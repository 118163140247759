@media (min-width: 768px) {
  .col {
    &.col-sm-hidden {
      display: none;
    }
    &.col-sm-block {
      display: block;
    }
    &.col-sm-inlineblock {
      display: inline-block;
    }
    &.col-sm-cell {
      display: table-cell;
    }
    &.col-sm-fleft {
      float: left;
    }
    &.col-sm-fright {
      float: right;
    }
    &.col-sm-auto {
      width: auto;
    }
    &.col-sm-size1of1 {
      width: 100%;
    }
    &.col-sm-size1of2{
      width: 50%;
    }
    &.col-sm-size1of3 {
      width: 33.3%;
    }
    &.col-sm-size1of4 {
      width: 25%;
    }
    &.col-sm-size1of5 {
      width: 20%;
    }
    &.col-sm-size1of6 {
      width: 16.6%;
    }
    &.col-sm-fixed5 {
      width: 5%;
    }
    &.col-sm-fixed8 {
      width: 8%;
    }
    &.col-sm-fixed10{
      width: 10%;
    }
    &.col-sm-fixed12 {
      width: 12%;
    }
    &.col-sm-fixed15 {
      width: 15%;
    }
    &.col-sm-fixed20 {
      width: 20%;
    }
    &.col-sm-fixed25 {
      width: 25%;
    }
    &.col-sm-fixed30 {
      width: 30%;
    }
    &.col-sm-fixed35 {
      width: 35%;
    }
    &.col-sm-fixed40 {
      width: 40%;
    }
    &.col-sm-fixed45 {
      width: 45%;
    }
    &.col-sm-fixed50 {
      width: 50%;
    }
    &.col-sm-fixed55 {
      width: 55%;
    }
    &.col-sm-fixed60 {
      width: 60%;
    }
    &.col-sm-fixed65 {
      width: 65%;
    }
    &.col-sm-fixed70 {
      width: 70%;
    }
    &.col-sm-fixed75 {
      width: 75%;
    }
    &.col-sm-fixed80 {
      width: 80%;
    }
    &.col-sm-fixed85 {
      width: 85%;
    }
    &.col-sm-fixed90 {
      width: 90%;
    }
    &.col-sm-fixed95 {
      width: 95%;
    }
  }
  .size {
    &.sm-size1of1 {
      width: 100%
    }
    &.sm-size1of2 {
      width: 50%
    }
    &.sm-size1of3 {
      width: 33%
    }
    &.sm-size1of4 {
      width: 25%
    }
    &.sm-size1of5 {
      width: 20%
    }
    &.sm-size1{
      width: 10%;
    }
    &.sm-size2{
      width: 20%;
    }
    &.sm-size3{
      width: 30%;
    }
    &.sm-size4{
      width: 40%;
    }
    &.sm-size5{
      width: 50%;
    }
    &.sm-size6{
      width: 60%;
    }
    &.sm-size7{
      width: 70%;
    }
    &.sm-size8{
      width: 80%;
    }
    &.sm-size9{
      width: 90%;
    }    
  }  
}

@media (min-width: 990px) {
  .col {
    &.col-md-hidden {
      display: none;
    }
    &.col-md-block {
      display: block;
    }
    &.col-md-inlineblock {
      display: inline-block;
    }
    &.col-md-cell {
      display: table-cell;
    }
    &.col-md-auto {
      width: auto;
    }
    &.col-md-size1of1 {
      width: 100%;
    }
    &.col-md-size1of2 {
      width: 50%;
    }
    &.col-md-size1of3 {
      width: 33.3%;
    }
    &.col-md-size1of4 {
      width: 25%;
    }
    &.col-md-size1of5 {
      width: 20%;
    }
    &.col-md-size1of6 {
      width: 16.6%;
    }
    &.col-md-fixed5 {
      width: 5%;
    }
    &.col-md-fixed8 {
      width: 8%;
    }
    &.col-md-fixed10 {
      width: 10%;
    }
    &.col-md-fixed12 {
      width: 12%;
    }
    &.col-md-fixed15 {
      width: 15%;
    }
    &.col-md-fixed20 {
      width: 20%;
    }
    &.col-md-fixed25 {
      width: 25%;
    }
    &.col-md-fixed30 {
      width: 30%;
    }
    &.col-md-fixed35 {
      width: 35%;
    }
    &.col-md-fixed40 {
      width: 40%;
    }
    &.col-md-fixed45 {
      width: 45%;
    }
    &.col-md-fixed50 {
      width: 50%;
    }
    &.col-md-fixed55 {
      width: 55%;
    }
    &.col-md-fixed60 {
      width: 60%;
    }
    &.col-md-fixed65 {
      width: 65%;
    }
    &.col-md-fixed70 {
      width: 70%;
    }
    &.col-md-fixed75 {
      width: 75%;
    }
    &.col-md-fixed80 {
      width: 80%;
    }
    &.col-md-fixed85 {
      width: 85%;
    }
    &.col-md-fixed90 {
      width: 90%;
    }
    &.col-md-fixed95 {
      width: 95%;
    }
  }
  .size {
    &.md-size1of1 {
      width: 100%
    }
    &.md-size1of2 {
      width: 50%
    }
    &.md-size1of3 {
      width: 33%
    }
    &.md-size1of4 {
      width: 25%
    }
    &.md-size1of5 {
      width: 20%
    }
    &.md-size1{
      width: 10%;
    }
    &.md-size2{
      width: 20%;
    }
    &.md-size3{
      width: 30%;
    }
    &.md-size4{
      width: 40%;
    }
    &.md-size5{
      width: 50%;
    }
    &.md-size6{
      width: 60%;
    }
    &.md-size7{
      width: 70%;
    }
    &.md-size8{
      width: 80%;
    }
    &.md-size9{
      width: 90%;
    }
  }  
}

@media (min-width: 1200px) {
  .col {
    &.col-lg-hidden {
      display: none;
    }
    &.col-lg-block {
      display: block;
    }
    &.col-lg-inlineblock {
      display: inline-block;
    }
    &.col-lg-cell {
      display: table-cell;
    }
    &.col-lg-auto {
      width: auto;
    }
    &.col-lg-size1of1 {
      width: 100%;
    }
    &.col-lg-size1of2 {
      width: 50%;
    }
    &.col-lg-size1of3 {
      width: 33.3%;
    }
    &.col-lg-size1of4 {
      width: 25%;
    }
    &.col-lg-size1of5 {
      width: 20%;
    }
    &.col-lg-size1of6 {
      width: 16.6%;
    }
    &.col-lg-fixed5 {
      width: 5%;
    }
    &.col-lg-fixed10 {
      width: 10%;
    }
    &.col-lg-fixed15 {
      width: 15%;
    }
    &.col-lg-fixed20 {
      width: 20%;
    }
    &.col-lg-fixed25 {
      width: 25%;
    }
    &.col-lg-fixed30 {
      width: 30%;
    }
    &.col-lg-fixed35 {
      width: 35%;
    }
    &.col-lg-fixed40 {
      width: 40%;
    }
    &.col-lg-fixed45 {
      width: 45%;
    }
    &.col-lg-fixed50 {
      width: 50%;
    }
    &.col-lg-fixed55 {
      width: 55%;
    }
    &.col-lg-fixed60{
      width: 60%;
    }
    &.col-lg-fixed65 {
      width: 65%;
    }
    &.col-lg-fixed70 {
      width: 70%;
    }
    &.col-lg-fixed75 {
      width: 75%;
    }
    &.col-lg-fixed80 {
      width: 80%;
    }
    &.col-lg-fixed85 {
      width: 85%;
    }
    &.col-lg-fixed90 {
      width: 90%;
    }
    &.col-lg-fixed95 {
      width: 95%;
    }
  }
  .size {
    &.lg-size1of1 {
      width: 100%
    }
    &.lg-size1of2 {
      width: 50%
    }
    &.lg-size1of3 {
      width: 33%
    }
    &.lg-size1of4 {
      width: 25%
    }
    &.lg-size1of5 {
      width: 20%
    }      
    &.lg-size1{
      width: 10%;
    }
    &.lg-size2{
      width: 20%;
    }
    &.lg-size3{
      width: 30%;
    }
    &.lg-size4{
      width: 40%;
    }
    &.lg-size5{
      width: 50%;
    }
    &.lg-size6{
      width: 60%;
    }
    &.lg-size7{
      width: 70%;
    }
    &.lg-size8{
      width: 80%;
    }
    &.lg-size9{
      width: 90%;
    }
  }  
}
