@import 'media';
@import 'palette';
@import 'mixin';

// Roboto
$font-regular: "Roboto";
$font-condensed: "Roboto Condensed";
$font-semi-condensed: "Roboto Condensed";

// Archivo Narrow;
// $font-regular: 'Archivo Narrow', sans-serif;;
// $font-condensed: 'Archivo Narrow', sans-serif;;
// $font-semi-condensed: 'Archivo Narrow', sans-serif;;

// Fira Sans
// $font-regular: 'Fira Sans', sans-serif;
// $font-condensed: 'Fira Sans', sans-serif;
// $font-semi-condensed: 'Fira Sans', sans-serif;

$display-4-size: 112px; $display-4-lheight: 112px; $display-4-weight: 300;
$display-3-size: 56px; $display-3-lheight: 56px; $display-3-weight: 400;
$display-2-size: 45px; $display-2-lheight: 48px; $display-2-weight: 400;
$display-1-size: 34px; $display-1-lheight: 40px; $display-1-weight: 400;
$headline-size: 24px; $headline-lheight: 32px; $headline-weight: 400;
$title-size: 20px; $title-lheight: 32px; $title-weight: 500;
$subheading-2-size: 16px; $subheading-2-lheight: 28px; $subheading-2-weight: 300;
$subheading-1-size: 15px; $subheading-1-lheight: 24px; $subheading-1-weight: 300;
$body-2-size: 16px; $body-2-lheight: 24px; $body-2-weight: 500;
$body-1-size: 14px; $body-1-lheight: 20px; $body-1-weight: 400;
$caption-size: 12px; $capition-lheight: 20px; $caption-weight: 400;
$note-size: 10px; $font-lheight: 20px; $note-weight: 400;

*, ::before, ::after {
  @include prefix(box-sizing, border-box);
}

html, body {
  color: #241c15;
  font-family: $font-regular;
  font-size: 1em;
  font-weight: 400;
}

.align {
  &.align-flex {
    &.center {
      align-items: center;
    }
    &.middle {
      justify-content: center;
    }
  }
  &.asidePre {
    margin: auto;
    margin-left: 0;
  }
  &.asidePos {
    margin: auto;
    margin-right: 0;
  }
  &.bottom {
    vertical-align: bottom;
  }
  &.center{
    text-align: center;
  }
  &.flex-center {
    @include prefix(align-items,center);
  }
  &.justify{
    text-align: justify;
  }
  &.left{
    text-align: left;
  }
  &.middle {
    vertical-align: middle;
  }
  &.right{
    text-align: right;
  }
  &.top {
    vertical-align: top;
  }
}

.border {
  &.noborder {
    border-bottom: 0 none;
    border-left: 0 none;
    border-right: 0 none;
    border-top: 0 none;
  }
  &.noborder-important {
    border: 0 none!important;
  }
}

.clearfix {
  &:before {
    content: " ";
    display: table;
    position: relative;
  }
  &:after {
    content: " ";
    clear: both;
    display: table;
    position: relative;
  }
}

.display {
  &.block {
      @include prefix(display, block);
  }
  &.flex {
      @include prefix(display, flex);
      &.column {
        flex-direction: column;
      }
      &.row {
        flex-direction: row;
      }
  }
  &.hidden {
      @include prefix(display, none);
  }
  &.h100vh {
    min-height: 100vh;
  }
  &.inline-block {
      @include prefix(display, inline-block);
  }
  &.inline-flex {
      @include prefix(display, inline-flex);
  }
  &.none {
      @include prefix(display, none);
  }
  &.table {
      @include prefix(display, table);
  }
  &.table-cell {
      @include prefix(display, table-cell);
  }
  &.table-row {
      @include prefix(display, table-row);
  }
}

.flex-order {
  &.order-0 {
    order: 0;
  }
  &.order-1 {
    order: 1;
  }
  &.order-2 {
    order: 2;
  }
  &.order-3 {
    order: 3;
  }
  &.order-4 {
    order: 4;
  }
  &.order-5 {
    order: 5;
  }
  &.order-6 {
    order: 6;
  }
  &.order-7 {
    order: 7;
  }
  &.order-8 {
    order: 8;
  }
  &.order-9 {
    order: 9;
  }
  &.order-10 {
    order: 10;
  }
  &.order-11 {
    order: 11;
  }
  &.order-12 {
    order: 12;
  }
}

.fluid-max {
  margin: auto;
  &.max-1440 {
    max-width: 1440px;
  }
  &.max-1220 {
    max-width: 1220px;
  }
  &.max-1024 {
    max-width: 1024px;
  }
  &.max-990 {
    max-width: 990px;
  }
  &.max-790 {
    max-width: 790px;
  }
  &.max-720 {
    max-width: 720px;
  }
  &.max-640 {
    max-width: 640px;
  }
  &.max-500 {
    max-width: 500px;
  }
  &.max-460 {
    max-width: 460px;
  }
  &.max-390 {
    max-width: 390px;
  }
  &.max-340 {
    max-width: 340px;
  }
  &.max-320 {
    max-width: 320px;
  }
  &.max-280 {
    max-width: 280px;
  }
  &.max-200 {
    max-width: 200px;
  }
  &.max-90p {
    max-width: 90%;
  }
  &.max-80p {
    max-width: 80%;
  }
  &.max-70p {
    max-width: 70%;
  }
  &.max-60p {
    max-width: 60%;
  }
  &.max-50p {
    max-width: 50%;
  }
}

.margin {
  &.auto {
    margin: auto!important;
  }
  &.m__00000016 {
    margin: 0 0 0 1em;
  }
  &.m__00000008 {
    margin: 0 0 0 .5em;
  }
  &.m__000008 {
    margin: 0 0 .5em;
  }
  &.m__000010 {
    margin: 0 0 10px;
  }
  &.m__000016 {
    margin: 0 0 1em;
  }
  &.m__000020 {
    margin: 0 0 20px;
  }
  &.m__000030 {
    margin: 0 0 30px;
  }
  &.m__000040 {
    margin: 0 0 40px;
  }
  &.m__000050 {
    margin: 0 0 50px;
  }
  &.m__00080000 {
    margin: 0 .5em 0 0;
  }
  &.m__00160000 {
    margin: 0 1em 0 0;
  }
  &.m__160000 {
    margin-top: 1em;
  }
  &.m__100030 {
    margin: 10px 0 30px;
  }
  &.m__1000 {
    margin: 10px 0;
  }
  &.m__20 {
    margin: 20px;
  }
  &.m__2000 {
    margin: 20px 0;
  }
  &.m__30 {
    margin: 30px;
  }
  &.m__3000 {
    margin: 30px 0;
  }
  &.m__5 {
    margin: 5px;
  }
  &.nomargin {
    margin: 0!important;
  }
}

.mobile-only {
  @include breakpoint('sm') { display: none; }
}

.padding {
  &.nopadding {
    padding: 0!important;
  }
  &.p__16 {
    padding: 1em;
  }
  &.p__16160 {
    padding: 1em 1em 0;
  }
  &.p__20 {
    padding: 20px;
  }
  &.p__m__16 {
    padding: 1em;
    @include breakpoint('sm'){
      padding: inherit;
    }
  }
  &.p__16__important {
    padding: 1em!important;
  }
}

.size{
  &.size1{
    width: 10%;
  }
  &.size2{
    width: 20%;
  }
  &.size3{
    width: 30%;
  }
  &.size4{
    width: 40%;
  }
  &.size5{
    width: 50%;
  }
  &.size6{
    width: 60%;
  }
  &.size7{
    width: 70%;
  }
  &.size8{
    width: 80%;
  }
  &.size9{
    width: 90%;
  }
  &.size1of1 {
    width: 100%
  }
  &.size1of2 {
    width: 50%;
  }
  &.size1of3 {
    width: 33.33%;
  }
  &.size1of4 {
    width: 25%;
  }
  &.size1of7{
    width: 66.67%;
  }
  &.xs-size1of1 {
    width: 100%
  }
  &.xs-size1of2 {
    width: 50%
  }
  &.xs-size1of3 {
    width: 33%
  }
  &.xs-size1of4 {
    width: 25%
  }
  &.xs-size1of5 {
    width: 20%
  }
  &.xs-size1{
    width: 10%;
  }
  &.xs-size2{
    width: 20%;
  }
  &.xs-size3{
    width: 30%;
  }
  &.xs-size4{
    width: 40%;
  }
  &.xs-size5{
    width: 50%;
  }
  &.xs-size6{
    width: 60%;
  }
  &.xs-size7{
    width: 70%;
  }
  &.xs-size8{
    width: 80%;
  }
  &.xs-size9{
    width: 90%;
  }
}

.text {
  &.disabled {
    color: $grey-300;
  }
  &.secondary {
    color: $grey-600;
  }
  &.success {
    color: $green-A400;
  }
}

.typo {
  &.display-4 {
    @include font('xs', 2.25em, 1.125, .004em); /* 36 / 16 */
    @include font('sm', 2.75em, 1.09091, -.002em); /* 44 / 16 */
    @include font('md', 3.5em, 1.07143, -.005em); /* 56 / 16 */
  }
  &.display-3 {
    @include font('xs', 1.6875em, 1.14815, .008em); /* 27 / 16 */
    @include font('sm', 2em, 1.125, .004em); /* 32 / 16 */
    @include font('md', 2.5em, 1.1, 0em); /* 40 / 16 */
  }
  &.display-2 {
    @include font('xs', 1.5em, 1.20849, .015em); /* 24 / 16 */
    @include font('sm', 1.75em, 1.14286, .01em); /* 28 / 16 */
    @include font('md', 2.25em, 1.11119, .006em); /* 36 / 16 */
  }
  &.display-1 {
    @include font('xs', 1.1875em, 1.42115, .012em); /* 19 / 16 */
    @include font('sm', 1.375em, 1.18182, .01em); /* 22 / 16 */
    @include font('md', 1.5em, 1.14815, .008em); /* 24 / 16 */
  }
  &.headline {
    @include font('xs', 1.125em, 1.4446, .016em); /* 18 / 16 */
    @include font('sm', 1.375em, 1.42115, .012em); /* 22 / 16 */
    @include font('md', 1.75em, 1.18182, .01em); /* 28 / 16 */
  }
  &.title {
    @include font('xs', 1.0625em, 1.4446, .016em); /* 17 / 16 */
    @include font('sm', 1.1875em, 1.42115, .012em); /* 19 / 16 */
    @include font('md', 1.375em, 1.18182, .01em); /* 22 / 16 */
  }
  &.subheading-2 {
    @include font('xs', 1.0625em, 1.4446, .016em); /* 17 / 16 */
    @include font('sm', 1.1875em, 1.42115, .012em); /* 19 / 16 */
    @include font('md', 1.375em, 1.18182, .01em); /* 22 / 16 */
  }
  &.subheading-1 {
    @include font('xs', 0.9375em, 1.4446, .016em); /* 15 / 16 */
    @include font('sm', 1.0625em, 1.4446, .016em); /* 17 / 16 */
    @include font('md', 1.125em, 1.18182, .01em); /* 18 / 16 */
  }
  &.body-1 {
    @include font('xs', 0.9375em, 1.4446, .016em); /* 15 / 16 */
    @include font('sm', 0.9375em, 1.4446, .016em); /* 15 / 16 */
    @include font('md', 0.9375em, 1.18182, .01em); /* 15 / 16 */
  }
  &.body-2 {
    @include font('xs', 0.9375em, 1.4446, .016em); /* 15 / 16 */
    @include font('sm', 1.0625em, 1.4446, .016em); /* 17 / 16 */
    @include font('md', 1.125em, 1.18182, .01em); /* 18 / 16 */
  }
  &.caption {
    @include font('xs', 0.75em, 1.4446, .016em); /* 12 / 16 */
    @include font('sm', 0.8125em, 1.4446, .016em); /* 13 / 16 */
    @include font('md', 0.875em, 1.18182, .01em); /* 14 / 16 */
  }
  &.label {
    @include font('xs', 0.8125em, 1.5, .016em); /* 13 / 16 */
    @include font('sm', 0.8125em, 1.5, .016em); /* 13 / 16 */
    @include font('md', 0.8125em, 1.5, .016em); /* 13 / 16 */
  }
  &.note {
    @include font('xs', 0.75em, 1.5, 0); /* 12 / 16 */
    @include font('sm', 0.75em, 1.5, 0); /* 12 / 16 */
    @include font('md', 0.75em, 1.5, 0); /* 12 / 16 */
  }
  &.footnote {
    @include font('xs', 0.6875em, 1.5, 0); /* 11 / 16 */
    @include font('sm', 0.6875em, 1.5, 0); /* 11 / 16 */
    @include font('md', 0.6875em, 1.5, 0); /* 11 / 16 */
  }
  &.placeholder{
    font-size: inherit;
  }
  &.regular {
    font-family: $font-regular;
  }
  &.condensed {
    font-family: $font-condensed;
  }
  &.semi-condensed {
    font-family: $font-semi-condensed;
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.lowercase {
    text-transform: lowercase;
  }
  &.italic {
    font-style: italic;
  }
  &.normal {
    font-style: normal;
  }
  &.fw100 {
    font-weight: 100;
  }
  &.fw200 {
    font-weight: 200;
  }
  &.fw300 {
    font-weight: 300;
  }
  &.fw400 {
    font-weight: 400;
  }
  &.fw500 {
    font-weight: 500;
  }
  &.fw600 {
    font-weight: 600;
  }
  &.fw700 {
    font-weight: 700;
  }
  &.fw800 {
    font-weight: 800;
  }
  &.fw900 {
    font-weight: 900;
  }
  &.primary {
      color: $grey-900;
  }
  &.secondary {
      color: $grey-600;
  }

}


$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}
.mat-tooltip  {
  white-space: pre-line;
}

.w100 {
  width: 100%;
}

.export-icon {
  margin-left: auto;
}

.ml-5 {
  margin-left: 5px;
}

.d-flex {
  display: flex;
}
