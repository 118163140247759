@use '@angular/material' as mat;
@import 'palette.scss';
@import 'common.scss';

$custom-typo: mat.define-typography-config(
    $font-family: $font-semi-condensed
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($custom-typo);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$rebillia-app-primary: mat.define-palette(mat.$blue-palette, A700);
$rebillia-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$rebillia-app-warn: mat.define-palette(mat.$red-palette);

$custom-theme: mat.define-light-theme($rebillia-app-primary, $rebillia-app-accent, $rebillia-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($custom-theme);

$sidenav-bg-color: $white;
$sidenav-divider-color: $grey-300;
$sidenav-padding: 0 16px;
$sidenav-width: 230px;
$sidenav-width-compressed: 84px;

html {
    touch-action: manipulation;
}

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, code, del, dfn, em, img, q, dl, dt, dd, ol, ul, fieldset, form, label, legend, textarea, table, caption, tbody, tfoot, thead, tr, th, td, polygon, circle, path {
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
}

html, body {
    -webkit-overflow-scrolling: touch;
    min-height: 100vh;
    touch-action: manipulation;
    width: 100%;
    &.nonscroll {
        -webkit-overflow-scrolling: hidden;
        height: 100%;
        position: fixed;
        overflow: hidden;
        touch-action: none;
        & .prehead, .page {
            -webkit-overflow-scrolling: hidden;
            opacity: 0;
            pointer-events: none;
        }
    }
    &.processing-request {
        & .app-page-loader {
            pointer-events: auto;
            z-index: 3;
        }
    }
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

a {
  &.custom-mat-link {
      color: $grey-900;
      &.active {
        color: $blue-A400;
      }
      &.disabled {
          color: $grey-500;
          pointer-events: none;
      }
      &.has--divider {
          &:before {
              color: $blue-A400;
              content: '\2022';
              margin: auto;
              margin-left: 12px;
              margin-right: 12px;
          }
      }
      &.hasIco {
        display: inline-flex;
        flex-direction: row;
        & .custom-mat-icon {
          margin: auto;
        }
        & span {
          align-items: center;
          display: flex;
          line-height: 1em;
          margin: auto;
          margin-left: 10px;
          margin-right: 10px;
        }
        &.active {
          svg {
            fill: $blue-A400;
          }
        }
      }
      &.link-back {
        display: flex;
        flex-direction: row;
        margin: auto;
        & .custom-mat-icon {
            height: auto;
            margin: auto;
            margin-right: 10px;
            width: auto;
            & svg {
                height: auto;
                margin: auto;
                width: 8px;
            }
        }
        @include breakpoint('lg'){
            position: fixed;
            left: 30px;
            top: 50%;
            transform: scale(1.2);
            z-index: 99;
            & .custom-mat-icon {
                background-color: $blue-50;
                border-radius: 50%;
                cursor: pointer;
                height: 40px;
                margin-right: auto;
                width: 40px;
            }
            & .ico-label {
                display: none;
            }
        }
      }
      &.link-button {
        &::after {
          background-color: $grey-500;
          display: block;
          content: ' ';
          height: 2px;
          opacity: 0;
          transition: opacity .3s ease-in-out;
          width: 100%;
        }
        &:hover {
          &::after {
            opacity: 1;
          }
        }
      }
      &.link-header-action {
        border: 1px solid $blue-A900;
        border-radius: 24px;
        color: $blue-A900;
        padding: .5em 2em;
      }
      &.link-router-back {
          & .caption {
              display: none;
              @include breakpoint('sm') { display: inline-flex; }
          }
      }
      &.overlay-link {
          height: 100%;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%;
          @include breakpoint('md') {
              display: none;
          }
      }
      &.router-link-active {
          color: $blue-A400;
          pointer-events: none;
          &.link-router-back {
              color: $black;
          }
      }
  }
  &.mat-flat-button {
    &.custom-mat-flat-button {
      padding: 0;
      & .mat-button-wrapper {
        margin: auto;
      }
      &.button-switch {
        background-color: transparent;
        color: $grey-900;
        display: flex;
        height: 40px;
        margin: auto;
        min-width: 40px;
      }
    }
    &.custom-mat-button-primary {
        line-height: 50px;
        padding-left: 40px;
        padding-right: 40px;
    }
    &.rounded {
        border-radius: 24px;
    }
  }
  &.custom-mat-button {
    align-items: center;
    display: flex;
    justify-content: center;
    & .mat-button-focus-overlay {
      display: none;
    }
    & .mat-button-wrapper {
      &::after {
        background-color: $grey-500;
        display: block;
        content: ' ';
        height: 2px;
        opacity: 0;
        transition: opacity .3s ease-in-out;
        width: 100%;
      }
    }
    &:hover {
      & .mat-button-wrapper {
          &::after {
              opacity: 1;
          }
      }
    }
  }
  &.custom-mat-icon-button {
    &.link-external {
      display: inline-flex;
      height: auto;
    }
  }
  &.sidenav-link {
    &.item-container {
      & .item {
        & .icon-container {
          background-color: $blue-grey-50;
          border-radius: 50%;
          transition: background-color .3s ease-in-out;
        }
        & .mat-icon {
          &.custom-mat-icon {
            &.ico-sidenav {
              -webkit-transition: all 0.3s ease-in-out;
              -moz-transition: all 0.3s ease-in-out;
              -ms-transition: all 0.3s ease-in-out;
              -o-transition: all 0.3s ease-in-out;
              transition: all 0.3s ease-in-out;
              & .ico-path {
                stroke: $grey-800;
                transition: stroke .3s ease-in-out;
              }
            }
          }
        }
      }
      &:hover {
        .item {
          .icon-container {
            background-color: $blue-grey-100;
            border-radius: 50%;
          }
          .mat-icon {
            &.custom-mat-icon {
              &.ico-sidenav {
                transform: scale(1.25) translate(0%, 0%);
                .ico-path {
                  stroke: $blue-A400;
                }
              }
            }
          }
        }
      }
      &.option-active {
        .item {
          .icon-container {
            background-color: $blue-A400;
            .custom-mat-icon {
              svg {
                path {
                  stroke: $white;
                }
              }
            }
          }
          .mat-icon {
            &.custom-mat-icon {
              &.ico-sidenav {
                transform: scale(1.1) translate(0%, 0%);
                .ico-path {
                  stroke: $white;
                }
              }
            }
          }
        }
        &:hover {
          .icon-container {
            background-color: $blue-A400;
            .custom-mat-icon {
              svg {
                path {
                  stroke: $white;
                }
              }
            }
          }
        }
      }
      &.router-link-active {
        .item {
          .icon-container {
            background-color: $blue-50;
          }
          .mat-icon {
            &.custom-mat-icon {
              &.ico-sidenav {
                transform: scale(1) translate(0%, 0%);
                & .ico-path {
                  stroke: $blue-A400;
                }
              }
            }
          }
        }
      }
    }
  }
}

button {
  &.button-account {
    align-items: center;
    background-color: transparent;
    border: 0 none;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
    padding: 0 1.25em;
    transition: opacity .3s ease-in-out;
  }
  &.button-add {
    align-items: center;
    background-color: $blue-A900;
    border: 0 none;
    border-radius: 50%;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0);
    color: $white;
    display: flex;
    overflow: hidden;
    padding: 0;
    transition: all .33s ease-in-out;
    .icon-container {
      .ico {
        height: 48px;
        width: 48px;
      }
    }
    .btn-txt {
      flex-shrink: 0;
    }
  }
  &.custom-mat-button {
      transition: background-color .3s ease-in-out;
      .mat-button-focus-overlay {
          display: none;
      }
      &.button-actions {
        align-items: center;
        border: 1px solid $blue-A900;
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        min-height: 42px;
        min-width: 42px;
        padding: 0;
        width: auto;
        @include breakpoint('md') {
          border-radius: 24px;
          padding: 0 2em;
        }
        .mat-button-wrapper {
          align-items: center;
          color: $blue-A900;
          display: flex;
          flex-direction: row;
          & .label-button {
            display: none;
            margin: 0;
            margin-left: .5em;
            @include breakpoint('md'){
              display: block;
            }
          }
        }
      }
      &.button-bulk-action {
          background-color: transparent;
          border: 1px solid;
          border-radius: 6px;
          color: #1452A3;
          display: flex;
          flex-direction: row;
          height: 40px;
          margin: auto;
          margin-right: 8px;
          & .mat-button-wrapper {
              align-items: center;
              display: flex;
              margin: auto;
          }
          & .mat-button-focus-overlay {
              display: none;
          }
          &.button-clear {
              border: 0 none;
              & .custom-mat-icon {
                  margin-right: 5px ;
              }
          }
          &.button-delete {
              background-color: transparent;
              transition: border-color .3s ease-in-out, color .2s ease-in-out, background-color .3s ease-in-out;
              &:hover {
                  background-color: $red-50;
                  border-color: $red-A400;
                  color: $red-A400;
              }
              & .custom-mat-icon {
                  margin-right: 5px ;
              }
          }
      }
      &.button-call-to-action {
          background-color: $blue-grey-900;
          color: $white;
          line-height: 40px;
          padding-left: 40px;
          padding-right: 40px;
      }
      &.button-cancel {
          background-color: transparent;
          border: 0 none;
          box-shadow: none;
      }
      &.button-cancel-new-rule {
          & .mat-button-wrapper {
              align-items: center;
              display: flex;
              flex-direction: column;
              justify-content: center;
          }
      }
      &.button-card{
          border: 1px solid $grey-300;
          color: $grey-500;
          font-size: 0.875em;
          font-weight: 600;
          text-transform: uppercase;
          width: 90px;
          & .mat-button-wrapper{
              align-items: center;
              display: flex;
              height: 40px;
              justify-content: center;
          }
          &.status-active {
              border-color: $green-A400;
              color: $green-A400;
          }
          &.status-disabled {
              border-color: #ff1744;
              color: #ff1744;
          }
          &.status-default {
              border-color: $blue-A400;
              color: $blue-A400;
          }
          &.button-delete{
              background-color: $red-50;
              border-color: $red-A400;
              color: $red-A400;
              & .custom-mat-icon{
                  margin-right: 4px;
              }
          }
      }
      &.button-currency {
        align-items: center;
        background-color: transparent;
        border: 0 none;
        display: flex;
        justify-content: center;
        & .img-flag {
          margin: auto .5em;
        }
      }
      &.button-dismiss {
          background-color: transparent;
          border: 1px solid $blue-grey-400;
          color: $blue-grey-400;
          font-size: 0.75em;
          font-weight: 600;
          text-transform: uppercase;
          width: 90px;
          & .mat-button-wrapper{
              align-items: center;
              display: flex;
              height: 40px;
              justify-content: center;
          }
      }
      &.button-delete {
          background-color: $red-A400;
          color: $white;
      }
      &.button-filter {
          border: 0 none;
          box-shadow: none;
          height: 40px;
          min-width: 40px;
          padding: 0;
          width: 40px;
          & .mat-button-wrapper {
              align-items: center;
              display: flex;
              & .custom-mat-icon {
                  margin: auto;
                  height: 40px;
                  width: 40px;
                  & svg {
                      height: 16px;
                      width: 16px;
                  }
              }
          }
      }
      &.button-link {
        background-color: transparent;
        border: 0 none;
        box-shadow: none;
        cursor: pointer;
        padding: 0;
      }
      &.button-link-active {
        color: $blue-A900;
      }
      &.button-link-disabled {
        color: $grey-500;
        &[disabled] {
          background-color: transparent;
        }
      }
      &.button-link-flat {
        border: 0 none;
        box-shadow: none;
        height: 40px;
        min-width: 0;
        padding: 0;
      }
      &.button-link-underline {
        &:after {
          background-color: $blue-A400;
          content: ' ';
          display: block;
          height: 1px;
          margin-top: .5em;
          width: 100%;
        }
      }
      &.button-merge-fields {
        border: 1px solid $grey-200;
        .mat-button-wrapper {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
      &.button-new-block {
          border: 0 none;
          box-shadow: none;
          height: 40px;
          min-width: 40px;
          padding: 0;
      }
      &.button-new-rule {
          & .mat-button-wrapper {
              align-items: center;
              display: flex;
              flex-direction: column;
              justify-content: center;
          }
      }
      &.button-options {
          align-items: center;
          display: flex;
          justify-content: center;
      }
      &.button-signin {
          background-color: $blue-grey-900;
          color: $white;
          line-height: 50px;
          padding-left: 40px;
          padding-right: 40px;
      }
      &.button-signout {
          width: auto;
          & .mat-button-wrapper {
              display: flex;
              flex-direction: row;
          }
          & .custom-mat-icon {
              margin: auto 5px;
          }
      }
      &.button-remove {
          align-items: center;
          background-color: transparent;
          border: 0 none;
          color: $red-A400;
          display: flex;
          fill: $red-A400;
          justify-content: center;
          &.button-remove-block {
              border: 1px solid $red-A400;
          }
      }
      &.hasIco {
          & .mat-button-wrapper {
              align-items: center;
              display: flex;
          }
          &.flex-column {
              & .mat-button-wrapper {
                  flex-direction: column;
              }
          }
          &.flex-row {
              & .mat-button-wrapper {
                  flex-direction: row;
              }
          }
      }
      &[disabled] {
        background-color: $grey-300;
        pointer-events: none;
      }
  }
  &.custom-mat-button-avatar {
      height: 30px;
      width: 30px;
      @include breakpoint('sm') { height: 40px; width: 40px; }
      & .mat-button-wrapper {
          height: 100%;
          width: 100%;
          & .custom-mat-card-avatar{
              border-radius: 50%;
              height: 100%;
              overflow: hidden;
              width: 100%;
              & .avatar {
                  height: 100%;
                  width: 100%;
              }
          }
      }
  }
  &.custom-mat-button-filter {
      background-color: $white;
      border-bottom: 1px solid $blue-A900;
      border-left: none;
      border-right: 1px solid $blue-A900;
      border-top: 1px solid $blue-A900;
      border-radius: 0;
      color: $blue-A900;
      font-family: $font-semi-condensed;
      font-size: inherit;
      font-weight: 500;
      line-height: 40px;
      padding: 0;
      text-transform: uppercase;
      transition: all .3s ease-in-out;
      width: 25%;
      @include breakpoint('sm') {
          padding-left: 30px;
          padding-right: 30px;
          &:first-child {
              border-bottom-left-radius: 24px;
              border-left: 1px solid $blue-A900;
              border-top-left-radius: 24px;
              padding-left: 35px;
          }
          &:last-child {
              border-bottom-right-radius: 24px;
              border-top-right-radius: 24px;
              padding-right: 35px;
          }
      }
      @include breakpoint('md') {
          width: auto;
      }
      &:first-child {
          border-left: 1px solid $blue-A900;
      }
      &:hover {
          background-color: $blue-A900;
          color: $white;
      }
      &.active {
          background-color: $blue-A900;
          pointer-events: none;
          color: $white;
      }
  }
  &.custom-mat-button-primary {
    line-height: 50px;
    padding-left: 40px;
    padding-right: 40px;
    &.mat-stroked-button {
      border: 1px solid $blue-A900;
      &.mat-button-disabled {
        border: 1px solid $grey-300;
      }
    }
  }
  &.custom-mat-button-secondary {
      line-height: 50px;
      padding-left: 50px;
      padding-right: 50px;
  }
  &.custom-mat-button-third {
    background-color: transparent;
    border: 1px solid #276BEE;
    border-radius: 24px;
    box-shadow: 0px 1px 4px rgba(201, 208, 219, 0.2);
    box-sizing: border-box;
    color: #276BEE;
    min-height: 35px;
    min-width: 90px;
    .mat-button-wrapper {
      align-items: center;
      display: flex;
      flex-direction: row;
      .label-button {
        font-size: 14px;
        line-height: 24px;
        margin: 0 .5em;
      }
    }
  }
  &.custom-mat-flat-button {
    &.button-actions {
      background-color: $white;
      border: 1px solid #C9D0DB;
      border-radius: 4px;
      color: #737F8B;
      height: 35px;
      .mat-button-wrapper {
        align-items: center;
        display: flex;
        flex-direction: row;
        .label-button {
          font-size: 12px;
          line-height: 35px;
          margin: 0 .5em;
        }
      }
      &.mat-button-disabled {
        background-color: $grey-100;
        border: 1px solid $grey-300;
        color: $grey-400;
      }
    }
  }
  &.custom-mat-icon-button {
    &.button-actions {
      &.mat-button-disabled {
        & svg {
          & path {
            stroke: $grey-500;
          }
        }
      }
    }
    &.button-expander, &.button-menu-expander, &.button-panel-expander {
      margin: auto .5em;
      padding: 0;
      transform: rotateY(0deg) rotate(0deg);
      transition: transform .3s;
      width: auto;
      .mat-button-wrapper {
        align-items: center;
        display: flex;
        .label-primary {
          cursor: pointer;
          margin: auto;
          margin-right: .5em;
        }
      }
      &.is--expanded {
        transform: rotateY(0) rotate(45deg);
      }
    }
    &.button-menu-expander, &.button-panel-expander {
      border: 1px solid $grey-500;
      border-radius: 4px;
      padding: 0 1em;
    }
  }
  &.mat-sort-header-button {
      background-color: transparent;
      border: none;
      flex-shrink: 0;
      font-family: $font-condensed;
      font-size: .6875em;
      padding: 0;
      text-transform: uppercase;
  }
  &.rounded {
      border-radius: 24px;
  }
  &:hover {
    &.button-add {
      box-shadow: 0 2px 12px 0 rgba(0,0,0,0.45);
      cursor: pointer;
      .ico {
        .circle {
          transform: scale(1.25);
        }
      }
    }
  }
}

fieldset {
  &.card {
    align-items: center;
    background-color: transparent;
    border: 0;
    display: flex;
    .card-type {
      background-color: transparent;
      min-width: 0;
      padding: 0 .5em;
    }
    .card-data {
      display: flex;
      flex-direction: column;
      .label {
        line-height: 1em;
        &.label-card-exp {
          color: $grey-600;
          font-size: .75em;
          margin-top: .5em;
        }
        &.label-card-number {
          color: $grey-900;
          font-size: 1em;
        }
      }
    }
  }
}

figure {
    &.adv {
        height: 100%;
        margin: auto;
        padding: 0;
        width: 100%;
    }
    &.adv-01 {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('/assets/images/adv-01.png');
        height: 100%;
        max-height: 600px;
        max-width: 90%;
        width: 100%;
    }
    &.bg {
        height: 100%;
        margin: auto;
        padding: 0;
        width: 100%;
        &.setup-footer {
            background-position: 50% 0;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url('/assets/images/bg-city-01.png');
            height: 280px;
            width: 100%;
            @include breakpoint('md'){
                background-size: contain;
            }
        }
    }
    &.mat-option-figure {
      display: flex;
      margin: 0;
      padding: 0;
      & .product-image {
        height: auto;
        margin: auto;
        max-height: 45px;
        max-width: 45px;
        width: auto;
      }
      &.figure-external-product {
        width: 80px;
      }
    }
    &.partner-isologo {
      display: flex;
      margin: 0;
      padding: 0;
      & img {
        height: auto;
        margin: auto;
        width: 48px;
        @include breakpoint('md'){
          width: 60px;
          &.img-shipperHq{
            width: 90px;
          }
        }
      }
    }
    &.sales-channels, &.sales-channel-logo {
      display: flex;
      margin: 0;
      margin-bottom: 1em;
      margin-top: 1em;
      padding: 0;
      @include breakpoint('md'){
        margin-bottom: 0;
      }
      & img {
        height: auto;
        margin: auto;
        margin-left: 0;
        max-height: 40px;
        min-height: 40px;
        max-width: 150px;
      }
    }
    &.sales-channel-logo {
      margin: 0;
    }
}

footer {
    &.setup-footer {
        text-align: center;
        & .footer__bottom {
            margin: 5px auto 20px;
            padding: 0 15px;
            & .copyright {
                margin-top: 25px;
            }
        }
    }
}

header {
    &.pre-head {
        background-color: $white;
        border-bottom: 1px solid $grey-200;
        display: flex;
        flex-direction: column;
        padding: 0;
    }
    &.setup-head {
        align-items: center;
        display: flex;
        flex-direction: row;
        height: 64px;
        padding: 0 16px;
        position: relative;
        & .button-signout {
            margin: auto;
            margin-right: 0;
            @include breakpoint('sm'){ margin-left: 0; }
        }
        & .headline {
            display: none;
            margin: auto;
            @include breakpoint('sm'){ display: flex; }
        }
        & .ico-isologo-top {
            height: 64px;
            width: 80px;
            & svg {
                height: 100%;
                margin-left: 0;
                width: 100%;
            }
        }
    }
}

img {
  &.currency-flag {
      margin-right: 5px;
  }
}

label {
  &.label {
    color: $grey-600;
    font-size: .875em;
    font-weight: 400;
  }
  &.label-card {
    align-items: center;
    display: flex;
    & .ico-card {
      margin: auto 0;
    }
    & .label-card-number {
      &:before {
        color: #8c8c91;
        content:"···";
        margin: 0 .5em;
      }
    }
    &.card-badge {
      background-color: $white;
      border: 1px solid $grey-400;
      border-radius: 3px;
      display: flex;
      font-size: .875em;
      justify-content: center;
      height: 36px;
      padding: 0 .25em;
      min-width: 110px;
    }
  }
  &.label-currency-symbol {
      margin: auto;
      margin-left: 4px;
  }
  &.label-customer-payment-method-full {
    display: flex;
    flex-direction: column;
  }
  &.label-description {
      font-size: .875em;
      font-weight: 400;
      line-height: 1.5em;
  }
  &.label-error {
    color: $red-A400;
  }
  &.label-limit {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
      text-overflow: ellipsis;
      @include breakpoint('sm'){
          max-width: 180px;
      }
  }
  &.label-link {
      font-family: $font-condensed;
      font-size: .875em;
      font-weight: 400;
  }
  &.label-navigation-subtitle {
      color: $grey-900;
      font-weight: 500;
  }
  &.label-navigation-title {
      color: $blue-A400;
      font-size: .8125em;
      font-weight: 400;
  }
  &.label-note {
    color: $grey-600;
    font-style: italic;
  }
  &.label-placeholder {
      color: $grey-600;
      display: block;
      font: inherit;
      font-family: $font-semi-condensed;
      font-size: .75em;
      font-weight: 300;
      margin: auto;
      margin-bottom: 1em;
      margin-left: 0;
      text-transform: uppercase;
  }
  &.label-secondary {
    color: $grey-600;
    &.label-attention {
      color: $amber-A700;
    }
  }
  &.label-status {
    align-items: center;
    background-color: $white;
    border-radius: 12px;
    border: 1px solid;
    display: flex;
    font-size: .75em;
    line-height: 2em;
    min-width: 120px;
    max-width: 120px;
    justify-content: center;
    padding: 0 .75em;
    text-transform: uppercase;
    &.status-active, &.status-done {
      background-color: $green-50;
      border: 1px solid $green-100;
      color: $green-900;
    }
    &.status-archived, &.status-processing, &.status-pending {
      background-color: $grey-50;
      border-color: $grey-300;
      color: $grey-500;
    }
    &.status-cancelled {
      border-color: $red-A400;
      color: $red-A400;
    }
    &.status-created, &.status-pending, &.status-published, &.status-ready {
      border: 1px solid $blue-A900;
      color: $blue-A900
    }
    &.status-disabled {
      background-color: $grey-50;
      border-color: $grey-300;
      color: $grey-500;
    }
    &.status-draft {
      border-color: $blue-grey-300;
      color: $blue-grey-400;
    }
    &.status-error {
      background-color: $red-50;
      border: 1px solid $red-100;
      color: $red-A400;
    }
    &.status-request-payment, &.status-required-authorization, &.status-running {
      background-color: $amber-50;
      border-color: $amber-300;
      color: $amber-900;
    }
    &.status-invoice, &.row-customers-subscription-total {
      &.status-paid {
        background-color: $green-50;
        border: 1px solid $green-100;
        color: $green-900;
      }
      &.status-payment-due {
        background-color: $red-50;
        border: 1px solid $red-A400;
        color: $red-A700;
      }
      &.status-posted, &.status-partial-paid {
        border: 1px solid $blue-A900;
        color: $blue-A900
      }
      &.status-payment-request, &.status-required-authorization {
        background-color: $amber-50;
        border-color: $amber-300;
        color: $amber-900;
      }
    }
  }
  &.status {
    border-radius: 0;
    height: 40px;
    overflow: hidden;
    transition: width .3s ease-in-out;
    width: 1.25em;
    .status-inner {
      border-radius: 0;
      display: flex;
      height: 100%;
      transition: width .3s ease-in-out, border-radius .3s ease-in-out;
      width: 6px;
      .status-label {
        font-size: 12px;
        font-weight: 400;
        margin: auto;
        opacity: 0;
        text-align: center;
        text-transform: uppercase;
        transition: opacity .3s ease-in-out;
      }
      &.status-active, &.status-settled, &.status-paid, &.status-success {
        background-color: var(--palette-green-60);
        color:  var(--palette-green-100);
      }
      &.status-awaitingForSettlement {
        background-color: $amber-200;
        color: $amber-900;
      }
      &.status-authorized {
        background-color: $blue-grey-100;
        color: $blue-grey-400;
      }
      &.status-archived {
        background-color: var(--palette-red-60);
        color:  var(--palette-red-100);
      }
      &.status-declined {
        background-color: #967e68;
        color: #47280b;
      }
      &.status-paused {
        background-color: var(--palette-grey-90);
        color:  var(--palette-grey-100);
      }
      &.status-cancelled, &.status-ended {
        background-color: var(--palette-grey-0);
        color:  var(--palette-grey-100);
      }
      &.status-created {
        border: 1px solid $blue-A900;
        color: $blue-A900
      }
      &.status-disabled {
        background-color: var(--palette-orange-60);
        color:  var(--palette-orange-100);
      }
      &.status-discontinue {
        background-color: var(--palette-grey-0);
        color:  var(--palette-grey-100);
      }
      &.status-draft {
        background-color: var(--palette-grey-90);
        color:  var(--palette-grey-100);
      }
      &.status-error, &.status-payment-due {
        background-color: $red-50;
        color: $red-A400;
      }
      &.status-completed {
        background-color: $green-50;
        color: $green-900;
      }
      &.status-dunning {
        background-color: $amber-200;
        color: $amber-900;
      }
      &.status-declined {
        background-color: #967e68;
        color: #47280b;
      }
      &.status-info {
        background-color: $deep-purple-100;
        color: $deep-purple-A700;
      }
      &.status-partial-refund {
        background-color: $amber-100;
        color: $amber-900
      }
      &.status-pending {
        background-color: $blue-grey-600;
        color: $blue-grey-50
      }
      &.status-published, &.status-posted, &.status-partial-paid {
        background-color: $blue-A400;
        color: $blue-50;
      }
      &.status-refund {
        background-color: $amber-600;
        color: $amber-900
      }
      &.status-request-payment, &.status-required-authorization {
        background-color: $amber-50;
        color: $amber-900;
      }
      &.status-warning {
        background-color: $amber-200;
        color: $amber-900;
      }
    }
    .force-expand {
      height: 28px;
    }
    &:hover, .force-expand {
      cursor: pointer;
      width: 120px;
      .status-inner {
        border-radius: 4px;
        width: 112px;
      }
      .status-label {
        opacity: 1;
      }
    }
    &:hover + .cell-inner-with-status{
      width: calc(100% - 120px) !important;
    }
  }
}

main {
    &.account {
        height: 100%;
        & .page {
            display: flex;
            flex-direction: column;
            & .form-title {
                margin-bottom: 30px;
            }
        }
    }
    &.base {
        display: flex;
        flex-direction: column;
        position: relative;
        @include breakpoint('lg'){
            flex-direction: row;
        }
        & .content {
            flex: 1;
            transition: all .3s ease-in-out;
            @include breakpoint('lg'){
                margin-left: 60px;
            }
        }
    }
    &.setup {
        display: block;
        overflow-x: hidden;
        padding: 60px 0;
        position: relative;
        & .form-content {
          padding: 0;
          & .form-block {
            border-top: 1px dotted $grey-300;
            display: flex;
            flex-direction: column;
            margin: auto;
            margin-left: 0;
            margin-top: 1em;
            padding: 0;
            padding-top: 1.25em;
            & .block-head {
              align-items: center;
              display: flex;
              justify-content: space-between;
              margin: auto;
              margin-bottom: 2em;
              margin-left: 0;
              margin-right: 0;
            }
            &:first-child {
                border-top: 0;
                margin-top: 0;
                padding-top: 0;
            }
            &.block-grid {
              & .block-head {
                align-items: center;
                display: flex;
                margin: 0;
                min-height: 3.5em;
              }
            }
          }
          &.plans-group {
              padding: 0;
          }
        }
        & .pre-form {
            margin-bottom: 40px;
            padding: 0;
            & .display-2 {
                margin: 10px 0 15px;
            }
        }
        & .post-form {
            display: flex;
            flex-direction: column;
            margin: auto;
            margin-top: 30px;
            padding: 0 15px;
            @include breakpoint('sm'){ padding: 0; }
        }
        & .steps {
            min-height: 100vh;
            @include breakpoint('lg') {
                padding: 0 120px;
            }
            & .content {
              & .form-wrapper {
                max-width: 520px;
              }
            }
            & .message {
                &.message--body{
                    & .message-wrapper {
                        padding: 0 0 20px;
                    }
                }
            }
            &.chooseplan {
                @include breakpoint('sm') { padding: 0 20px; }
                // @include breakpoint('md') { padding: 0 40px 0 90px; }
            }
            &.payment {
                & .form-content {
                    padding: 0;
                    position: relative;
                }
                & .post-form {
                    flex-direction: row;
                }
            }
        }
    }
}

nav {
    &.nav-head-actions {
        margin: auto;
        margin-left: 0;
        margin-right: 0;
        & .btn-head-action {
            border: 1px solid;
            border-radius: 6px;
            color: $blue-A900;
            height: 40px;
            font-family: $font-semi-condensed;
            font-weight: 600;
            text-transform: uppercase;
        }
    }
    &.head-navigation {
        align-items: center;
        display: flex;
        @include breakpoint('lg'){
            min-width: 45px;
        }
        & .link-head-back {
            height: 40px;
            width: 40px;
            @include breakpoint('lg'){
                background-color: $grey-200;
                border-radius: 50%;
                height: 30px;
                width: 30px;
            }
        }
    }
    &.login-links{
        & .nav-opt{
            margin: 0 10px;
        }
    }
    &.menutabs {
      display: flex;
      flex-direction: row;
      & .inner {
        align-items: center;
        display: flex;
        flex: 1;
        flex-direction: row;
        font-size: .9375em;
        height: 64px;
        justify-content: space-evenly;
        margin: auto;
        max-width: 640px;
        padding: 0;
        @include breakpoint('sm'){
          flex: inherit;
          max-width: inherit;
        }
        & .custom-mat-link {
          align-items: center;
          display: flex;
          height: 50px;
          justify-content: center;
          position: relative;
          width: 50px;
          @include breakpoint('sm'){
            height: inherit;
            padding: 0 1em;
            width: inherit;
          }
          & .mobile-only {
              @include breakpoint('sm'){ display: none;}
          }
          & span {
            &.mobile--hidden {
              display: none;
              @include breakpoint('sm'){
                display: inline;
              }
            }
          }
          &.no--shrink {
            width: auto;
          }
          &.router-link-active {
              &:after {
                  @include breakpoint('sm'){
                      background-color: $blue-A400;
                      bottom: 12px;
                      border-radius: 50%;
                      content: ' ';
                      display: flex;
                      height: 6px;
                      left: calc(50% - 6px);
                      position: absolute;
                      width: 6px;
                  }
              }
          }
        }
      }
    }
    &.nav-links {
      & .link-option {
        font-family: $font-semi-condensed;
        font-size: .875em;
        color: $blue-A400;
        cursor: pointer;
        &:before {
            color: #d7d7d9;
            content: '\2022';
            margin: auto .5em;
        }
        &:first-child {
            &:before {
                content: none;
            }
        }
        &.disabled {
          color: $grey-500;
          cursor: normal;
        }
      }
    }
    &.nav-mobile {
        $heigth: 74px;
        background-color: $sidenav-bg-color;
        display: block;
        flex: 1;
        width: 100%;
        & .account {
            padding: $sidenav-padding;
        }
        & .body {
            padding: 16px 0 32px;
        }
        & .footer {
            align-items: center;
            display: flex;
            color: $grey-600;
            font-size: 0.6875em;
            justify-content: center;
            padding: 40px 16px;
        }
        & .top {
            display: flex;
            flex-direction: row;
            height: $heigth;
            padding: $sidenav-padding;
            & .ico-close-wrapper {
                display: flex;
                margin: auto;
                margin-left: 0;
                & .button-ico-close {
                    background-color: $grey-200;
                    margin: auto;
                }
            }
            & .ico-home {
                align-items: center;
                background-color: $grey-200;
                border-radius: 50%;
                display: flex;
                margin: auto;
                margin-right: 0;
                height: 40px;
                justify-content: center;
                width: 40px;
            }
            & .logo {
                background-repeat: no-repeat;
                background-size: contain;
                color: transparent;
                display: flex;
                font-size: 1px;
                height: 35px;
                margin: auto;
                margin-left: 0;
                width: 100%;
                z-index: 1;
                background-position: 50% 50%;
                background-size: 80px auto;
                height: 74px;
                left: 0;
                pointer-events: none;
                position: absolute;
                top: 0;
                &.blue {
                    background-image: url('../svg/logo-rebillia-blue.svg');
                }
            }
        }
    }
    &.nav-product-rateplan-charges {
      margin-top: .5em;
    }
    &.post-form {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
        margin-bottom: 4em;
        margin-top: 3em;
        max-width: 420px;
        padding: 0 1em;
        width: 100%;
        @include breakpoint('sm') {
            padding: 0;
        }
        & .button-cancel {
            margin-top: 2em;
        }
    }
    &.nav-card{
        display: flex;
        flex-direction: row;
        margin: auto;
        margin-top: 16px;
        // width: 100%;
        & .button-card{
            margin-right: 5px;
            &.button-delete{
                margin: auto;
                margin-right: 0px;
            }
        }
    }
}

p {
    &.active {
        color: $blue-A400;
    }
    &.label-ach-terms {
        color: $grey-600;
        font-family: $font-semi-condensed;
        font-size: .8125em;
        font-weight: 400;
    }
}

section {
  position: relative;
  &.chart {
    & .chart-wrapper {
      background-color: white;
      border: 1px solid $blue-grey-100;
      box-shadow: 0 0 12px -8px rgb(0,0,0,.45);
      & .chart-container {
        padding: .5em;
        & .line-chart {
          margin: 0;
        }
      }
    }
  }
  &.dropzone {
      align-items: center;
      background-color: $white;
      border: 1px dashed $grey-300;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      padding: 2rem;
      position: relative;
      text-align: center;
      width: 100%;
      @include breakpoint('sm') {
          min-width: 520px;
      }
      & .custom-mat-icon {
          margin-bottom: 1em;
      }
      & h3{
          font-size: 1.125em;
          font-weight: 400;
          margin-bottom: 8px;
      }
      & input {
          height: 100%;
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 2;
      }
      & label {
          background-color: $red-A400;
          border-radius: 24px;
          color: $white;
          padding: 8px 16px;
          width: 180px;
      }
  }
  &.grid {
    .grid-head {
      .grid-head-inner {
        align-items: center;
        display: flex;
        padding: .75em 0;
        position: relative;
        & .grid-actions {
            display: none;
            margin: auto;
            margin-right: 0;
            z-index: 1;
            @include breakpoint('md'){
                display: flex;
            }
        }
        & .grid-filter {
            margin: auto;
            z-index: 1;
            @include breakpoint('md'){
                margin: auto;
                margin-left: 0;
            }
            & .button-filter {
                height: 50px;
                width: auto;
            }
            & .label-filter {
                margin: auto;
                margin-left: 0;
            }
        }
        & .grid-search {
            display: none;
            @include breakpoint('md'){
                display: flex;
                position: absolute;
                width: 100%;
                z-index: 0;
            }
            & .head-search {
                margin: auto;
            }
        }
        & .grid-settings {
            display: none;
            @include breakpoint('md'){
                display: flex;
                margin: auto;
                margin-left: 0;
                margin-right: 0;
                z-index: 1;
            }
            & .button-settings {
                height: 50px;
                width: 50px;
            }
        }
      }
    }
    .grid-container {
      &.has-no-filter {
        margin-top: 2em;
      }
    }
    .grid-paginator {
        // min-height: 72px;
        @include breakpoint('md'){
            padding: 0 0 0 1em;
        }
    }
    &.grid--has-padding {
      padding: 2em 1.5em;
    }
  }
  &.loading {
      &.form-row-loader, &.grid-loader {
          & .loading-wrapper {
              align-items: center;
              display: flex;
              justify-content: left;
          }
          & .label-loading-message {
              font-size: .875em;
              font-weight: 400;
              font-style: italic;
              margin-left: 4px;
          }
      }
      &.form-row-loader {
        & .loading-wrapper {
          padding: 1em;
        }
      }
      &.full-page {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 100vh;
          width: 100%;
      }
      &.grid-loader {
          padding: 0;
          & .loading-wrapper {
              padding: 2em;
          }
      }
      &.page-loading, &.section-loading {
          display: flex;
          flex-direction: row;
          height: 100vh;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          pointer-events: none;
          top: 0;
          width: 100%;
          & .loading-wrapper {
              align-items: center;
              display: flex;
              flex-direction: column;
              margin: auto;
              padding: 30px 0;
              text-align: center;
              & .label-title {
                  margin: 10px auto;
                  max-width: 70%;
              }
          }
          & .background {
              &.blur{
                  filter: blur(8px);
                  -webkit-filter: blur(8px);
              }
          }
      }
      &.section-loading {
          left: 0;
          position: absolute;
      }
  }
  &.login {
    align-items: baseline;
    display: flex;
    flex-direction: row;
    height: 100vh;
    justify-content: center;
    overflow: hidden;
    overflow-y: scroll;
    padding: 0 16px;
    width: 100%;
    @include breakpoint('md') {
      align-items: center;
      justify-content: space-between;
      padding: 0;
    }
    & .side-adv {
      display: none;
      @include breakpoint('md'){
        display: flex;
        flex: 2;
        max-width: 40%;
      }
    }
    & .side-main {
      margin-top: 0;
      @include breakpoint('md'){
        margin: auto;
      }
      & .panel-header {
        text-align: center;
      }
      & .forgot, & .signin, & .signup, & .switch {
        display: flex;
        min-height: 100vh;
        @include breakpoint('sm'){
          margin: auto;
        }
        & .mat-form-field-wrapper {
            padding-bottom: 5px;
        }
        & .panel {
          & .pre-head {
            min-height: 74px;
            & .ico-isologo-login {
              margin: auto;
            }
          }
          &.panel-hero {
            margin: auto;
            max-width: 320px;
            width: 100%;
            @include breakpoint('sm'){
              max-width: 420px;
            }
            @include breakpoint('lg'){
              margin: auto;
            }
          }
        }
      }
    }
  }
  &.mat-progress-bar-wrapper {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 999;
  }
  &.message {
    & .message-wrapper {
        display: flex;
        flex-direction: column;
        padding: 0;
        & .message-description {
            font-family: $font-semi-condensed;
            font-size: $body-1-size;
            font-weight: 400;
            line-height: 1.25em;
        }
        & .message-inner {
            align-items: center;
            display: flex;
            flex-direction: column;
            margin: auto;
            justify-content: center;
            padding: 20px;
            width: 100%;
            & .message-body {
                max-width: 270px;
                @include breakpoint('lg'){
                    margin: auto 1em;
                    max-width: 100%;
                }
            }
            & .message-head {
                max-width: 270px;
                @include breakpoint('lg'){ max-width: 100%; }
            }
            &.attention {
                background-color: $amber-50;
                border: 1px solid $amber-100;
            }
            &.error {
                background-color: $red-50;
                border: 1px solid $red-100;
            }
            &.error-500 {
                background-color: transparent;
                border: none;
                text-align: center;
                @include breakpoint('lg'){
                    flex-direction: column;
                    justify-content: normal;
                    min-height: 80vh;
                }
                & .message-body {
                    margin: auto;
                    margin-top: 0;
                    max-width: 270px;
                    @include breakpoint('lg'){
                        margin: auto;
                        margin-top: 0;
                        max-width: 420px;
                    }
                    & .message-description {
                        font-size: 1em;
                    }
                }
                & .message-head {
                    margin: auto;
                    margin-bottom: 20px;
                    margin-top: 40px;
                    max-width: 270px;
                    @include breakpoint('lg'){
                        margin: auto;
                        margin-bottom: 20px;
                        max-width: 420px;
                    }
                }
            }
            &.error-404 {
                background-color: transparent;
                border: none;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                @include breakpoint('lg'){
                    min-height: 80vh;
                }
                & .message-body {
                    margin: auto;
                    margin-top: 0;
                    max-width: 270px;
                    @include breakpoint('lg'){
                        margin: auto;
                        margin-top: 0;
                        max-width: 420px;
                    }
                    & .message-description {
                        font-size: 1em;
                    }
                }
                & .message-head {
                    margin: auto;
                    margin-bottom: 20px;
                    margin-top: 40px;
                    max-width: 270px;
                    @include breakpoint('lg'){
                        margin: auto;
                        margin-bottom: 20px;
                        max-width: 420px;
                    }
                    & img {
                        height: auto;
                        max-height: 300px;
                        max-width: 100%;
                    }
                }
            }
            &.error-400 {
                background-color: $red-50;
                border: none;
                text-align: center;
                @include breakpoint('lg'){
                    border-top: 1px solid $red-A400;
                    text-align: left;
                }
                & .message-inner {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                }
            }
            &.nodata {
              align-items: center;
              background-color: transparent;
              border: 1px solid $grey-200;
              flex-direction: column;
              justify-content: center;
              & .message-body {
                  margin: 40px 0;
                  text-align: center;
                  @include breakpoint('lg'){
                      max-width: 45%;
                  }
              }
              & .message-head {
                  align-items: center;
                  display: flex;
                  justify-content: center;
                  max-width: 100%;
                  & img {
                      height: auto;
                      max-height: 300px;
                      max-width: 100%;
                  }
              }
              & .message-inner {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
              }
              & .message-title {
                  font-family: $font-semi-condensed;
                  font-size: 1.375em;
                  font-weight: 400;
                  margin: 0;
              }
            }
            &.success {
                background-color: $green-50;
                border: 1px solid $green-100;
            }
        }
        & .message-subtitle {
            margin-bottom: 15px;
            @include breakpoint('lg') {
                margin: 0;
            }
        }
        & .message-title {
            margin: 20px 0 10px;
            @include breakpoint('lg') {
                margin: 0;
            }
        }
    }
    &.message-form {
      .message-wrapper {
        padding: 20px;
        @include breakpoint('lg'){
          min-height: 100%;
        }
        .message-inner {
            align-items: center;
            display: flex;
            flex-direction: row;
            margin-left: 0;
            max-width: 100%;
            padding: 0;
            text-align: left;
            &.flex-column {
              flex-direction: column;
            }
        }
        .message-description {
            margin: auto;
            margin-left: 15px;
        }
        &.attention {
            background-color: $amber-50;
            border: 1px solid $amber-100;
        }
      }
    }
    &.message-grid {
      align-items: center;
      display: flex;
      justify-content: center;
      & .message-wrapper {
          @include breakpoint('lg'){
            min-height: 100%;
          }
          & .message-inner {
              @include breakpoint('lg'){
                  justify-content: center;
              }
          }
      }
    }
    &.message-login {
        @include breakpoint('lg') { padding: 0; }
        & .message-wrapper {
            align-items: center;
            flex-direction: row;
            padding: 15px;
            @include breakpoint('lg'){
              min-height: 100%;
            }
            & .message-icon {
                margin: auto;
                margin-left: 0;
                margin-right: 15px;
            }
        }
    }
    &.message-row {
      .message-wrapper {
        margin-bottom: 1em;
        padding: 1em;
        @include breakpoint('lg'){
          min-height: 100%;
        }
        .message-inner {
          padding: 0;
          @include breakpoint('sm'){
            align-items: flex-start;
            flex-direction: row;
            justify-content: normal;
          }
          .label-message-detail {
            font-family: $font-semi-condensed;
            font-size: .875em;
            margin-top: 1em;
            cursor: pointer;
            @include breakpoint('sm'){
              margin: auto;
              margin-left: 1em;
            }
          }
          &.flex-center {
            justify-content: center;
          }
          &.flex-column {
            flex-direction: column;
          }
        }
        &.attention {
          background-color: $amber-50;
          border-left: 2px solid $amber-100;
          text-align: initial;
          max-width: 520px;
        }
        &.error {
          background-color: $red-50;
          border-left: 2px solid $red-100;
        }
        &.info {
          background-color: $blue-50;
          border-left: 2px solid $blue-A400;
        }
        &.nodata {
          background-color: $grey-50;
          border: 1px solid $grey-200;
        }
      }
    }
    &.message--page-section {
        & .message-wrapper {
            border: 1px solid $grey-300;
            background-color: $white;
            padding: 0;
            margin-bottom: 40px;
            @include breakpoint('lg'){
              min-height: 100%;
            }
        }
    }
    &.system {
        background-color: $white;
        overflow: hidden;
        transition: max-height .75s ease-in-out;
        & .message-wrapper {
            // @include breakpoint('md'){
            //   padding: 16px;
            // }
            @include breakpoint('lg'){
              min-height: 100%;
            }
            & .message-container {
                & .message-inner {
                    flex-direction: column;
                    @include breakpoint('md'){
                      border-radius: 0 3px 3px 0;
                      box-shadow: inset 0 0 0 0 transparent,inset 0 0 0 0 transparent,0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15);
                      flex-direction: row;
                      justify-content: space-between;
                      margin-top: 8px;
                      padding: .5em;
                      transition: transform .3s ease-in-out;
                    }
                    & .message {
                        @include breakpoint('md'){
                            margin: auto;
                            margin-left: 0;
                            max-width: 85%;
                            text-align: left;
                        }
                    }
                    & .message-actions {
                        margin-top: 16px;
                        @include breakpoint('md'){
                            margin: auto;
                            margin-right: 0;
                        }
                    }
                    &:first-child {
                        margin-top: 0;
                    }
                    &.level-1 {
                        border-left: 2px solid $blue-A400;
                        background-color: $blue-50;
                    }
                    &.level-2 {
                        border-left: 2px solid $amber-A400;
                        background-color: $amber-50;
                    }
                    &.level-3 {
                        border-left: 2px solid $red-A400;
                        background-color: $red-50
                    }
                }
            }
        }
    }
  }
  &.new-address-book {
    & .custom-mat-checkbox {
      padding: 0 1em;
      @include breakpoint('md'){
        padding: inherit;
      }
    }
    & .custom-mat-form-field {
      padding: 1em 1em 0;
      @include breakpoint('md'){
        padding: inherit;
      }
      & .mat-form-field-outline {
        background-color: $white;
      }
    }
  }
  &.overlay-mobile-navigation {
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
  }
  &.panel {
    &.inner-panel {
      padding: 0;
      .panel-wrapper {
        .panel-collection {
          .panel-inner {
            border-bottom: 1px solid $grey-300;
            padding: 1em 0;
            .panel-body {
              display: flex;
              flex-direction: column;
              margin: 0;
            }
            .panel-head {
              align-items: center;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              min-height: 4.5em;
              width: 100%;
              .panel-action {
                display: flex;
                margin-left: 1em;
                .button-change-product {
                  margin: 0 1em;
                }
              }
              .panel-title {
                display: flex;
                flex-direction: column;
              }
            }
            .panel-section {
              display: flex;
              .section-block {
                display: flex;
                flex: 2;
                flex-direction: column;
                margin-bottom: 1em;
              }
            }
            &.plan-currency--first, &.billing-interval--first {
              .panel-head {
                .panel-action {
                  display: none;
                }
              }
            }
            &.billing-interval--first {
              padding: 0;
            }
          }
        }
      }
      &.panel-product-sale-channel {
        .panel-wrapper {
          .panel-collection {
            .panel-inner {
              .panel-product-header {
                background-color: $grey-50;
                border: 1px solid $grey-300;
                padding: 1em;
              }
            }
          }
        }
      }
    }
    &.popup-panel {
        background-color: $white;
        box-shadow: 0 2px 3px 0 rgba(0,0,0,0.25);
        display: none;
        opacity: .01;
        overflow: hidden;
        padding: 0;
        pointer-events: none;
        visibility: hidden;
        width: 20em;
        z-index: -1;
        @include prefix(backface-visibility, hidden);
        @include prefix(transform-origin, top center);
        & .panel-message {
            & .custom-mat-icon {
                background-color: $blue-50;
                border: 1px solid $blue-100;
                border-radius: 50%;
                fill: $blue-A400;
                height: 60px;
                width: 60px;
                & svg {
                    height: 32px;
                    width: 32px;
                }
            }
            & .custom-mat-link {
                margin: 20px 0 0;
            }
            & .inner {
                align-items: center;
                display: flex;
                font-size: .875em;
                flex-direction: column;
                padding: 30px;
                text-align: center;
            }
            & .label-message-title {
                //font-size: 1em;
                font-weight: 500;
                margin: 20px 0 10px;
            }
            & .label-message-subtitle {
                font-weight: 400;
            }
        }
        &.panel-account, &.panel-help, &.panel-quick-access, &.panel-search {
          @include breakpoint('lg'){
            border-radius: 0;
            border: solid 1px $grey-300;
            bottom: 10px;
            display: flex;
            height: auto;
            left: 75px;
            overflow: visible;
            position: fixed;
            width: auto;
            z-index: 99;
          }
        }
        &.panel-account {
            bottom: .5em;
            left: 0;
            min-width: 320px;
            & .panel-side {
              display: flex;
              flex-direction: column;
            }
            & .panel-info {
                display: flex;
                flex-direction: row;
                padding: 1em;
                min-width: 320px;
                & .user-profile {
                  display: flex;
                  flex-direction: column;
                  margin: auto;
                  margin-left: 20px;
                  & .user-name {
                    align-items: center;
                    display: flex;
                    line-height: 22px;
                    margin-bottom: .5em;
                    & .badge {
                      margin-left: .5em;
                    }
                  }
                }
            }
            & .panel-legal {
              align-items: center;
              border-top: 1px solid $grey-50;
              color: $grey-500;
              display: flex;
              font-size: .75em;
              font-weight: 400;
              height: 48px;
              padding: 0 1.65em;
              & .divider {
                margin: 0 .5em;
              }
              & .link {
                text-decoration: underline;
              }
            }
            & .side-upgrade {
              @include breakpoint('md'){
                align-items: center;
                background-color: $blue-B50;
                display: flex;
                flex-direction: column;
                max-width: 310px;
                padding: 2em;
                text-align: center;
              }
              & .label {
                margin: auto;
                margin-bottom: 2em;
              }
              & .title {
                margin: auto;
                margin-top: 1em;
                margin-bottom: .5em;
              }
            }
        }
        &.panel-gear-settings {
            &.panel-dashboard-preferences {
                & .popup-panel {
                    right: 0;
                    top: 0;
                    @include breakpoint('md'){
                        right: 20px;
                        top: 135px;
                    }
                    @include breakpoint('lg'){
                        top: 55px;
                    }
                }
            }
        }
        &.panel-preferences {
            border-radius: 0;
            display: flex;
            flex-direction: column;
            height: 100vh;
            position: fixed;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 99;
            @include breakpoint('md'){
                border: solid 1px $grey-300;
                height: auto;
                min-width: 220px;
                overflow: visible;
                position: absolute;
                right: .5em;
                top: 125px;
                width: auto;
            }
            @include breakpoint('lg'){
                right: 1.5em;
            }
            & .head {
                align-items: center;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 16px;
                @include breakpoint('md'){ display: none; }
            }
            & .list-popup-preferences {
                display: block;
                max-width: 100%;
                min-height: 60px;
                overflow: hidden;
                padding: 0;
                width: 100%;
                & .custom-mat-list-item {
                    align-items: center;
                    background-color: $white;
                    border-top: 1px dotted $grey-300;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: row;
                    font-size: 14px;
                    height: 48px;
                    justify-content: space-between;
                    padding: 0 0 0 8px;
                    width: 100%;
                    & .button-drag {
                        color: $grey-600;
                        cursor: move;
                        margin: auto;
                        margin-right: 0;
                    }
                    & .custom-mat-list-item-placeholder {
                        background-color: $grey-500;
                        border: dotted 3px #999;
                        height: 48px;
                        transition: transform .33s cubic-bezier(0, 0, 0.2, 1);
                    }
                    &.is--hidden {
                        display: none;
                    }
                }
                &.cdk-drop-list-dragging {
                    & .custom-mat-list-item:not(.cdk-drag-placeholder) {
                        transition: transform .33s cubic-bezier(0, 0, 0.2, 1);
                    }
                }
            }
        }
        &.panel-search {
            background-color: $blue-grey-50;;
            border: 0 none;
            bottom: 0;
            display: flex;
            flex-direction: column;
            height: 100vh;
            left: 0;
            min-width: 370px;
            overflow: hidden;
            overflow-y: auto;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1;
            @include breakpoint('lg'){
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
                left: $sidenav-width;
                max-width: 370px;
                width: auto;
            }
            & .body {
                & .message-search-nodata {
                    border-top: 1px solid $grey-200;
                    padding: 16px;
                    & .primary {
                        margin-bottom: 5px;
                    }
                }
                & .section-search-result {
                    border-top: 1px solid $grey-200;
                    padding: 16px 0;
                    & .header {
                        align-items: center;
                        display: flex;
                        flex-direction: row;
                        height: 40px;
                        justify-content: space-between;
                        padding: 0 16px;
                    }
                }
            }
            & .head {
                align-items: center;
                background-color: $white;
                display: flex;
                flex-direction: row;
                height: 74px;
                min-height: 74px;
                padding: 0 16px;
                & .button-close {
                    margin: auto;
                    margin-right: 0;
                }
                & .label-result {
                    display: none;
                    font-size: .875em;
                    margin: auto;
                    margin-left: 0;
                    @include breakpoint('lg'){ display: flex; }
                }
                & .field-search {
                    background-color: transparent;
                    border: 1px solid $grey-300;
                    border-radius: 12px;
                    color: $grey-900;
                    height: 40px;
                    margin: auto;
                    margin-right: 10px;
                    transition: background-color .3s ease-in-out;
                    width: 100%;
                    @include breakpoint('lg'){ display: none; }
                    & .mat-form-field-wrapper {
                        height: 100%;
                        margin: 0;
                        padding: 0;
                        & .mat-form-field-underline {
                            display: none;
                        }
                        & .mat-form-field-flex {
                            height: 100%;
                            margin: 0;
                            padding: 0;
                            & .mat-form-field-prefix {
                                display: flex;
                                margin: auto;
                                justify-content: center;
                                width: 40px;
                            }
                            & .mat-form-field-infix {
                                border-top: 0;
                                display: flex;
                                height: 100%;
                                margin: auto;
                                padding: 0;
                                width: 100%;
                                & input {
                                    &.mat-input-element {
                                        caret-color: $grey-900;
                                        font-size: $body-1-size;
                                        margin: auto;
                                        margin-left: 0;
                                    }
                                }
                                & span {
                                    &.mat-form-field-label-wrapper {
                                        display: flex;
                                        font-size: $body-1-size;
                                        padding: 0;
                                        top: 0;
                                        & .mat-form-field-label {
                                            color: $grey-900;
                                            margin: auto;
                                            margin-right: 0;
                                            position: relative;
                                            top: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.mat-focused {
                        border: 1px solid $blue-A400;
                        & .mat-form-field-wrapper {
                            & .mat-form-field-flex {
                                & .mat-form-field-infix {
                                    & span {
                                        &.mat-form-field-label-wrapper {
                                            & .mat-form-field-label {
                                                color: $grey-400;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.is--active {
                        background-color: $blue-grey-900;
                        color: $white;
                    }
                }
            }
            & .loading {
                align-items: center;
                display: flex;
                height: 100vh;
                justify-content: center;
                left: 0;
                overflow: hidden;
                pointer-events: none;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
        &.panel-quick-access {
            bottom: auto;
            flex-direction: column;
            max-width: 350px;
            min-width: 350px;
            top: 130px;
        }
    }
  }
  &.page {
    background-color: $blue-grey-50;
    min-height: calc(100vh - 74px);
    transition: margin .3s ease-in-out;
    @include breakpoint('sm'){
        background-color: $white;
    }
    @include breakpoint('lg'){
        flex: 1;
        min-height: 100vh;
        margin: auto;
        margin-left: $sidenav-width;
    }
    & .app-billing, & .app-business-detail, & .app-currencies, & .app-customers, & .app-dashboard, & .app-emails-template, & .app-emails-editor, & .app-gateways, & .app-gateway-list, & .app-import, & .app-integration, & .app-users {
        display: flex;
        flex-direction: column;
    }
    & .app-dashboard {
      background-color: #f6f7f9;
      & .head {
        background-color: transparent;
        & .head-container {
            & .label-title {
                @include breakpoint('sm'){
                    margin: auto;
                    margin-left: 0;
                }
            }
        }
      }
    }
    & .body {
        display: flex;
        flex-direction: column;
        .body-container {
            margin: auto;
            margin-top: 0;
            width: 100%;
            padding: 0;
            @include breakpoint('md') {
              padding: 0 .5em 1em;
            }
            @include breakpoint('lg') {
              padding: 0 2em 2em
            }
            &.body-container-invoice {
              & .post-form {
                max-width: 100%;
                width: 100%;
              }
            }
            &.body-container-notifications, &.body-container-notifications-inbox {
              @include breakpoint('md'){
                display: flex;
                flex-direction: row;
              }
            }
            &.body-container-rateplan, &.body-container-subscription {
              padding: 0;
              & .form-wrapper {
                &.form-wrapper-rateplan, &.form-wrapper-subscription {
                  padding: 0;
                  & .form-content {
                    &.form-content-body {
                      & .form-section {
                        @include breakpoint('md') {
                          padding: 1em 4.5em;
                        }
                        @include breakpoint('lg') {
                          padding: 1em 4.5em;
                        }
                      }
                    }
                    &.form-content-head {
                      & .form-section {
                        &.form-rateplan-details {
                          @include breakpoint('md') {
                            padding: 2em 4.5em;
                          }
                          @include breakpoint('lg') {
                            padding: 2em 4.5em;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
        }
        .body-loader {
            height: 100vh;
            left: 0;
            pointer-events: none;
            position: fixed;
            top: 0;
            transition: left .3s ease-in-out, width .3s ease-in-out;
            width: 100%;
            z-index: 1;
            @include breakpoint('lg'){
                left: 260px;
                width: calc(100% - 260px);
            }
        }
        .form-pre {
            margin: 32px 16px;
            @include breakpoint('md') {
                flex: 1;
                margin: 0;
                margin-bottom: 40px;
                max-width: 670px;
            }
            @include breakpoint('lg') {
                max-width: 990px;
            }
            & .label-title {
                margin-bottom: 10px;
            }
        }
        .form-content {
          padding: 0;
          width: 100%;
          @include breakpoint('sm'){
            width: 100%;
          }
          .form-call-to-action {
            align-items: center;
            display: flex;
            justify-content: center;
            margin-bottom: 40px;
            min-height: 64px;
          }
          .form-section {
            border: 0 none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0;
            padding: 0;
            @include breakpoint('sm'){
              border-bottom: 1px dotted $grey-300;
              flex-direction: row;
              padding: 1em;
            }
            @include breakpoint('md'){
              justify-content: space-between;
            }
            @include breakpoint('lg'){
              padding: 1em 0;
            }
            .form-section-side {
              @include breakpoint('sm'){
                display: block;
                flex: 2;
                max-width: 30%;
              }
              @include breakpoint('md'){
                max-width: 220px;
              }
              @include breakpoint('lg'){
                max-width: 240px;
              }
              @include breakpoint('xl'){
                max-width: 260px;
              }
              .label-note {
                display: none;
                color: $grey-700;
                font-size: .8125em;
                font-style: italic;
                margin-top: 25px;
                @include breakpoint('sm') {
                  display: block;
                  margin-right: 1em;
                }
              }
              .label-subtitle {
                display: none;
                @include breakpoint('sm') {
                  color: $grey-700;
                  font-size: .875em;
                  display: block;
                  margin-bottom: 1em;
                  margin-right: 2em;
                  margin-top: .5em;
                }
              }
              .label-title {
                color: $blue-grey-400;
                font-family: $font-semi-condensed;
                font-size: .75em;
                font-weight: 400;
                margin: 16px;
                text-transform: uppercase;
                @include breakpoint('sm'){
                  font-family: $font-regular;
                  font-size: 1.375em;
                  font-weight: 300;
                  margin: 16px 16px 16px 0;
                  text-transform: capitalize;
                }
              }
            }
            .form-section-container {
                background-color: $white;
                padding: 1em;
                width: 100%;
                @include breakpoint('sm'){
                    background-color: transparent;
                    flex: 2;
                    max-width: 100%;
                    padding: 0;
                }
                .form-section-body {
                  flex: 1;
                  margin: auto;
                  margin-top: 0;
                  @include breakpoint('sm'){
                      margin: 0;
                  }
                  .form-block {
                    border-top: 1px dotted $grey-300;
                    display: flex;
                    flex-direction: column;
                    margin: auto;
                    margin-left: 0;
                    margin-top: 1em;
                    padding: 0;
                    padding-top: 1.25em;
                    .block-body {
                      .block-body-section {
                        padding: 0;
                        padding-bottom: 1.5em;
                        .block-title {
                          border-bottom: 1px solid #E6E9EF;
                          margin-bottom: 1.5em;
                          padding: 1em 0;
                        }
                      }
                    }
                    .block-footer {
                      border: 0 none;
                      border-top: 1px solid #E6E9EF;
                      margin: 0;
                      margin-top: 1.5em;
                      padding: 1em 0 0;
                    }
                    .block-head {
                      align-items: flex-start;
                      display: flex;
                      flex-direction: column;
                      justify-content: flex-start;
                      margin: auto;
                      margin-bottom: .5em;
                      margin-left: 0;
                      margin-right: 0;
                      @include breakpoint('md'){
                        margin-bottom: 0;
                        margin-left: 0;
                        margin-right: auto;
                        min-height: 45px;
                      }
                    }
                    &:first-child {
                        border-top: 0;
                        margin-top: 0;
                        padding-top: 0;
                    }
                    &.block-grid {
                      & .block-head {
                        align-items: center;
                        display: flex;
                        margin: 0;
                        min-height: 3.5em;
                      }
                    }
                    &.block-settings {
                      padding: .5em;
                      @include breakpoint('md'){
                        padding: 0;
                        padding-top: 1.25em;
                      }
                      & .block-head {
                        align-items: flex-start;
                        flex-direction: column;
                        justify-content: flex-start;
                        margin-bottom: .5em;
                        margin-left: 0;
                        margin-right: auto;
                        @include breakpoint('md'){
                          margin-bottom: 0;
                          margin-left: 0;
                          margin-right: auto;
                        }
                      }
                      & .form-row {
                        align-items: flex-start;
                        border-bottom: 1px solid $grey-300;
                        flex-direction: column;
                        @include breakpoint('md'){
                          align-items: center;
                          flex-direction: row;
                          justify-content: space-between;
                        }
                        &:first-child {
                          border-top: 1px solid $grey-300;
                        }
                        &.row-status {
                          padding: 1em 0;
                          @include breakpoint('md'){
                            padding: inherit;
                          }
                        }
                        &.row-panel {
                          border: 1px solid $grey-200;
                          .row-inner {
                            padding: 1em 2em;
                          }
                        }
                      }
                    }
                    &.block-plan-rules {
                      padding: .5em;
                      @include breakpoint('md'){
                        padding: 0;
                        padding-top: 1.25em;
                      }
                      & .block-head {
                        align-items: flex-start;
                        flex-direction: column;
                        justify-content: flex-start;
                        margin-bottom: .5em;
                        margin-left: 0;
                        margin-right: auto;
                        @include breakpoint('md'){
                          margin-bottom: 0;
                          margin-left: 0;
                          margin-right: auto;
                        }
                      }
                      & .form-row {
                        align-items: flex-start;
                        border-bottom: 1px solid $grey-300;
                        flex-direction: column;
                        @include breakpoint('md'){
                          align-items: center;
                          flex-direction: row;
                          justify-content: space-between;
                        }
                        &:first-child {
                          border-top: 1px solid $grey-300;
                        }
                        &.row-status {
                          padding: 1em 0;
                          @include breakpoint('md'){
                            padding: inherit;
                          }
                        }
                        &.row-panel, &.row-rules {
                          border: 1px solid $grey-200;
                          .row-inner {
                            padding-top: 1em;
                            padding-bottom: 1em;
                            padding-left: 2em;
                          }
                          & .custom-mat-form-field{
                            padding: 1em 1em 0;
                            @include breakpoint('md') {
                              padding: inherit;
                            }
                            &.custom-mat-form-field-outline {
                              &.expirationAction {
                                width: 310px;
                              }
                              &.loyaltyAction {
                                width: 150px;
                                padding-left: 0px;
                              }
                              &.prepaidAction {
                                width: 200px;
                                padding-left: 0px;
                              }
                              &.event {
                                width: 310px;
                              }
                              &.loyaltyEvent {
                                width: 200px;
                              }
                              &.expirationEvent {
                                width: 200px;
                              }
                            }
                            // &.expirationCount {
                            //   margin-left: 10px;
                            //   margin-right: 10px;
                            // }
                            // &.loyaltyRulesAmount {
                            //   width: 120px;
                            //   margin-left: 10px;
                            //   margin-right: 10px;
                            // }
                            // &.loyaltyRulesAmountType {
                            //   width: 190px;
                            //   margin-left: 10px;
                            //   margin-right: 10px;
                            // }
                            // &.loyaltyCount {
                            //   width: 145px;
                            //   margin-left: 5px;
                            //   margin-right: 5px;
                            // }
                            // &.prepaidRulesAmount{
                            //   width: 180px;
                            //   margin-left: 10px;
                            //   margin-right: 10px;
                            // }
                            // &.prepaidRulesAmountType {
                            //   width: 190px;
                            //   margin-left: 10px;
                            //   margin-right: 10px;
                            // }
                            // &.prepaidCount {
                            //   width: 145px;
                            //   margin-left: 5px;
                            //   margin-right: 5px;
                            // }
                          }
                        }
                      }
                    }
                  }
                  .form-panel {
                      padding: 1em 0;
                  }
                  .form-row {
                      display: flex;
                      min-height: 64.5px;
                      margin: auto 0;
                      & .form-row-container {
                        display: flex;
                        flex: 1 100%;
                        flex-direction: column;
                      }
                      & .form-row-inner {
                        display: flex;
                        flex: 1 100%;
                      }
                      & .form-row-section {
                        display: flex;
                        flex: 1 100%;
                        flex-direction: column;
                        margin-bottom: 1.5em;
                        & .row-section-inner-nav {
                          display: flex;
                          margin-top: 1em;
                        }
                        &.row-section-invoice-detail {
                          flex-direction: column;
                          margin-bottom: 0;
                          width: 100%;
                          @include breakpoint('md'){
                            flex-direction: row;
                            margin-bottom: 2em;
                          }
                        }
                        &.row-section-invoice-summary {
                          align-items: center;
                          flex-direction: row;
                          justify-content: space-between;
                          margin: 0;
                          min-height: 40px;
                          &.row-section-invoice-exchange-rate {
                            & .label-total-exchangeRate {
                              color: $grey-500;
                              font-size: .875em;
                              font-style: italic;
                              font-weight: 300;
                              margin: auto;
                              margin-left: 0;
                            }
                          }
                          &.row-section-invoice-total {
                            border-top: 1px solid $grey-200;
                            margin-top: 1em;
                            padding: 1em 0;
                          }
                          &.row-section-invoice-balance {
                            border-top: 1px solid $grey-200;
                            padding: 1em 0;
                          }
                        }
                      }
                      &:first-child{
                        @include breakpoint('sm'){
                          margin-top: 1em;
                        }
                      }
                      &[hidden] {
                        display: none;
                      }
                      &.row-address {
                        flex-direction: column;
                        @include breakpoint('sm'){
                          flex-direction: row;
                        }
                        & .field-contact-name, & .field-company-name, & .field-contact-email, & .field-contact-phone, & .field-street-1, & .field-street-2 {
                          flex: 1;
                          @include breakpoint('sm'){
                            flex: 2;
                          }
                        }
                      }
                      &.row-add-panel {
                          display: block;
                          & .button-wrapper {
                            margin: .5em 0 0;
                          }
                      }
                      &.row-addon-price {
                          flex-direction: column;
                          @include breakpoint('md'){
                              flex-direction: row;
                          }
                          & .field-charge-type {
                              flex: 1 100%;
                              @include breakpoint('md'){
                                  flex: 3 33%;
                                  margin-right: 1em;
                              }
                          }
                          & .field-charge-unit {
                              flex: 1 100%;
                              @include breakpoint('md'){
                                  flex: 3 33%;
                                  margin-right: 1em;
                              }
                          }
                          & .field-amount {
                              flex: 1 100%;
                              @include breakpoint('md'){
                                  flex: 3 33%;
                              }
                          }
                          & .field-percentage {
                              flex: 1 100%;
                              @include breakpoint('md'){
                                  flex: 3 33%;
                              }
                          }
                      }
                      &.row-address-info {
                          display: flex;
                          flex-direction: column;
                          & .address-info {
                              display: flex;
                              flex-direction: column;
                              &.address-contact-info {
                                  margin-bottom: 8px;
                              }
                          }
                          & .label-address-info {
                              font-size: .875em;
                          }
                      }
                      &.row-calendar-months, &.row-calendar-days {
                          & .form-row-inner {
                              margin-bottom: 1em;
                          }
                      }
                      &.row-calendar-week-days {
                          min-height: 0!important;
                          & .form-row-inner {
                              align-items: center;
                              display: flex;
                              flex-direction: row;
                              justify-content: space-around;
                              padding-bottom: 1em;
                          }
                          & .custom-mat-button-toggle-group {
                              flex: 1;
                              & .custom-mat-button-toggle {
                                  flex: 7;
                                  & .mat-button-toggle-button {
                                      & .mat-button-toggle-label-content {
                                          & span {
                                              display: none;
                                              font-size: .875em;
                                              @include breakpoint('md'){
                                                  display: inline-flex;
                                              }
                                              &.mobile--only {
                                                  display: inline-flex;
                                                  @include breakpoint('md'){
                                                      display: none;
                                                  }
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                      }
                      &.row-charge-model-tiered {
                        & .form-row-inner {
                          background-color: $grey-50;
                          border: 1px solid $grey-200;
                          padding: .5em;
                          @include breakpoint('sm'){
                            margin-bottom: 1em;
                            padding: 1em 4em 1em 1em;
                          }
                          & .custom-mat-form-field {
                            & .mat-form-field-wrapper {
                              padding: 0;
                              & .mat-form-field-flex {
                                align-items: center;
                                background-color: $white;
                                margin: 0;
                                & .mat-form-field-outline {
                                  top: 0;
                                }
                                & .mat-form-field-prefix {
                                  bottom: 0;
                                  top: 1.5em;
                                }
                              }
                            }
                            & .mat-form-field-suffix {
                              margin: 1.5em 0 1em;
                              top: 0;
                            }
                            &.field-tiered-unit {
                              flex: 3 33%;
                              margin-right: .5em;
                              @include breakpoint('md'){
                                margin-right: 1em;
                              }
                            }
                            &.mat-form-field-disabled {
                              & .mat-form-field-wrapper {
                                & .mat-form-field-flex {
                                  background-color: $grey-50;
                                }
                              }
                              & .mat-form-field-suffix {
                                color: rgba(0, 0, 0, 0.38);
                              }
                            }
                          }
                        }
                      }
                      &.row-checkbox {
                        display: inline-flex;
                        flex-direction: column;
                        justify-content: center;
                        min-height: 76px;
                        @include breakpoint('sm'){
                          padding-top: 0;
                        }
                        & .label-checkbox-description {
                            margin-top: 5px;
                            padding-left: 32px;
                        }
                      }
                      &.row-checkbox-group {
                        display: flex;
                        flex-direction: column;
                        & .mat-checkbox-layout {
                          & .mat-checkbox-inner-container {
                            margin: auto .5em auto 0;
                          }
                          & .mat-checkbox-label {
                            margin: auto 0;
                          }
                        }
                      }
                      &.row-custom-billing-frequency {
                          & .field-frequency-unit {
                              flex: 1;
                              @include breakpoint('sm') {
                                  flex: 2;
                              }
                          }
                          & .field-frequency-value {
                              & input {
                                  text-align: right;
                              }
                          }
                      }
                      &.row-customer-template {
                        flex-direction: column;
                        line-height: 1;
                        width: 100%;
                        & .button-clean-search {
                          height: 1.5em;
                          width: 1.5em;
                        }
                        & .details {
                          align-items: center;
                          border-bottom: 1px dotted rgb(155, 137, 137);
                          display: flex;
                          font-weight: 400;
                          justify-content: space-between;
                          letter-spacing: normal;
                          line-height: 1.125;
                          margin-bottom: 16px;
                          padding-bottom: 16px;
                          & .customer-name {
                            color: rgba(0, 0, 0, 0.54);
                            min-height: 21px;
                            & .customer-email > span {
                              font-size: 0.875em;
                              font-weight: 300;
                            }
                          }
                        }
                        & .title {
                          color: #bfbfbf;
                          font-size: .6875em;
                          margin-bottom: 0.9375em;
                          text-transform: uppercase;
                        }
                      }
                      &.row-data-detail {
                        align-items: baseline;
                        border-bottom: 1px dotted $grey-200;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        min-height: 3.75em;
                        padding: 1em 0;
                        @include breakpoint('md'){
                          justify-content: initial;
                          & .label {
                            min-width: 120px;
                          }
                        }
                        &.data-payment-method {
                          align-items: center;
                          & .ico-card {
                            margin: auto;
                            margin-right: .5em;
                          }
                        }
                        &:first-child {
                          border-top: 1px dotted $grey-200;
                          margin-top: 0;
                        }
                      }
                      &.row-data-json {
                        background-color: $grey-50;
                        border: 1px solid $grey-200;
                        padding: 1em;
                          & .json-code-block {
                            font-size: 13px;
                            border-radius: 5px;
                            padding: 5px;
                            white-space: pre-wrap;
                            width: 100%;
                          }
                      }
                      &.row-date-range {
                        display: flex;
                        @include breakpoint('md'){
                          max-width: 240px;
                          padding-left: 35px;
                        }
                        & .field-date-picker {
                          flex: 2;
                          &.field-date-start {
                            margin-right: 1em;
                          }
                        }
                      }
                      &.row-description {
                        display: flex;
                        flex-direction: column;
                        min-height: inherit;
                        padding: 1em 0;
                      }
                      &.row-enable-trial {
                        display: flex;
                        @include breakpoint('md'){
                          padding-left: 35px;
                        }
                      }
                      &.row-flex-wrap {
                        justify-content: space-between;
                        flex-wrap: wrap;
                        .md-size1of2 {
                          width: 49%;
                        }
                      }
                      &.row-invoice-detail {
                        align-items: flex-start;
                        flex-direction: column;
                        @include breakpoint('md'){
                          flex-direction: row;
                        }
                        & .form-row-container {
                          margin-bottom: 1em;
                          order: 3;
                          width: 100%;
                          @include breakpoint('md'){
                            margin-bottom: auto;
                            order: 1;
                          }
                          &.invoice-status {
                            order: 1;
                            @include breakpoint('md'){
                              order: 2;
                            }
                          }
                        }
                        & .invoice-status {
                          background-color: $grey-100;
                          border-radius: .5em;
                          flex: 1 100%;
                          padding: 1em;
                          width: 100%;
                          @include breakpoint('md'){
                            margin-bottom: 0;
                          }
                          & .label-payment-collected {
                            color: $blue-A900;
                            font-size: .875em;
                          }
                          & .label-status {
                            align-items: flex-start;
                            background-color: transparent;
                            border: 0 none;
                            border-radius: 0;
                            font-size: 1.5em;
                            font-weight: 300;
                            justify-content: flex-start;
                            line-height: inherit;
                            max-width: 100%;
                            min-width: 0;
                            padding: 0;
                            @include breakpoint('md'){
                              font-size: 1.5em;
                            }
                          }
                          &.status-posted, &.status-partial-paid {
                            background-color: $blue-50;
                            border: 1px solid $blue-200;
                            color: $blue-A900
                          }
                          &.status-draft {
                            background-color: $blue-grey-50;
                            border-color: $blue-grey-300;
                            color: $blue-grey-400;
                          }
                          &.status-partial-refund, &.status-refund {
                            background-color: $amber-300;
                            color: $amber-900;
                          }
                          &.status-request-payment {
                            background-color: $amber-50;
                            border-color: $amber-300;
                            color: $amber-900;
                          }
                          &.status-paid {
                            background-color: $green-50;
                            border-color: $green-300;
                            color: $green-A700;
                          }
                          &.status-awaitingForSettlement {
                            background-color: $amber-50;
                            border-color: $amber-300;
                            color: $amber-900;
                          }
                          &.status-payment-due {
                            background-color: $red-50;
                            border-color: $red-A400;
                            color: $red-A700;
                          }
                        }
                        & .label-customer-info {
                          align-items: center;
                          display: flex;
                          height: 1.5em;
                          line-height: 1.5em;
                          & .custom-mat-icon {
                            margin-right: .5em;
                          }
                        }
                        & .label-customer-name {
                          color: $blue-A900;
                        }
                        & .label-section-title {
                          color: $grey-600;
                          font-size: .6875em;
                          font-weight: 300;
                          margin-bottom: .5em;
                        }
                      }
                      &.row-invoice-transaction, &.row-invoice-log {
                        align-items: center;
                        display: flex;
                        margin: 0;
                        .row-inner {
                          display: flex;
                          flex-direction: column;
                        }
                        .status {
                          align-items: center;
                          border: 1px solid;
                          border-radius: 8px;
                          display: inline-flex;
                          font-size: .8125em;
                          font-weight: 400;
                          justify-content: center;
                          line-height: 25px;
                          margin-right: 1em;
                          min-width: 70px;
                          text-align: center;
                        }
                      }
                      &.row-item-amount {
                          display: flex;
                          & .field-amount {
                              flex: 1 100%;
                          }
                          & .field-amount-qty {
                              flex: 2 100%;
                              max-width: 25%;
                          }
                          & .field-currency {
                              flex: 2 100%;
                              max-width: 75px;
                              margin-right: 1em;
                          }
                          & .field-price-model {
                              flex: 2 100%;
                              max-width: 120px;
                              margin-right: 1em;
                          }
                          & .field-number-unit {
                              flex: 3 100%;
                              margin-right: 1em;
                              max-width: 33%;
                          }
                      }
                      &.row-item-setup {
                          & .field-price-setup {
                              flex: 2 100%;
                          }
                          & .field-setup-option {
                              flex: 2 100%;
                              margin-right: 1em;
                              max-width: 210px;
                          }
                      }
                      &.row-line-item {
                          align-items: center;
                          display: flex;
                      }
                      &.row-new-address {
                        & .form-row-group {
                          padding: 0;
                        }
                      }
                      &.row-note {
                          margin-bottom: 24px;
                          min-height: 0;
                          & .label-note {
                              color: $grey-500;
                              transform: translateY(-8px);
                          }
                      }
                      &.row-shipping-rates {
                        display: flex;
                        flex-direction: column;
                      }
                      &.row-subscription-start {
                          & .custom-mat-form-field {
                              flex: 2 100%;
                              &.field-start-date {
                                  max-width: 120px;
                                  margin-left: 1em;
                                  & .mat-form-field-infix {
                                      min-height: 61px;
                                  }
                              }
                              &.field-start-on {
                                  max-width: 120px;
                                  margin-left: 1em;
                              }
                              &.field-start-on-options {
                                  max-width: 120px;
                                  margin-left: 1em;
                              }
                          }
                      }
                      &.row-time-frame, &.row-invoice-due{
                          display: flex;
                          flex: 1 100%;
                          flex-direction: column;
                          @include breakpoint('sm'){
                              flex-direction: row;
                              flex-wrap: nowrap;
                          }
                          & .field-time-frame, & .field-invoice-term {
                              @include breakpoint('sm'){
                                  flex: 2 100%;
                                  margin: auto;
                                  margin-left: 0;
                                  margin-right: 1em;
                                  max-width: 120px;
                              }
                          }
                          & .field-date-picker {
                              @include breakpoint('sm'){
                                  flex: 2 100%;
                              }
                          }
                      }
                      &.row-upcoming-date {
                        align-items: baseline;
                        background-color: $grey-100;
                        border: 1px solid $grey-200;
                        border-radius: .5em;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin: 1em 0;
                        padding: 1em;
                        & .label-dow {
                          display: flex;
                          flex-direction: column;
                          min-width: 120px;
                        }
                        &:first-child {
                          margin-top: 0;
                        }
                      }
                  }
                  .form-row-wrapper {
                    padding: 0;
                    .form-row {
                      margin-top: 0;
                      padding: 0;
                    }
                  }
                  .section-tax-regions {
                    .form-block {
                      &.block-grid {
                        .block-head {
                          @include breakpoint('sm'){
                            flex-direction: row;
                            justify-content: space-between;
                          }
                        }
                      }
                    }
                  }
                  &.section-form-invoice {
                    @include breakpoint('sm'){
                      display: flex;
                    }
                    & .row-invoice-due {
                      @include breakpoint('sm'){
                        flex: 2;
                      }
                    }
                    & .row-time-frame {
                      @include breakpoint('sm'){
                        flex: 2;
                      }
                    }
                  }
                }
                .form-section-head {
                    margin-bottom: 1em;
                    margin-left: 0;
                    margin-right: 0;
                    margin-top: 0;
                    padding: 0;
                    & .form-section-inner {
                      display: flex;
                      flex-direction: column;
                      margin: 0;
                    }
                }
                .form-section-inner {
                    margin-top: 2em;
                    & .row-headline {
                        padding: 1em 1em 0;
                        @include breakpoint('sm') {
                            padding: 0;
                        }
                    }
                    &:first-child {
                        margin-top: 0;
                    }
                }
                &.margin--auto {
                    margin: auto;
                }
                &.section-form-billto, &.section-form-shipto {
                  padding: 0;
                }
                &.section-form-gateway-payment-list {
                  display: flex;
                  padding: 0;
                  & .form-section-body {
                    margin: auto;
                  }
                }
                &.section-form-invoice-detail {
                  padding: 0;
                }
                &.section-form-key-permission {
                  padding: 0;
                  @include breakpoint('md'){
                    padding: 0 1em;
                  }
                }
                &.section-form-list {
                    padding: 0;
                    @include breakpoint('md'){
                        padding: 1em;
                    }
                }
                &.section-radio-group-list {
                    padding: 0;
                }
                &.section-container-rateplan {
                  .form-section-body {
                    padding-top: 1em;
                    .form-block {
                      &.block-rateplan {
                        border: 1px solid #E6E9EF;
                        border-radius: 6px;
                        box-shadow: 1px 1px 9px rgba(216, 216, 216, 0.4);
                        .block-body {
                          padding: 1em 2em;
                        }
                        .block-head {
                          align-items: center;
                          background-color: #F5F7FB;
                          display: flex;
                          flex-direction: row;
                          justify-content: space-between;
                          min-height: 50px;
                          padding: 0 1em;
                          width: 100%;
                        }
                      }
                    }
                    &:first-child {
                      padding-top: 0;
                    }
                  }
                }
            }
            &.borderless {
                border-bottom: 0 none;
            }
            &.form-header {
              padding: 0 0 2em;
            }
            &.form-accounting-providers, &.form-currencies-list, &.form-dunning, &.form-gateway-list, &.form-notifications-section, &.form-sales-channels, &.form-tax-partners, &.form-shipping-classes, &.form-shipping-carriers, &.form-smart-routing, &.form-users {
              @include breakpoint('sm'){
                flex-direction: column;
              }
              .form-section-side {
                align-items: center;
                display: flex;
                justify-content: space-between;
                max-width: 100%;
                margin: 1em;
                @include breakpoint('sm'){
                  margin: 0;
                  margin-bottom: 1em;
                }
                .label-title {
                  margin: 0;
                }
              }
            }
            &.form-rateplan-details {
              background-color: $grey-50;
              .form-section-container {
                .form-section-body {
                  .form-row {
                    margin: 0 auto auto;
                    min-height: 0;
                  }
                }
                .label-title {
                  color: $black;
                }
              }
              .form-section-side {
                .label-title {
                  margin: 0 auto auto;
                }
              }
            }
            &.form-section-gateway-list {
              display: flex;
              flex-direction: column;
            }
            &.form-section-notifications {
              .form-section-container {
                padding: 0;
                @include breakpoint('md'){
                  padding: 1em 0;
                }
              }
            }
            &.form-section-summary {
              .form-section-container {
                padding: 0;
                .form-section-body {
                  border: 1px solid $grey-200;
                  padding: 0 1em;
                  @include breakpoint('sm'){
                    background-color: $grey-50;
                  }
                }
              }
            }
            &.section-form-payment {
              .form-section-container {
                padding: 0;
              }
              .page-section-nav {
                align-items: center;
                display: flex;
                justify-content: center;
                margin: .5em auto;
                @include breakpoint('sm'){
                  display: inherit;
                  margin: 0;
                  margin-bottom: 1em;
                }
              }
            }
          }
          &.grid-container {
              padding: 0;
          }
          &.form-content-subscription {
            display: flex;
            flex-direction: row;
            .form-content-inner {
              flex: 2;
              padding: 0 2em 0 0;
              &.inner-summary {
                background-color: $grey-50;
                max-width: 30%;
                padding: 0;
              }
            }
          }
          &.form-content-plan {
            .form-section {
              @include breakpoint('sm'){
                padding: 20px 20px 4px;
              }
              @include breakpoint('lg'){
                padding: 0 0 24px;
              }
            }
          }
        }
        .form-wrapper {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            @include breakpoint('md'){
                padding: 40px;
            }
            &.form-import {
                & .form-pre {
                    max-width: 520px;
                }
                &.step-dropzone {
                    & .form-section-body {
                        margin: 0 1em;
                        @include breakpoint('md'){
                            margin: auto;
                        }
                    }
                }
                &.step-setup {
                    & .form-section-body {
                        margin: auto;
                    }
                }
            }
            &.form-subscription, &.form-gateway-list {
              & .form-content {
                & .form-section {
                  flex-direction: column;
                  @include breakpoint('md'){
                    padding: 1em 0;
                  }
                  @include breakpoint('xl'){
                    flex-direction: row;
                  }
                  & .form-section-body {
                    & .form-row {
                      &:first-child {
                        margin-top: 0;
                      }
                    }
                  }
                }
              }
            }
            &.form-gateway-list {
              & .form-content {
                & .form-section {
                  @include breakpoint('xl'){
                    flex-direction: column;
                  }
                }
              }
            }
        }
    }
    & .head {
        background-color: $white;
        border-bottom: 1px solid $grey-200;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        padding: 0;
        transition: width .3s ease-in-out;
        @include breakpoint('sm') {
            flex-direction: row;
        }
        @include breakpoint('lg') {
            min-height: 64px;
            padding: 0 32px;
            // position: fixed;
            // width: calc(100% - 260px);
            // z-index: 1;
        }
        & .head-container {
            align-items: center;
            display: flex;
            flex: 0 0 100%;
            min-height: 62px;
            padding: .5em;
            @include breakpoint('md'){
              padding: .5em 1em .5em .5em;
            }
            @include breakpoint('lg'){
                padding: 0;
            }
            & .head-actions {
                display: flex;
                flex-direction: row;
                margin: auto;
                margin-right: 0;
            }
            & .head-filter {
                align-items: center;
                background-color: transparent;
                display: flex;
                justify-content: center;
                margin: auto;
                margin-left: 0;
                padding: 0;
            }
            & .head-navigation-description {
              display: none;
              @include breakpoint('md'){
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
              }
              &.head-navigation-customer {
                & .customer-info {
                  display: flex;
                  flex-direction: column;
                }
              }
            }
            & .head-title {
                align-items: center;
                display: flex;
                left: 0;
                min-height: 64px;
                justify-content: center;
                padding: 0;
                position: absolute;
                pointer-events: none;
                width: 100%;
                & .inner {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        &.customer-details, &.product-details, &.store-setup {
            flex-direction: column;
            min-height: 64px;
            overflow: hidden;
            padding: 0;
            & .top {
                border-bottom: 1px solid $grey-200;
                display: flex;
                flex-direction: row;
                min-height: 64px;
                padding: 0;
                @include breakpoint('lg'){
                    position: relative;
                }
                & .account-info, & .product-info {
                    align-items: center;
                    display: flex;
                    min-height: 64px;
                    justify-content: center;
                    padding: 0 16px;
                    position: absolute;
                    pointer-events: none;
                    width: 100%;
                    @include breakpoint('lg'){ padding: 0;}
                    & .inner {
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        & .label-account-name {
                          &.label-status {
                            margin-top: 0px;
                            position: relative;
                            &:before {
                              border-radius: 50%;
                              display: inline-block;
                              content: ' ';
                              height: 6px;
                              left: -15px;
                              position: absolute;
                              top: 10px;
                              width: 6px;
                            }
                            &.active {
                              &:before {
                                background-color: $green-A400;
                              }
                            }
                            &.disabled {
                              &:before {
                                background-color: $grey-600;
                              }
                            }
                            &.archived {
                              &:before {
                                background-color: $red-A400;
                              }
                            }
                          }
                        }
                        & .label-account-email {
                            color: $grey-700;
                        }
                        & .label-status {
                          margin-top: .5em;
                          position: relative;
                          &:before {
                            border-radius: 50%;
                            display: inline-block;
                            content: ' ';
                            height: 6px;
                            left: -1em;
                            position: absolute;
                            top: 6px;
                            width: 6px;
                          }
                          &.active {
                            &:before {
                              background-color: $green-A400;
                            }
                          }
                          &.archive {
                            &:before {
                              background-color: $red-A400;
                            }
                          }
                          &.pending {
                            &:before {
                              background-color: $amber-A400;
                            }
                          }
                        }
                    }
                }
                & .head-actions {
                    margin: auto;
                    margin-right: 1em;
                }
                & .head-navigation {
                    margin: auto;
                    margin-left: .5em;
                    margin-right: 0;
                }
            }
        }
        &.store-setup {
          & nav {
            &.head-actions {
              @include breakpoint('sm'){ display: none; }
            }
            &.menutabs {
              display: none;
              @include breakpoint('sm'){ display: flex; }
            }
          }
        }
    }
    & .footer {
        & .copyright {
            color: $grey-700;
            font-size: .6875em;
            padding: 20px;
        }
    }
    & .page-aside {
        display: none;
        @include breakpoint('md') {
            display: flex;
            flex: 2;
            flex-direction: column;
            margin: auto;
            margin-left: 20px;
            margin-right: 0;
            margin-top: 0;
            padding: 0;
            max-width: 320px;
        }
        @include breakpoint('lg') {
            margin-left: 40px;
            margin-right: 0;
        }
        &.page-navigation {
            @include breakpoint('lg') { max-width: 300px; padding: 60px 0 0 65px; width: 30%; }
            & h6 {
                margin-bottom: 30px;
            }
        }
    }
    & .page-main {
        flex: 1;
        @include breakpoint('md') { flex: 2; }
    }
    & .page-section {
        padding: 1em 0 0 0;
        @include breakpoint('md'){
            padding: 1em 0;
        }
        & .page-section-body {
            background-color: $white;
            border: 1px solid $grey-300;
            &.page-section-rateplans, &.page-section-customers-subscription {
              border: 0 none;
            }
            &.section-notification-events {
              border: 0 none;
              .form-wrapper {
                padding-top: 0;
                .form-section {
                  padding: 0;
                  .form-section-body {
                    border-bottom: 1px solid $grey-200;
                    margin-bottom: 3em;
                    padding-bottom: 3em;
                  }
                }
              }
            }
        }
        & .page-section-head {
          align-items: center;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          min-height: 46.5px;
          padding: 0 1em;
          @include breakpoint('md'){
              min-height: 72px;
              padding: 0;
          }
          & .head-actions {
              display: flex;
              margin: auto;
              margin-right: 0;
              &.nav-options {
                margin: auto;
                @include breakpoint('md'){
                  margin-left: 0;
                }
              }
          }
          & .label-title {
              margin: auto;
              margin-left: 0;
          }
          &.section-head-rateplans {
            & .head-actions {
              &.nav-links {
                display: none;
                @include breakpoint('md'){
                  display: flex;
                }
              }
            }
          }
        }
        & .page-section-nav {
          align-items: center;
          display: flex;
          margin-bottom: 1em;
          min-height: 60px;
          & .custom-mat-button-toggle {
            border: 1px solid $blue-A400;
            color: $blue-A400;
            transition: all .3s ease-in-out;
            & .mat-button-toggle-label-content {
              padding: 0 2em;
            }
            &.mat-button-toggle-checked {
              background-color: $blue-A400;
              color: $white;
            }
          }
        }
        &.section-customer-transactions {
            margin-bottom: 40px;
        }
        &.section-dashboard-payment-activity {
          & .list-summary-numbers {
            margin-bottom: 1em;
          }
        }
        &.section-dashboard-notifications, &.section-dashboard-sales-channels, &.section-dashboard-payment-activity {
          & .page-section-body {
            background-color: transparent;
            border: 0 none;
          }
        }
        &.summary {
            align-items: center;
            display: flex;
            justify-content: center;
            margin: auto;
            margin-left: 0;
            margin-right: 0;
            margin-top: 20px;
            @include breakpoint('sm'){
                padding: 16px 2em;
            }
        }
        &.summary-charts {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            & .charts-filter {
                font-size: .75em;
                margin: auto;
                padding: 20px 16px;
                width: 100%;
                @include breakpoint('sm') { font-size: .875em; padding: 20px 5px; width: 80%; }
                @include breakpoint('md') { font-size: .875em; width: auto; }
            }
            & .charts-row {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                width: 100%;
                @include breakpoint('sm') {
                  flex-direction: row;
                  padding: 0 5px;
                }
                & .chart-wrapper {
                  flex: 1;
                  min-height: 320px;
                  @include breakpoint('sm') {
                    flex: 2;
                    min-height: 425px;
                    padding: 1em;
                  }
                  & .chart-inner {
                    align-items: center;
                    background-color: $white;
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    height: 100%;
                    justify-content: center;
                    min-height: 320px;
                    @include breakpoint('md'){
                      min-height: 425px;
                    }
                    & .chart-content {
                      width: 100%;
                    }
                    & .chart-canvas {
                      align-items: center;
                      display: flex;
                      justify-content: center;
                      padding: 1em;
                      position: relative;
                      width: 100%;
                      & .chart-canvas-container {
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                        height: 100%;
                        min-height: 240px;
                        width: 100%;
                      }
                    }
                    & .chart-head {
                        display: flex;
                        flex-direction: row;
                        padding: 0 1em;
                        & .aside-left {
                            margin: auto;
                            margin-left: 0;
                            text-align: left;
                        }
                        & .aside-right {
                            margin: auto;
                            margin-right: 0;
                            text-align: right;
                        }
                    }
                    & .chart-prehead {
                        display: flex;
                        justify-content: space-between;
                        padding: 1em;
                        & .chart-title {
                            margin: auto;
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }
                    & .page-loading {
                        align-items: center;
                        display: flex;
                        height: 100%;
                        justify-content: center;
                        margin: auto;
                        min-height: 320px;
                        @include breakpoint('md'){
                            min-height: 350px;
                        }
                    }
                  }
                }
            }
        }
    }
    & .page-wrapper {
      min-height: 100vh;
    }
    &:before {
        content: " ";
        display: table;
        position: relative;
    }
    &:after {
        content: " ";
        clear: both;
        display: table;
        position: relative;
    }
    &.sidenav--is--float {
      @include breakpoint('lg'){
        margin-left: $sidenav-width-compressed;
      }
      & .body-loader {
          left: 60px;
          width: calc(100% - 60px);
      }
    }
  }
  &.payment-form {
      & .card-payment-form {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          & .hosted-field-wrapper {
              display: flex;
              flex-direction: column;
              height: 90px;
              padding-bottom: 1em;
              position: relative;
              & .label-placeholder {
                  font-weight: 400;
                  font-size: .656em;
                  font-family: "Roboto Condensed";
                  letter-spacing: .33px;
                  margin: 0;
              }
              & .hosted-field {
                  border-bottom: 1px solid #e0e0e0;
                  height: 50px;
                  min-height: 50px;
                  &.StripeElement {
                      align-items: center;
                      background-color: transparent;
                      border-radius: 0;
                      box-shadow: none;
                      color: #32325d;
                      display: flex;
                      height: 50px;
                      justify-content: center;
                      position: relative;
                      padding: 0;
                      width: 100%;
                      & .__PrivateStripeElement {
                          width: 100%;
                      }
                  }
              }
              &.field--card-holdername {
                  flex: 1 100%;
                  height: auto;
                  padding-bottom: 0;
              }
              &.field--card-number {
                  flex: 1 100%;
                  @include breakpoint('md'){
                      flex: 4 40%;
                  }
              }
              &.field--card-exp {
                  flex: 4 20%;
              }
              &.field--card-cvv {
                  flex: 4 20%;
              }
              &.field--card-zip{
                  flex: 4 20%;
              }
          }
          &[hidden]{
            display: none;
          }
      }
      &.payment-source-info {
          & .card-billing-address {
              display: flex;
              flex-direction: column;
              margin: 1em 0;
          }
          & .card-default {
              display: flex;
              flex-direction: row;
              margin-top: 16px;
          }
          & .card-account-name {
              margin-top: 1em;
          }
          & .card-last4 {
              display: inline-flex;
              @include breakpoint('md'){
                  align-items: center;
                  height: 40px;
              }
          }
          & .ico-card {
              margin: auto;
              margin-left: 0;
              margin-right: 0;
          }
          & .ico-default {
              fill: $green-A400;
              margin: auto;
              margin-left: 0;
              margin-right: 5px;
          }
          & .ico-mask {
              height: 6px;
              margin: auto;
              margin-left: 10px;
              margin-right: 10px;
              width: 100px;
          }
          & .label-account-name {
              font-size: 1em;
          }
          & .label-last4 {
              margin: auto;
              margin-left: 8px;
          }
          & .label-placeholder {
              align-items: center;
              display: flex;
              flex-direction: row;
              min-height: 40px;
          }
      }
  }
  &.prehead {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 74px;
      padding: 0 .5em;
      position: relative;
      @include breakpoint('lg'){ display: none; }
      & .button-search {
          margin: auto;
          margin-right: 0;
      }
      & .logo {
          background-position: 50% 50%;
          background-repeat: no-repeat;
          background-size: 80px auto;
          color: transparent;
          display: flex;
          font-size: 1px;
          height: 74px;
          left: 0;
          margin: auto;
          margin-left: 0;
          pointer-events: none;
          position: absolute;
          top: 0;
          width: 100%;
          z-index: 1;
          &.black {
            background-image: url('../svg/logo-rebillia.svg');
          }
      }
  }
  &.plans {
      & .plans-features-description-group {
          display: none;
          @include breakpoint('sm') { display: block; }
          & .features-group {
              & .group-name {
                  border-bottom: 1px dotted $grey-300;
                  color: $grey-500;
                  line-height: 30px;
                  margin-bottom: 10px;
                  margin-top: 40px;
              }
          }
      }
  }
  &.sidenav {
    background-color: $blue-grey-50;
    border-right: solid 1px $blue-B50;
    box-shadow: 1px 2px 15px 0 rgba(0,0,0,.05);
    display: none;
    flex-direction: column;
    font-family: $font-regular;
    font-size: 16px;
    height: 100vh;
    left: 0;
    margin: 0;
    overflow: hidden;
    position: fixed;
    transition: width .3s ease-in-out;
    top: 0;
    width: $sidenav-width;
    @include breakpoint('lg'){
      display: flex;
      flex-direction: column;
      z-index: 2;
    }
    .body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      list-style: none;
      margin: 0;
      margin-bottom: auto;
      .item-container {
        color: $blue-B700;
        display: flex;
        font-family: $font-condensed;
        font-weight: 400;
        min-height: 50px;
        overflow: hidden;
        padding: 0;
        padding-left: 17.5px;
        text-decoration: none;
        .item {
          align-items: center;
          display: flex;
          overflow: hidden;
          .icon-container {
            align-items: center;
            display: flex;
            flex-shrink: 0;
            height: 48px;
            justify-content: center;
            position: relative;
            width: 48px;
          }
          .item-txt {
            flex-shrink: 0;
            margin-left: .5em;
          }
        }
        &:hover {
          color: $blue-B400;
          .item {
            .icon-container {
              svg {
                path {
                  stroke: $blue-B400;
                }
              }
            }
          }
        }
        &.router-link-active, &.option-active {
          color: $blue-A400;
          .item {
            .icon-container {
              background-color: $blue-A900;
              .custom-mat-icon {
                svg {
                  path {
                    stroke: $white;
                  }
                }
              }
            }
            .mat-icon {
              &.custom-mat-icon {
                &.ico-sidenav {
                  transform: scale(1.1) translate(0%, 0%);
                  .ico-path {
                    stroke: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
    .footer {
      font-size: .8125em;
      font-weight: bold;
      margin: 0;
      .container{
        align-items: center;
        display: flex;
        justify-content: flex-start;
        margin: 0;
        padding: 1em 0;
      }
      .avatar {
        display: flex;
        margin: 0;
        .avatar-background {
          align-items: center;
          background-color: $blue-B700;
          border-radius: 100%;
          display: flex;
          height: 48px;
          justify-content: center;
          width: 48px;
          .label {
            color: $white;
            display: flex;
            font-size: 20px;
            padding: 3px 0;
            span {
              flex: 2;
            }
          }
        }
      }
      .user-info {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        flex: 2;
        justify-content: center;
        min-width: 145px;
        opacity: 1;
        padding: 0 0 0 .5em;
        transition: opacity .3s ease-in-out;
        .label-user-name {
          margin-bottom: .25em;
          text-align: left;
        }
      }
    }
    .head {
      display: flex;
      flex-direction: column;
      margin: 0;
      min-height: 96px;
      padding: 0;
      .container {
        display: flex;
        margin: 0;
        margin-bottom: auto;
        margin-top: auto;
        padding: 0 0 0 17.5px;
        position: relative;
      }
    }
    .panel {
      &.popup-panel {
        &.panel-account {
          left: 210px;
        }
      }
    }
    .prehead {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 0;
      min-height: 64px;
      padding: 0;
      position: relative;
      .ico-goto-website {
        background-color: $grey-100;
        border-radius: 50%;
        height: 40px;
        min-width: 40px;
        transition: opacity .3s ease-in-out;
        width: 40px;
        svg {
          height: 12px;
          width: 12px;
        }
      }
      .ico-logo-top {
        height: 38px;
        left: 1.25em;
        min-width: 75px;
        padding: 0;
        position: absolute;
        width: 95px;
        svg {
          g, path, polygon, rect {
            transition: all .3s ease-in-out;
          }
        }
        &.is--normal {
          svg {
            path {
              &.path--rebillia {
                &.reb-iso {
                  &.path--R {
                    fill: $grey-900;
                    transform: scale(1);
                  }
                }
              }
            }
            rect {
              &.reb-iso {
                &.rect-bg {
                  fill: $white;
                  height: 0px;
                  opacity: 0;
                  transform: scale(1);
                  width: 0px;
                }
              }
            }
          }
        }
        &.is--shrink {
          svg {
            g {
              &.path--rebillia {
                opacity: 0;
              }
            }
            path {
              &.path--platform {
                opacity: 0;
              }
              &.path--rebillia {
                opacity: 0;
                &.reb-iso {
                  &.path--R {
                    fill: $blue-50;
                    opacity: 1;
                    transform: scale(1.75);
                  }
                }
              }
            }
            polygon {
              &.path--rebillia {
                opacity: 0;
              }
            }
            rect {
              &.reb-iso {
                &.rect-bg {
                  fill: $grey-900;
                  height: 48px;
                  opacity: 1;
                  width: 48px;
                }
              }
            }
          }
        }
      }
    }
    &.is--compressed{
      width: $sidenav-width-compressed;
      .footer {
        .button-account {
          margin: auto;
        }
        .user-info {
          opacity: 0;
        }
      }
      .panel {
        &.popup-panel {
          &.panel-account {
            left: 80px;
          }
        }
      }
    }
  }
  &.sidenav-handler {
      bottom: 0;
      cursor: pointer;
      display: none;
      left: $sidenav-width;
      top: 0;
      padding: 0 16px 0 12px;
      position: fixed;
      transition: left .3s ease-in-out;
      z-index: 1;
      @include breakpoint('lg'){
          display: flex;
      }
      &:after, &:before {
          -webkit-transform: translateY(-50%) rotate(90deg);
          -webkit-transition: .2s;
          background-color: #bfbfbf;
          border-radius: 2px;
          content: "";
          bottom: 12px;
          height: 3px;
          left: 0;
          margin: 0 auto;
          position: absolute;
          right: 0;
          top: 50%;
          transition: .2s;
          transform: translateY(-50%) rotate(90deg);
          width: 20px;
      }
      &:after {
          bottom: calc(50% - 17px);
      }
      &:before {
          top: calc(50% - 17px);
      }
      &:hover:after, &:hover:before {
          background-color:#2c0069
      }
      &:hover:before {
          -webkit-transform:rotate(114deg);
          transform:rotate(114deg)
      }
      &:hover:after {
          -webkit-transform:rotate(66deg);
          transform:rotate(66deg)
      }
      &.sidenav--is--float {
          left: $sidenav-width-compressed;
          &:hover:before {
              -webkit-transform:rotate(66deg);
              transform:rotate(66deg)
          }
          &:hover:after {
              -webkit-transform:rotate(114deg);
              transform:rotate(114deg)
          }
      }
  }
  &.spanel {
      position: fixed;
      right: 0;
      top: 0;
      z-index: 1000;
      &.spanel-account {
          display: flex;
          height: 100vh;
          margin: auto;
          margin-right: 0;
          margin-top: 0;
          width: 100%;
          @include breakpoint('md') { max-width: 480px; }
          & .spanel-container {
              background-color: $grey-900;
              overflow-x: hidden;
              overflow-y: auto;
              width: 100%;
              & .head {
                  & nav {
                      display: flex;
                      height: 64px;
                      & .custom-mat-icon-button {
                          margin: auto;
                          &.btn-close {
                              background-color: $black;
                              margin-right: 12px;
                              @include breakpoint('lg') { margin-right: 20px; }
                          }
                          &.btn-edit {
                              background-color: $blue-A900;
                              margin-left: 12px;
                              @include breakpoint('lg') { margin-left: 20px; }
                          }
                      }
                  }
                  & .card-spanel-account {
                      & .fullname {
                          line-height: 50px;
                      }
                  }
              }
          }
          &.static {
              & .spanel-container {
                  padding-top: 64px;
              }
          }
      }
  }
  &.test-banner {
    align-items: center;
    display: flex;
    justify-content: center;
    .inner {
      align-items: center;
      border-top: 1px solid #f5925e;
      display: flex;
      justify-content: center;
      width: 100%;
      span {
        background-color: #f5925e;
        color: $white;
        font-size: 11px;
        padding: .25em .5em;
      }
    }
  }
  &.verify {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: auto;
    margin-top: 0;
    padding: 0;
    @include breakpoint('md'){ margin: auto; }
    .body {
      margin: auto;
      margin-left: 0;
      margin-top: 0;
      max-width: 420px;
      padding: 0 32px;
      text-align: center;
      width: 100%;
      @include breakpoint('sm') {
        margin: auto;
        margin-top: 32px;
        min-height: 320px;
      }
      .nav-links {
        display: flex;
        justify-content: center;
        margin: 32px auto;
        .custom-mat-link {
          &.link-back {
            left: 0;
            position: relative;
          }
        }
      }
    }
    .footer {
        margin: auto;
        margin-bottom: 25px;
        max-width: 420px;
        padding: 0;
        text-align: center;
        width: 100%;
        @include breakpoint('sm') {
          margin-top: 60px;
          margin-bottom: 50px;
        }
        .label-copyright {
          color: $grey-600;
          letter-spacing: .45px;
          max-width: 375px;
        }
    }
    .head {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin: auto;
        margin-bottom: 32px;
        margin-top: 0;
        @include breakpoint('md') {
          margin: auto;
          margin-bottom: 0;
          margin-top: 0;
          text-align: center;
        }
        .custom-mat-icon {
          margin: 60px auto 16px;
        }
        .label-title {
          margin: 15px 0 5px;
          @include breakpoint('sm') {
            margin: auto;
            margin-bottom: 0;
            margin-top: 0;
          }
        }
    }
    .pre-head {
      align-items: center;
      display: flex;
      flex-direction: row;
      height: 74px;
      justify-content: center;
      margin: auto;
      margin-bottom: 0;
      margin-top: 0;
      @include breakpoint('sm'){
        height: auto;
        margin: auto;
        margin-bottom: 32px;
      }
    }
  }
}

span {
  &.avatar {
    display: flex;
    height: 40px;
    margin: 0;
    overflow: hidden;
    position: relative;
    width: 40px;
    figure {
        background-color: $white;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 50%;
        border: 1px solid $white;
        display: block;
        margin: auto;
        height: 100%;
        width: 100%;
    }
    &.avatar-panel {
      border: 1px solid $grey-300;
      border-radius: 50%;
      padding: .25em;
    }
    &.size-64 {
        height: 64px;
        width: 64px;
    }
    &.size-80 {
        height: 80px;
        width: 80px;
    }
  }
  &.badge {
      background-color: $white;
      border: 1px solid $blue-A400;
      border-radius: 24px;
      color: $blue-A400;
      display: inline-flex;
      font-family: $font-condensed;
      font-size: $note-size;
      font-style: normal;
      font-weight: 600;
      line-height: 1em;
      padding: 5px 10px;
      text-transform: uppercase;
      &.owner {
          background-color: $blue-A400;
          color: $white;
      }
  }
  &.badge--user-role {
    background-color: $blue-B50;
    border: none;
    border-radius: 5px;
    color: $blue-A700;
    display: inline;
    font-size: .75em;
    margin: auto 0 0;
    padding: 4px 8px;
    text-decoration: none;
  }
  &.customer-payment-method {
    align-items: center;
    display: flex;
    width: 100%;
    & .ico-card {
      margin: auto 0;
    }
    & .label-card-exp {
      margin: auto;
      margin-right: 0;
    }
    & .label-card-number {
      align-items: center;
      display: flex;
      margin: auto;
      margin-left: 0;
      &:before {
        color: #8c8c91;
        content:"····";
        font-size: 2em;
        margin: 0 .25em 0 .5em;
      }
    }
    & .label-card-number-new {
      align-items: center;
      display: flex;
      margin: auto;
      margin: 0 .25em 0 .5em;
      &:before {
        color: #8c8c91;
        font-size: 2em;
        margin: 0 .25em 0 .5em;
      }
    }
    & .label-card-holder {
      align-items: center;
      display: flex;
      margin-top: auto;
      margin-right: 1.25em;
      margin-bottom: auto;
      margin-left: 0;
    }
    & .label-card-gateway {
      align-items: center;
      display: flex;
      margin-top: auto;
      margin-right: 1.25em;
      margin-bottom: auto;
      margin-left: 0;
    }
  }
  &.credit-card-item {
    align-items: center;
    display: flex;
    width: 100%;
    & .ico-card {
      margin: auto 0;
      height: 32px;
      width: 32px;
    }
    & .label-credit-card {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin: auto;
      margin: 0 .25em 0 .5em;
      &:before {
        color: #8c8c91;
        font-size: 2em;
        margin: 0 .25em 0 .5em;
      }
      & .label-primary {
        font-size: .9375em;
      }
      & .label-secondary {
        font-size: .875em;
        font-weight: 300;
    }
    }
    & .label-card-exp {
      margin: auto;
      margin-right: 0;
      font-size: .9375em;
    }
  }
  &.dot{
      content: " ";
      display: flex;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $grey-500;
      margin: auto 4px auto 0px;
      &.active{
          background: $green-A400;
      }
      &.default{
          background: $blue-A400;
      }
  }
  &.notification-ballon {
    background: $red-A400;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0,0,0,0.5);
    color: #fff;
    font-family: $font-condensed;
    font-size: 12px;
    height: 18px;
    line-height: 18px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    width: 18px;
  }
  &.status {
    &.status-authorized {
      border-color: $blue-grey-300;
      color: $blue-grey-400;
    }
    &.status-error {
      color: $red-A400;
    }
    &.status-refund {
      background-color: $amber-600;
      color: $amber-900
    }
    &.status-settled {
      background-color: $green-50;
      color: $green-900;
    }
    &.status-void {
      background-color: $amber-200;
      color: $amber-900;
    }
    &.status-declined {
      background-color: #967e68;
      color: #47280b;
    }
  }
  &.strong{
      font-weight: 600;
  }
}

ul {
    & li {
        &.column {
            float: left;
        }
        &.row-copyright{
            margin: 10px 0 30px;
            text-align: center;
        }
        &.row-divider {
            clear: both;
            margin: 25px 0 5px;
            overflow: hidden;
            position: relative;
            text-align: center;
            &:before {
                background-color: $grey-300;
                display: inline-block;
                content: '';
                height: 1px;
                margin-left: -50%;
                position: relative;
                right: 0.5em;
                vertical-align: middle;
                width: 50%;
            }
            &:after {
                background-color: $grey-300;
                content: '';
                display: inline-block;
                height: 1px;
                left: 0.5em;
                margin-right: -50%;
                position: relative;
                vertical-align: middle;
                width: 50%;
            }
        }
        &.row-form {
            margin-bottom: 25px;
            &:before {
                content: " ";
                display: table;
                position: relative;
            }
            &:after {
                content: " ";
                clear: both;
                display: table;
                position: relative;
            }
            &.row__below__message {
                margin-top: 20px;
                margin-bottom: 0;
            }
            &.row-checkbox {
              align-items: center;
              display: inline-flex;
              flex-direction: column;
              justify-content: center;
              width: 100%;
              @include breakpoint('sm'){
                padding-top: 0;
              }
              & .label-checkbox-description {
                margin-top: 5px;
                padding-left: 32px;
              }
            }
            &.row-message {
                margin-bottom: 0;
            }
            &.row-nav {
                margin-top: 25px;
            }
        }
    }
    &.grid-plans {
        & .column {
            & .inner {
                padding: 40px 10px;
                @include breakpoint('sm') { padding: 40px 0; }
            }
        }
        & .aside {
            @include breakpoint('sm'){
                padding-left: 50px;
            }
        }
        &.grid-plans-features {
            & .column-feature-desc {
                display: none;
                @include breakpoint('md') { display: block; padding-right: 30px; }
            }
            & .column-plan {
                padding: 0 30px;
                text-align: center;
                & .plan-actions {
                    margin: 30px 0;
                }
                & .plan-price {
                    margin: 20px 0;
                    position: relative;
                    & .price {
                        color: #191919;
                        font-weight: normal;
                        font-size: 40px;
                        line-height: 28px;
                        display: inline-block;
                        position: relative;
                        white-space: nowrap;
                        &:before {
                            content: '$';
                            left: -12px;
                        }
                        &:after {
                            content: '/mo';
                            right: -33px;
                        }
                        &:before, &:after {
                            position: absolute;
                            color: rgba(0,0,0,0.54);
                            font-size: 18px;
                            line-height: 24px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
            &.plan-feature {
                display: block;
                padding: 10px 20px 20px;
                transition: all .3s ease-in-out;
                @include breakpoint('md') { display: flex; padding: 10px 20px; }
                &:hover {
                    background-color: $grey-200;
                }
                & .column-feature-desc {
                    display: block;
                    margin-bottom: 5px;
                    text-align: center;
                    @include breakpoint('md'){ margin-bottom: 0; text-align: left; }
                }
                & .column-plan {
                    display: flex;
                }
            }
        }
    }
}

pre {
    &.json-code-block {
        font-size: 13px;
        background-color: #f6f6f6;
        border-radius: 5px;
        padding: 5px;
    }
}

.app-loader {
    display: flex;
    position: relative;
}

.app-page-loader {
    height: 100vh;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -1;
    & .loading {
        justify-content: center;
        & .loading-wrapper {
            z-index: 1;
        }
        & .overlay-background {
            background-color: $blue-grey-900;
            height: 100vh;
            left: 0;
            opacity: .45;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;
        }
    }
}

.cdk-drag-animating {
    transition: transform .33s cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
    background-color: rgba(0, 0, 0, .2);
    height: 48px;
    opacity: 0.5;
}

.cdk-drag-preview {
    align-items: center;
    background-color: $white;
    border-radius: 0;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 8px;
}

.cdk-drop-dragging .cdk-drag {
    transition: transform .5s cubic-bezier(0, 0, 0.2, 1);
}

.cdk-overlay-container {
    &.disable-backdrop-click .cdk-overlay-backdrop.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
        pointer-events: none;
    }
    // temporary change
    z-index: 99999;
}

.cdk-overlay-pane {
  .mat-select-panel-wrap {
    & .mat-option {
      &.custom-mat-option {
        & .mat-option-text {
          align-items: flex-start;
          display: flex;
        }
        &.flex-center {
          & .mat-option-text {
            align-items: center;
          }
        }
      }
    }
  }
  .mat-snack-bar-container {
    &.custom-mat-dialog {
      max-width: 75vw;
      div:first-child {
        width: 100%;
      }
      .dialog-message-timer {
        .dialog-wrapper {
          display: flex;
          flex-direction: row;
          div:first-child {
            width: auto;
          }
          .body {
            margin: auto;
            margin-left: 10px;
          }
          .head {
            .custom-mat-icon {
                fill: $green-A400;
            }
          }
        }
      }
      &.bad-request {
          background-color: $red-100;
          color: $grey-900;
          & .dialog-message-timer {
              & .dialog-wrapper {
                  align-items: center;
                  justify-content: center;
                  & .head {
                      & .custom-mat-icon {
                          fill: $grey-900;
                      }
                  }
              }
          }
      }
      &.success_with_error {
        background-color: $orange-200;
        color: $grey-900;
        & .dialog-message-timer {
            & .dialog-wrapper {
                align-items: center;
                justify-content: center;
                & .head {
                    & .custom-mat-icon {
                        fill: $grey-900;
                    }
                }
            }
        }
    }
      &.mat-dialog-bulk-actions {
          $minHeight: 72px;
          align-items: center;
          background-color: $white;
          color: $grey-900;
          display: flex;
          padding: 0;
          margin-bottom: 72px;
          max-width: 100vw;
          min-height: $minHeight;
          min-width: 75vw;
          @include breakpoint('lg'){
              min-width: 65vw;
          }
          @include breakpoint('xl'){
              min-width: 45vw;
          }
          & .dialog-bulk {
              flex: 1;
              & .dialog-wrapper {
                  & .body {
                      align-items: center;
                      display: flex;
                      justify-content: space-between;
                      & .bulk-records-actions {
                          display: flex;
                          & .button-action {
                              font-size: .75em;
                              justify-content: center;
                              min-height: $minHeight;
                              min-width: 72px;
                              text-transform: uppercase;
                              & .mat-button-wrapper {
                                  align-items: center;
                                  display: flex;
                                  flex-direction: column;
                                  & .custom-mat-icon {
                                      margin: 1em;
                                  }
                              }
                              &.button-close {
                                  border: 0 none;
                                  border-left: 1px solid $grey-200;
                                  border-radius: 0;
                              }
                          }
                      }
                      & .bulk-records-info {
                          align-items: center;
                          display: flex;
                          & .bulk-records-inner {
                              align-items: center;
                              background-color: $blue-A400;
                              color: $white;
                              display: flex;
                              min-height: $minHeight;
                              justify-content: center;
                              width: 72px;
                              & .label-bulk-records {
                                  font-family: $font-condensed;
                                  font-size: 2em;
                                  font-weight: 100;
                              }
                          }
                          & .label-bulk-section {
                              font-size: 1.25em;
                              font-weight: 100;
                              margin-left: 1em;
                          }
                      }
                  }
              }
          }
      }
    }
  }
  &.custom-mat-dialog {
    max-width: 100vw;
    max-height: 100vh;
    width: 100%;
    @include breakpoint('sm'){
      max-width: 80vw;
      width: 100%;
    }
    @include breakpoint('md'){
      max-height: 90vh;
      min-width: 680px;
      width: auto;
    }
    .mat-dialog-container {
      height: 100vh;
      padding: 0;
      width: 100%;
      @include breakpoint('md'){
          height: 100%;
          width: 100%;
      }
      .dialog-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        position: relative;
        @include breakpoint('md'){
          min-height: 100%;
        }
        .body {
          .form-wrapper {
              align-items: center;
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              padding: 1em;
              .form-content {
                .form-section-head {
                  align-items: center;
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  padding: 1em 0 2em;
                  .icon {
                    margin: auto;
                    margin-left: 0;
                    margin-right: 1em;
                  }
                }
                .form-row {
                  display: flex;
                  min-height: 64.5px;
                  margin: auto 0;
                  .form-block {
                    &.block-plan-rules {
                      padding: .5em;
                      @include breakpoint('md'){
                        padding: 0;
                        padding-top: 1.25em;
                      }
                      & .block-head {
                        align-items: flex-start;
                        flex-direction: column;
                        justify-content: flex-start;
                        margin-bottom: .5em;
                        margin-left: 0;
                        margin-right: auto;
                        @include breakpoint('md'){
                          margin-bottom: 0;
                          margin-left: 0;
                          margin-right: auto;
                        }
                      }
                      & .form-row {
                        align-items: flex-start;
                        border-bottom: 1px solid $grey-300;
                        flex-direction: column;
                        @include breakpoint('md'){
                          align-items: center;
                          flex-direction: row;
                          justify-content: space-between;
                        }
                        &:first-child {
                          border-top: 1px solid $grey-300;
                        }
                        &.row-status {
                          padding: 1em 0;
                          @include breakpoint('md'){
                            padding: inherit;
                          }
                        }
                        &.row-panel, &.row-rules {
                          border: 1px solid $grey-200;
                          .row-inner {
                            padding-top: 1em;
                            padding-bottom: 1em;
                            padding-left: 2em;
                          }
                          & .custom-mat-form-field{
                            padding: 1em 1em 0;
                            @include breakpoint('md') {
                              padding: inherit;
                            }
                            &.custom-mat-form-field-outline {
                              &.expirationAction {
                                width: 310px;
                              }
                              &.loyaltyAction {
                                width: 150px;
                                padding-left: 0px;
                              }
                              &.prepaidAction {
                                width: 200px;
                                padding-left: 0px;
                              }
                              &.event {
                                width: 310px;
                              }
                              &.loyaltyEvent {
                                width: 200px;
                              }
                              &.expirationEvent {
                                width: 200px;
                              }
                            }
                            // &.expirationCount {
                            //   margin-left: 10px;
                            //   margin-right: 10px;
                            // }
                            // &.loyaltyRulesAmount {
                            //   width: 120px;
                            //   margin-left: 10px;
                            //   margin-right: 10px;
                            // }
                            // &.loyaltyRulesAmountType {
                            //   width: 190px;
                            //   margin-left: 10px;
                            //   margin-right: 10px;
                            // }
                            // &.loyaltyCount {
                            //   width: 145px;
                            //   margin-left: 5px;
                            //   margin-right: 5px;
                            // }
                            // &.prepaidRulesAmount{
                            //   width: 180px;
                            //   margin-left: 10px;
                            //   margin-right: 10px;
                            // }
                            // &.prepaidRulesAmountType {
                            //   width: 190px;
                            //   margin-left: 10px;
                            //   margin-right: 10px;
                            // }
                            // &.prepaidCount {
                            //   width: 145px;
                            //   margin-left: 5px;
                            //   margin-right: 5px;
                            // }
                          }
                        }
                      }
                    }
                  }
                  &.row-calendar-week-days {
                    & .form-row-inner {
                      align-items: center;
                      display: flex;
                      flex-direction: row;
                      justify-content: space-around;
                      margin: auto;
                      padding: 0;
                      width: 100%;
                    }
                    & .custom-mat-button-toggle-group {
                        flex: 1;
                        & .custom-mat-button-toggle {
                            flex: 7;
                            & .mat-button-toggle-button {
                                & .mat-button-toggle-label-content {
                                    & span {
                                        display: none;
                                        font-size: .875em;
                                        @include breakpoint('md'){
                                            display: inline-flex;
                                        }
                                        &.mobile--only {
                                            display: inline-flex;
                                            @include breakpoint('md'){
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                  }
                  &.row-checkbox {
                    display: inline-flex;
                    flex-direction: column;
                    justify-content: center;
                    min-height: 76px;
                    @include breakpoint('sm'){
                      padding-top: 0;
                    }
                    & .label-checkbox-description {
                        margin-top: 5px;
                        padding-left: 32px;
                    }
                  }
                  &.row-flex-wrap {
                    justify-content: space-between;
                    flex-wrap: wrap;
                    .md-size1of2 {
                      @include breakpoint('md'){
                        width: 49%;
                      }
                    }
                  }
                }
              }
              .form-ups-settings {
                align-items: center;
                display: flex;
                justify-content: center;
                width: 100%;
              }
          }
          .mat-tab-group {
              &.custom-mat-tab-group{
                  & .mat-tab-body {
                      & .mat-tab-body-content {
                          padding: 20px;
                      }
                      & .post-form {
                          display: flex;
                          justify-content: center;
                          margin: auto;
                          margin-top: 24px;
                      }
                  }
                  & .mat-tab-header {
                      background-color: $white;
                      & .mat-tab-label-container {
                          flex-grow: 1;
                          & .mat-tab-label {
                              flex: 2;
                              height: 60px;
                              &.mat-tab-label-active {
                                  background-color: $white;
                              }
                          }
                          & .custom-mat-icon {
                              margin: auto 10px;
                          }
                      }
                  }
              }
          }
        }
        .float-head {
          position: absolute;
          right: 1.5em;
          top: 1.5em;
        }
        .head {
            align-items: center;
            background-color: $white;
            border-bottom: 1px solid $grey-200;
            display: flex;
            flex-direction: row;
            justify-content: center;
            min-height: 74px;
            position: relative;
            .button-close-panel {
              position: absolute;
              right: 1em;
              top: 1em;
            }
            .label-title {
              text-align: center;
              max-width: 350px;
              pointer-events: none;
              position: absolute;
            }
        }
        .nav {
          align-items: center;
          display: flex;
          flex-direction: column;
          margin-bottom: 0;
          margin-top: auto;
          min-height: 74px;
          justify-content: center;
          padding: 0;
          @include breakpoint('md'){
            border-top: 1px solid $grey-200;
          }
          & .button-submit {
              min-width: 210px;
          }
          & .button-cancel {
              line-height: 50px;
              margin: 1em 0;
              min-width: 210px;
              padding-left: 40px;
              padding-right: 40px;
          }
          & .post-form {
            margin-bottom: 0;
          }
        }
      }
      .dialog-confirmation {
          display: flex;
          height: 100vh;
          @include breakpoint('md'){
              height: auto;
          }
          & .dialog-wrapper {
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              margin: auto;
              & .body {
                  align-items: center;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  padding: 0 30px;
                  text-align: center;
                  & .block-card {
                      align-content: center;
                      align-items: center;
                      display: inline-flex;
                      flex-direction: row;
                      justify-content: center;
                  }
                  & .label-last4 {
                      margin: auto;
                      margin-left: 8px;
                  }
                  & .label-message {
                      margin: 16px auto;
                      max-width: 520px;
                  }
                  & .label-title {
                      margin: auto;
                      max-width: 420px;
                  }
                  & .nav {
                      display: flex;
                      flex-direction: column;
                      padding: 2em 0;
                      & .button-cancel {
                          margin: 1em 0;
                      }
                  }
              }
              & .head {
                  align-items: center;
                  border-bottom: 0 none;
                  display: flex;
                  justify-content: center;
                  margin: auto;
                  padding: 30px;
                  @include breakpoint('md'){
                      padding: 40px;
                  }
                  & .custom-img {
                      align-items: center;
                      display: flex;
                      height: auto;
                      justify-content: center;
                      max-height: 250px;
                      max-width: 100%;
                      @include breakpoint('lg') {
                          max-height: 250px;
                      }
                  }
                  & .custom-mat-icon {
                      height: 120px;
                      width: 120px;
                  }
              }
              & .nav {
                border-top: 0 none;
                margin-top: 2em;
              }
          }
      }
    }
    &.mat-dialog-account {
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100%;
        @include breakpoint('md'){
            height: 480px;
            min-height: 480px;
            width: 400px;
        }
        & .mat-dialog-container {
            background-color: $blue-grey-50;
            flex: 1;
            & .dialog-wrapper {
                & .body {
                    & .form-wrapper {
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        flex-direction: column;
                        padding: 1em;
                        & .form-section {
                            width: 100%;
                        }
                    }
                }
            }
            & .mat-card.custom-mat-card.card-form-wrapper .card-section {
                border: 0 none;
            }
        }
    }
    &.mat-dialog-charge-invoice {
      & .mat-dialog-container {
        & .dialog-wrapper {
          & .nav {
            border-top: 1px solid $grey-200;
            margin-top: 2em;
            padding: 1em 0;
          }
        }
      }
    }
    &.mat-dialog-customer-group {
      & .mat-dialog-container {
        & .dialog-wrapper {
          & .nav {
            border-top: 1px solid $grey-200;
            margin-top: 2em;
            padding: 1em 0;
          }
        }
      }
    }
    &.mat-dialog-engage-gateway {
      min-width: 0px;
      & .dialog-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        @include breakpoint('sm'){
          max-width: 480px;
          min-height: 0;
        }
        & .nav {
          margin: auto;
          margin-bottom: 0;
          @include breakpoint('sm'){
            align-items: center;
            border-top: 1px solid $grey-200;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: auto;
            margin-left: 0;
            margin-right: 0;
            padding: 1em;
          }
          & .inner {
            align-items: center;
            display: flex;
            flex-direction: column;
            max-width: 420px;
            justify-content: center;
            @include breakpoint('sm'){
              align-items: center;
              display: flex;
              flex-direction: row;
              justify-content: center;
            }
            & .button-cancel {
              order: 2;
              @include breakpoint('sm'){
                order: 1;
                margin: 0;
              }
            }
            & .custom-mat-button-primary {
              margin: auto;
              order: 1;
              @include breakpoint('sm'){
                order: 2;
              }
            }
          }
        }
      }
    }
    &.mat-dialog-idle-user {
      max-width: 640px!important;
      min-width: 0;
      & .dialog-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        @include breakpoint('sm'){
          max-width: inherit;
          min-height: 0;
        }
        & .body {
          & .form-wrapper {
            padding: 3em;
            & .form-content {
              & .form-row {
                margin: 0;
                min-height: 0;
                &.row-hero {
                  margin: 0 0 30px;
                  & label {
                    color: $blue-A700;
                  }
                }
              }
            }
          }
        }
        & .nav {
          @include breakpoint('sm'){
            align-items: flex-end;
            padding: 0 3em;
          }
          & button {
            background-color: transparent;
            border: 1px solid $blue-A400;
            color: $blue-A400;
          }
        }
      }
    }
    &.mat-dialog-link-rateplan-charge {
      min-width: 0px;
      & .dialog-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        @include breakpoint('sm'){
          width: 720px;
          min-height: 0;
        }
        & .nav {
          margin: auto;
          margin-bottom: 0;
          @include breakpoint('sm'){
            align-items: center;
            border-top: 1px solid $grey-200;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin: auto;
            margin-left: 0;
            margin-right: 0;
            padding: 1em;
          }
          & .inner {
            align-items: center;
            display: flex;
            flex-direction: column;
            max-width: 420px;
            justify-content: center;
            @include breakpoint('sm'){
              align-items: center;
              display: flex;
              flex-direction: row;
              justify-content: center;
            }
            & .button-cancel {
              order: 2;
              @include breakpoint('sm'){
                order: 1;
                margin: 0;
              }
            }
            & .custom-mat-button-primary {
              margin: auto;
              order: 1;
              @include breakpoint('sm'){
                order: 2;
              }
            }
          }
        }
      }
    }
    &.mat-dialog-gateway-connect {
      & .dialog-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        & .body {
          max-width: 640px;
          padding: 1.5em 1em;
          & .block-info {
            & .label-paragraph {
              margin-top: 1em;
            }
          }
          & .message {
            &.message-row {
              margin-top: 2em;
              & .message-wrapper {
                &.info {
                  margin: 0;
                }
              }
            }
          }
        }
        & .head {
          position: relative;
          width: 100%;
          & .ico-close-panel {
            position: absolute;
            right: 1em;
          }
          & .ico-logo {
            height: 30px;
            width: auto;
          }
        }
        & .nav {
          & .links-additional{
            align-items: center;
            display: flex;
            flex-direction: row;
            font-family: $font-condensed;
            font-size: 14px;
            margin-top: 2em;
            & a {
              &.custom-mat-button {
                padding: 0 .5em;
              }
              & .mat-button-wrapper {
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    &.mat-dialog-user-role {
        & .dialog-user-role {
            & .head {
                border: 0 none;
                display: flex;
                flex-direction: column;
                padding: 60px;
                & .avatar {
                    align-items: center;
                    border-radius: 50%;
                    border: 1px solid $grey-300;
                    display: flex;
                    box-pack: center;
                    height: 128px;
                    justify-content: center;
                    margin: 40px 0;
                    overflow: hidden;
                    padding: 0;
                    width: 128px;
                    & .img-avatar {
                        height: 100%;
                        width: auto;
                    }
                }
            }
        }
    }
    &.mat-dialog-manage-filter {
        @include breakpoint('sm'){
            height: 80%;
            min-width: 520px;
            width: auto;
        }
        & .mat-dialog-container {
            background-color: $blue-grey-50;
            flex: 1;
            & .mat-card.custom-mat-card.card-form-wrapper .card-section {
                border: 0 none;
            }
        }
    }
    &.mat-dialog-new-payment-method, &.mat-dialog-tax, &.mat-dialog-taxregion, &.mat-dialog-shipping-connect, &.mat-dialog-shipping-settings {
      min-height: 100vh;
      @include breakpoint ('sm'){
        min-height: inherit;
      }
      & .mat-dialog-container {
        & .dialog-wrapper {
          & .body {
            & .form-wrapper {
              padding: 0;
              & .form-content {
                flex: 1;
                max-width: 90%;
                @include breakpoint('md'){
                  max-width: 640px;
                }
                & .form-section-head {
                  padding: 1em;
                }
                & .form-section-body {
                  padding: 1em;
                  & .form-row {
                    display: flex;
                    min-height: 64.5px;
                    margin: auto 0;
                    &.row-checkbox {
                      align-items: center;
                      justify-content: flex-start;
                      @include breakpoint('sm'){
                        padding-top: 0;
                      }
                    }
                    &.row-description {
                      display: flex;
                      flex-direction: column;
                      min-height: inherit;
                      padding: 1em 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.mat-dialog-payments {
      max-width: 550px!important;
    }
    &.mat-dialog-shortcuts {
      display: flex;
      height: 100vh;
      max-height: 100vh;
      max-width: 100vh;
      width: 100%;
      & .dialog-wrapper {
        & .body{
          margin: auto;
          padding: 1em;
          @include breakpoint('xl'){
            max-width: 85%;
          }
          & .body-wrapper {
            & .body-container{
              display: flex;
              & .element-card {
                -webkit-box-shadow: 0 10px 30px 0 rgba(0,0,0,.1);
                box-shadow: 0 10px 30px 0 rgba(0,0,0,0);
                flex: 4 1 0px;
                transition: box-shadow .3s ease-in-out;
                &:hover {
                  box-shadow: 0 10px 30px 0 rgba(0,0,0,.1);
                }
              }
            }
          }
        }
        & .footer {
          & .footer-wrapper {
            display: flex;
            padding: 2em;
            & .footer-container {
              margin: auto;
            }
          }
        }
      }
    }
    &.mat-dialog-payments {
      max-width: 550px;
    }
  }
}

.flex-customer-subscription {
  display: flex;
  flex-direction: column;
}

.isologo {
    background-size: auto auto;
    background-size: 100%;
    color: transparent;
    display: none;
    font-size: 1px;
    height: 40px;
    left: 40px;
    top: 40px;
    position: absolute;
    width: 100px;
    z-index: 1;
    @include breakpoint('md') { display: block; }
    &.rebillia-logo-blue {
        background-image: url('/assets/svg/logo-rebillia-blue.svg');
    }
    &.rebillia-logo-white {
        background-image: url('/assets/svg/logo-rebillia-white.svg');
    }
}

.mat-accordion {
    &.custom-mat-accordion {
        &.accordion-filter {
            & .custom-mat-expansion-panel {
                & .card-section {
                    max-width: 430px;
                }
            }
            & .label-title{
                margin: auto;
                margin-left: 8px;
            }
        }
        &.accordion-payments {
            position: relative;
            & .mat-expansion-panel {
                & .mat-expansion-panel-content {
                    font: inherit;
                }
                &.mat-expanded {
                    & .mat-expansion-panel-header {
                        pointer-events: none;
                    }
                    & .mat-expansion-panel-body {
                        padding: 24px;
                        & .creditcard-controller {
                            & .braintree-controller {
                                align-items: center;
                                display: flex;
                                min-height: 240px;
                                width: 100%;
                            }
                            & .stripe-controller {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
        &.form-panel {
            & .custom-mat-expansion-panel {
                border-top: 1px solid $grey-200;
                box-shadow: none;
                margin: 0;
                &:first-child {
                    border-top: none;
                }
                &.mat-expanded {
                    & .custom-mat-expansion-panel-header {
                        pointer-events: none;
                    }
                }
                & .custom-mat-expansion-panel-header {
                    height: auto!important;
                    padding: 20px;
                    & .custom-mat-panel-title {
                        display: flex;
                        flex-direction: column;
                        & .label-description {
                            font-size: .875em;
                            margin-top: 3px;
                        }
                    }
                }
                & .mat-expansion-panel-body {
                    padding: 0;
                }
                & .mat-expansion-panel-content {
                    font-size: inherit;
                    & .card-section {
                        padding: 0 20px 20px;
                    }
                }
            }
        }
        &.list-navigation {
            & .custom-mat-expansion-panel {
                background-color: transparent;
                border-bottom: 0 none;
                border-radius: 0;
                box-shadow: none;
                padding: 0 1em;
                @include breakpoint('lg'){
                  padding: 0;
                }
                & .custom-mat-expansion-panel-header {
                    background-color: transparent;
                    border-top: 1px solid $sidenav-divider-color;
                    height: 64px;
                    padding: 0;
                    @include breakpoint('lg'){ border-top: 0 none; height: 48px!important; }
                    & .custom-mat-expansion-panel-header-title {
                        margin: auto;
                        @include breakpoint('lg'){
                            color: $white;
                        }
                        & .ico-prefix {
                            align-items: center;
                            display: flex;
                            height: 40px;
                            justify-content: center;
                            margin: auto;
                            margin-left: 0;
                            margin-right: 0;
                            transition: height .3s ease-in-out, width .3s ease-in-out;
                            width: 40px;
                            @include breakpoint('lg'){
                                height: 50px;
                                min-width: 50px;
                                width: 50px;
                            }
                        }
                        & .ico-suffix {
                            margin: auto;
                            margin-left: 0;
                            margin-right: 0;
                        }
                        & label {
                            font-family: $font-semi-condensed;
                            font-size: .8125rem;
                            font-weight: 500;
                            line-height: 1;
                            margin: auto;
                            margin-left: 11px;
                            pointer-events: none;
                            text-transform: uppercase;
                            transition: opacity .3s ease-in-out;
                            @include breakpoint('lg'){
                                font-size: .9375em;
                                font-weight: 400;
                                margin-left: 0;
                                min-width: 100%;
                                text-transform: initial;
                            }
                            &.mat-line {
                                box-sizing: border-box;
                                display: block;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }
                    & .mat-expansion-indicator {
                        display: flex;
                        height: 20px;
                        margin: auto;
                        width: 40px;
                        &:after {
                            margin: auto;
                            @include breakpoint('md'){ border-width: 0 1px 1px 0; color: $white; }
                        }
                    }
                }
                & .mat-expansion-panel-body {
                    padding: 0 16px 16px;
                    @include breakpoint('lg'){ padding: 0; }
                }
                &.expansion-panel-settings {
                    &:after {
                        background-color: $sidenav-divider-color;
                        content: '';
                        display: block;
                        height: 1px;
                        width: 100%;
                        @include breakpoint('lg'){ background-color: transparent; height: 0; }
                    }
                }
                &.mat-expanded {
                    @include breakpoint('lg'){ background-color: $blue-A700; }
                    & .custom-mat-expansion-panel-header {
                        height: 64px;
                        @include breakpoint('lg'){ height: 48px!important; }
                        & .custom-mat-expansion-panel-header-title {
                            @include breakpoint('lg'){ color: $blue-50; font-size: 500; }
                        }
                    }
                }
            }
        }
    }
}

.mat-autocomplete-panel {
  &.custom-mat-autocomplete {
    &.autocomplete-avatax-codes {
      & .mat-option {
        height: auto;
        &.custom-mat-option {
          & .mat-option-text {
            border-bottom: 1px solid $grey-300;
            padding: 1em 0;
            & .label {
              line-height: 100%;
              margin: 0;
              padding: 0;
              &.label-tax-code {
                font-weight: 600;
                font-size: 16px;
              }
              &.label-tax-description {
                font-weight: 400;
                font-size: 12px;
                margin-top: .25em;
              }
            }
          }
          &.is-loading {
            & .mat-option-text {
                align-items: center;
                display: flex;
                flex-direction: row;
                & .custom-mat-spinner {
                    color: $grey-600;
                    fill: $grey-600;
                    & svg {
                        & circle {
                            stroke: $grey-600;
                        }
                    }
                }
                & .label-loading {
                    color: $grey-600;
                    font-style: italic;
                    margin-left: .5em;
                }
            }
          }
        }
      }
    }
    & .customer-mat-option {
      line-height: 1;
      & .label-email-customer {
      color: #757575;
      font-size: 0.875em;
      font-weight: 300;
      }
    }
    &.autocomplete-products {
      & .mat-option {
          &.custom-mat-option {
            & .mat-option-wrapper {
              display: flex;
              flex-direction: column;
            }
            &.is-loading {
                & .mat-option-text {
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                    & .custom-mat-spinner {
                        color: $grey-600;
                        fill: $grey-600;
                        & svg {
                            & circle {
                                stroke: $grey-600;
                            }
                        }
                    }
                    & .label-loading {
                        color: $grey-600;
                        font-style: italic;
                        margin-left: .5em;
                    }
                }
            }
            &.option-external-product {
              height: auto;
              & .mat-option-text {
                align-items: center;
                display: flex;
                min-height: 80px;
              }
            }
          }
      }
    }
  }
}

.mat-button-toggle-group {
    &.custom-mat-button-toggle-group {
        & .custom-mat-button-toggle {
            display: flex;
            transition: background-color .3s ease-in-out;
            & .mat-button-toggle-label-content {
                font-size: .875em;
            }
            &.breakline {
                border-left: 0;
                @include breakpoint('lg') {
                    border-left: inherit;
                }
            }
            &.rounded {
              border-radius: 50%;
              & .mat-button-toggle-ripple {
                border-radius: 50%;
              }
            }
        }
        &.group-calendar-days{
          border: 0 none;
          display: grid;
          grid-template-columns: repeat(7, minmax(45px, 1fr));
          column-gap: 0;
          row-gap: 0;
          width: 100%;
          @include breakpoint('md'){
            grid-template-columns: repeat(7, minmax(60px, 1fr));
          }
          & .custom-mat-button-toggle {
            align-items: center;
            background-color: transparent;
            border: 0 none;
            display: flex;
            justify-content: center;
            & .mat-button-toggle-button {
              border: 1px solid transparent;
              border-radius: 50%;
              max-height: 60px;
              max-width: 60px;
              transition: all .3s ease-in-out;
              &:hover {
                border: 1px solid $grey-200;
                background-color: $grey-50;
              }
            }
            & .mat-button-toggle-focus-overlay {
              display: none;
            }
            &.mat-button-toggle-checked {
              background-color: transparent;
              & .mat-button-toggle-button {
                border: 1px solid $blue-100;
                background-color: $blue-50;
              }
            }
            &:hover {
              background-color: none;
            }
          }
        }
        &.group-calendar-months {
          display: grid;
          grid-template-columns: repeat(4, minmax(60px, 1fr));
          column-gap: 0;
          row-gap: 0;
          width: 100%;
          @include breakpoint('lg'){
            grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
          }
        }
        &.group-currencies {
          & .custom-mat-button-toggle {
            &.toggle-currency {
              & .mat-button-toggle-button {
                & .mat-button-toggle-label-content {
                  align-items: center;
                  display: flex;
                  justify-content: space-between;
                  min-width: 80px;
                  & img {
                    &.desaturate {
                      -webkit-filter: grayscale(100%);
                      filter: grayscale(100%);
                      filter: gray;
                      filter: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' height='0'><filter id='greyscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0' /></filter></svg>#greyscale");
                    }
                  }
                }
              }
            }
          }
        }
    }
}

.mat-card {
  &.custom-mat-card {
    &.card-aside {
        background-color: transparent;
        border: 1px solid $grey-300;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        width: 100%;
        & .card-content {
            & .card-section {
                border-bottom: 1px solid $grey-300;
                padding: 20px;
            }
        }
        & .card-header {
            border-bottom: 1px solid $grey-300;
            min-height: 40px;
            & .mat-card-header-text {
                margin: auto;
                margin-left: 0;
                & .label-title {
                    margin: auto 20px;
                }
            }
        }
    }
    &.card-feature {
        box-shadow: none;
        & .mat-card-header {
            margin-bottom: 20px;
            & .custom-mat-icon {
                margin: auto;
            }
        }
    }
    &.card-filter {
        & .card-section {
            &.filter-footer {
                display: flex;
                flex-direction: row;
                & .col {
                    display: flex;
                    flex-direction: column;
                }
            }

        }

    }
    &.card-form-wrapper {
      border-radius: 0;
      box-shadow: none;
      padding: 0;
      @include breakpoint('sm'){
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
      }
      .card-content {
          font-size: $body-2-size;
          margin: 0;
          padding: 0;
      }
      .card-col {
          flex: 2;
      }
      .card-message {
          align-items: center;
          display: flex;
          flex-direction: column;
          padding: 30px;
          text-align: center;
          @include breakpoint('sm') { padding: 60px; }
          &.nodata {
              & .custom-img {
                  align-items: center;
                  display: flex;
                  height: auto;
                  justify-content: center;
                  max-height: 150px;
                  max-width: 100%;
                  @include breakpoint('lg') {
                      max-height: 250px;
                  }
              }
          }
          & .label-title {
              font-family: $font-condensed;
              font-size: $headline-size;
              font-weight: 300;
              margin: auto;
              margin-top: 30px;
              margin-bottom: 10px;
          }
          & .label-subtitle {
              font-size: $body-1-size;
              font-weight: 300;
              margin: auto;
              margin-bottom: 30px;
              max-width: 260px;
          }
      }
      .card-multiple-rows {
        .card-row {
          margin-bottom: 5px;
        }
      }
      .card-header {
          border-bottom: 1px solid $grey-200;
          display: flex;
          flex-direction: row;
          padding: 12px 16px;
          & .card-header-nav {
              & .custom-mat-link {
                  font-size: $body-1-size;
                  margin: auto;
                  margin-right: 0;
              }
          }
          & .label-title {
              font-family: $font-condensed;
              font-size: $body-1-size;
              font-weight: 600;
              margin: 0;
          }
          & .mat-card-header-text {
              margin: auto;
              margin-left: 0;
          }
      }
      .card-row {
          display: flex;
          flex-direction: row;
          min-height: 25px;
          padding: 0;
          & .label-description {
              flex: 3;
          }
          & .label {
              flex: 2;
              max-width: 110px;
          }
          &.has--cols {
              justify-content: space-between;
          }
      }
      .card-section {
        display: flex;
        flex-direction: column;
        padding: 16px;
        &.card-filter-rules {
            flex-direction: row;
            justify-content: space-between;
            & .new-rule {
                align-items: center;
                display: flex;
                justify-content: center;
            }
        }
        &.card-section-info {
            & .card-row {
                margin-top: 8px;
                & .label {
                    max-width: 90px;
                }
                &:first-child {
                    margin-top: 0;
                }
            }
        }
        &.hasBorder--bt {
            border-bottom: 1px solid $grey-200;
            border-top: 1px solid $grey-200;
        }
        &.noborder {
            border-bottom: 0 none;
        }
      }
      .form-row {
          display: flex;
          flex-direction: row;
          &.flex-column {
              flex-direction: column
          }
          &.note {
              margin-bottom: 16px;
          }
          &.form-row-nav {
              align-items: center;
              display: flex;
              justify-content: center;
          }
          &.form-row-filter-rules {
              margin: 0;
              margin-bottom: 24px;
              & .chip-rules {
                  width: 100%;
              }
          }
      }
      &.card-avatax {
        background-color: $grey-50;
        border: 0 none;
        border-bottom: 1px solid $grey-300;
        box-shadow: none;
        padding: .5em;
        @include breakpoint('sm'){
          border: 1px solid $grey-300;
          padding: 3em;
        }
        .card-row {
          flex-direction: column;
          @include breakpoint('sm'){
            flex-direction: row;
          }
          .card-section {
            flex: 4;
          }
          &.card-row-header {
            padding: 16px;
          }
        }
        .label-section-title {
          color: $grey-600;
          font-size: .6875em;
          font-weight: 300;
          margin-bottom: .5em;
        }
      }
      &.card-recommended {
        align-items: flex-start;
        background-color: $blue-grey-50;
        padding: 2em 0;
        & .card-row {
          display: flex;
          flex-direction: column;
          @include breakpoint('sm'){
            flex-direction: row;
          }
          & .card-col {
            &.col-gateway-logo {
              display: flex;
              margin-bottom: 1em;
              max-width: 100%;
              @include breakpoint('sm'){
                justify-content: center;
                margin-top: .5em;
                max-width: 30%;
              }
              @include breakpoint('md'){
                max-width: 25%;
              }
            }
            & ul {
              & li {
                align-items: center;
                display: flex;
                min-height: 30px;
                & .label {
                  margin-left: 1em;
                  max-width: 100%;
                }
              }
            }
            & nav {
              margin-top: 2em;
            }
          }
        }
      }
    }
    &.card-integration-setup {
        & .custom-img {
            &.logo-bigcommerce {
                height: 60px;
            }
            &.logo-goolgle-retail {
              height: 60px;
            }
            &.logo-mailchimp {
                height: 60px;
            }
            &.logo-salesforce {
                max-height: 150px;
            }
            &.logo-shipperhq {
                height: 60px;
            }
            &.logo-shipstation {
                height: 60px;
            }
            &.logo-shopify {
                height: 60px;
            }
            &.logo-quickbooks {
                max-height: 150px;
            }
        }
    }
    &.card-invoice {
      background-color: $grey-50;
      box-shadow: none;
      & .card-section {
        display: flex;
        flex-direction: column;
        padding: 0;
        @include breakpoint('md'){
          flex-direction: row;
        }
        & .card-col {
          display: flex;
          flex-direction: column;
          flex: 1;
          padding: 0;
          @include breakpoint('md'){
            flex: 3;
            flex-direction: row;
            padding: 1em;
          }
          & .col-flex-wrapper {
            border-bottom: 1px solid $grey-300;
            display: flex;
            flex-grow: 1;
            padding: 1em;
            @include breakpoint('md'){
              border: 0 none;
              padding: 0;
            }
            &.col-invoice-detail{
              & .row-item-detail {
                align-items: center;
                display: flex;
                justify-content: space-between;
                min-height: 25px;
              }
            }
          }
          & .col-side {
            display: flex;
            flex-direction: column;
            & .invoice-detail-container, &.customer-subscription-detail-container {
              border: 1px solid $grey-300;
              padding: .5em;
              & .row-item-detail {
                align-items: baseline;
                & .label-item {
                  display: flex;
                  flex-direction: column;
                  min-height: 45px;
                }
              }
            }
            & .invoice-total-container, &.customer-subscription-total-container {
              background-color: $grey-100;
              border: 1px solid $grey-300;
              border-top: 0 none;
              padding: .5em .5em 0;
              & .row-invoice-total {
                align-items: center;
                display: flex;
                justify-content: space-between;
                min-height: 25px;
                & .label-value {
                  color: $black;
                  font-size: 1em;
                  font-weight: 400;
                }
                &.row-grand-total {
                  border-top: 1px solid $grey-300;
                  margin-top: .5em;
                  min-height: 45px;
                  padding: 0;
                  & .label-item {
                    color: $black;
                    font-size: 1em;
                    font-weight: 400;
                  }
                  & .label-value {
                    color: $black;
                    font-size: 1.125em;
                    font-weight: 400;
                  }
                }
              }
            }
            &.side-left {
              align-items: flex-start;
              flex: 2;
              max-width: 70px;
              padding: 0 1em 0 0;
              @include breakpoint('md'){
                align-items: flex-end;
              }
            }
            &.side-right{
              flex: 2;
            }
          }
          & .label {
            font-size: .875em;
            font-weight: 300;
            &.label-customer-name, &.label-title {
              font-size: 1.125em;
              font-weight: 400;
              margin-bottom: .25em;
            }
            &.label-email {
              margin-top: 1em;
            }
            &.label-phone, &.label-email, &.label-created-at, &.label-invoice-source, &.label-invoice-tax, &.label-status, &.label-data, &.label-customers-subscription-surce {
              align-items: center;
              display: flex;
              min-height: 25px;
              justify-content: flex-start;
            }
            &.label-amount {
              font-weight: 400;
            }
          }
        }
        & .panel-section {
          display: flex;
          flex: 1;
          @include breakpoint('md'){
            padding: 1.25em 0;
          }
          & .panel-block {
            @include breakpoint('sm'){
              display: flex;
              flex: 3;
              flex-direction: row;
              max-width: 30%;
              padding: 1em;
            }
            & .col-side {
              display: flex;
              flex-direction: column;
              flex: 2;
              &.side-left {
                @include breakpoint('sm'){
                  align-items: flex-end;
                  max-width: 48px;
                  padding-right: .5em;
                }
              }
              &.side-right {
                border-left: 1px solid $grey-200;
                padding-left: .5em;
              }
              & .col-section {
                margin-top: .25em;
                & .col-row {
                  align-items: center;
                  display: flex;
                  height: 25px;
                  position: relative;
                  & .custom-mat-icon {
                    height: 25px;
                    left: -30px;
                    position: absolute;
                  }
                }
              }
            }
            & .skeleton-loader {
              width: 100%;
            }
            &.panel-summary {
              max-width: 100%;
              padding: 1em 0 1em 1em;
              width: 100%;
              & .customer-subscription-detail-container {
                padding: 0;
                padding-bottom: 1em;
                padding-left: .5em;
                padding-right: 1em;
                & .row-item-detail {
                  display: flex;
                  justify-content: space-between;
                  margin-top: .25em;
                  padding: 0;
                  & .line-item {
                    display: flex;
                    flex-direction: column;
                  }
                  &:first-child {
                    margin-top: 0;
                  }
                }
              }
              & .customer-subscription-total-container {
                background-color: $grey-100;
                border: 1px solid $grey-200;
                border-left: 0 none;
                padding: 1em 1em 1em .5em;
                & .row-customers-subscription-total {
                  align-items: center;
                  display: flex;
                  justify-content: space-between;
                  min-height: 25px;
                  padding: 0;
                  &.row-grand-total {
                    padding-top: 1em;
                  }
                }
              }
              & .col-side {
                &.side-right {
                  padding-left: 0;
                }
              }
            }
          }
        }
      }
      &.invoice-refund-reason {
        & .card-section {
          padding: 1em;
          & .custom-mat-form-field {
            background-color: white;
            padding: 1em;
            & .mat-form-field-wrapper {
              padding-bottom: 0;
              & .mat-form-field-flex {
                & .mat-form-field-infix {
                  & .mat-input-element {
                    margin: 0;
                    padding-bottom: 0;
                    padding-top: 0;
                  }
                }
              }
              & .mat-form-field-underline {
                bottom: 0;
              }
            }
          }
        }
      }
    }
    &.card-rate-plan {
      border: 1px solid $grey-300;
      border-radius: 0;
      box-shadow: none;
      margin: 1em 0;
      .card-row {
        &.card-row-header {
          display: flex;
          flex-direction: column;
          padding: 1em 0;
        }
      }
    }
    &.card-review {
        & .mat-card-header {
            & .mat-card-header-text {
                display: none;
                margin: 0;
            }
        }
        & .mat-card-content {
            margin: 10px 0;
        }
        & .mat-card-footer {
            margin: 0;
            & .name {
                font-size: $subheading-1-size;
            }
            & .job-title {
                font-size: $caption-size;
                font-weight: 300;
            }
        }
    }
    &.card-spanel-account {
        background-color: transparent;
        box-shadow: none;
        color: $white;
        padding-top: 0;
        padding-bottom: 60px;
        text-align: center;
    }
    &.card-subscription-detail {
        & .card-header {
            align-items: center;
            height: 50px;
            padding: 4px 16px;
            & .label-title {
                font-size: .9375em;
                font-weight: 400;
            }
        }
        & .charge-detail {
            border: 1px solid $grey-200;
            background-color: $grey-50;
            & .row-items {
                display: flex;
                flex-direction: column;
                padding: 16px 8px;
                & .card-row {
                    justify-content: space-between;
                    padding: 0;
                }
                & .item-description {
                    display: flex;
                    flex-direction: column;
                    & .label-item-description {
                        color: $grey-600;
                        font-size: .875em;
                    }
                }
            }
            & .row-next-charge {
                border-bottom: 1px solid $grey-200;
                padding: 8px;
                & .label-description {
                    font-family: $font-semi-condensed;
                    //font-size: 1em;
                    font-weight: 400;
                    letter-spacing: .75px;
                    text-align: left;
                }
            }
            & .row-total {
                border-top: 1px solid $grey-200;
                display: flex;
                flex-direction: column;
                padding: 8px;
                & .card-row {
                    justify-content: space-between;
                    padding: 0;
                }
            }
        }
        & .config-detail {
            & .card-row {
                padding: 4px 0;
            }
        }
    }
  }
}

.mat-card-actions, .mat-card-subtitle, .mat-card-content {
  margin: 0
}

.mat-checkbox {
  &.custom-mat-checkbox {
    .mat-checkbox-layout {
      display: flex;
      height: 32px;
      white-space: normal;
      .mat-checkbox-inner-container {
        height: 24px;
        transition: opacity .3s ease-in-out;
        width: 24px;
        .mat-checkbox-background {
          display: flex;
          border-radius: 50%;
          svg {
            height: 1.125em;
            margin: auto;
          }
        }
        .mat-checkbox-frame {
          background-color: $grey-100;
          border: 1px solid $grey-300;
          border-radius: 50%;
        }
      }
    }
    &.rounded {
      .mat-checkbox-layout {
        .mat-checkbox-inner-container {
          .mat-checkbox-background {
            border-radius: 3px;
          }
          .mat-checkbox-frame {
            border: 2px solid $grey-300;
            border-radius: 3px;
          }
        }
      }
    }
    &.square {
      .mat-checkbox-layout {
        .mat-checkbox-inner-container {
          .mat-checkbox-background {
            border-radius: 0;
          }
          .mat-checkbox-frame {
            border-radius: 0;
          }
        }
      }
    }
    &.checkbox-simple-line {
      .mat-checkbox-layout {
        align-items: flex-start;
        display: flex;
        height: 32px;
        white-space: normal;
        .mat-checkbox-inner-container {
          margin: auto;
          margin-left: 0;
          margin-right: .5em;
        }
        .mat-checkbox-label {
          margin: auto;
          margin-left: 0;
        }
      }
    }
    &.checkbox--with--description {
      .mat-checkbox-layout {
        display: flex;
        height: auto;
        .mat-checkbox-inner-container {
          margin: auto;
          margin-left: 0;
          margin-right: 8px;
          margin-top: 0;
        }
        .mat-checkbox-label {
          display: flex;
          flex-direction: column;
          white-space: normal;
        }
      }
    }
  }
}

.mat-checkbox-group {
    display: flex;
    & .placeholder {
        color: $grey-600;
        // font-size: 12px;
        margin-bottom: 12px;
        transform: translateX(-18px) scale(0.75);
    }
    &.flex-column {
        flex-direction: column;
    }
}

.mat-chip {
    &.custom-mat-chip {
        &.chip-filter-rule {
            height: auto;
            margin: 4px 0;
            max-width: 375px;
            & span {
                margin: 0;
                margin-right: 5px;
            }
        }
    }
}

.mat-chip-list {
    &.custom-mat-chip-list {
        &.chip-list-filter-rules {
            & .mat-chip-list-wrapper {
                justify-content: space-between;
                & .button-add-rule {
                    flex: 2;
                    max-width: 40px;
                }
                & .chip-filter-rule {
                    flex: 2;
                }
            }
        }
    }
}

.mat-expansion-panel {
  &.custom-mat-expansion-panel {
    &.expansion-panel-addition-options {
      box-shadow: none;
      .mat-expansion-panel-body {
        padding: 0;
        .card-section {
          display: flex;
          flex-direction: column;
        }
      }
      .mat-expansion-panel-header {
        &.custom-mat-expansion-panel-header {
          display: inline-flex;
          padding: 0;
          .custom-mat-panel-title {
            color: $blue-A400;
            margin: 0;
            margin-right: .5em;
          }
          .mat-content {
            align-items: center;
            color: $blue-A400;
          }
          &:active, &:focus, &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.mat-flat-button {
    &.custom-mat-flat-button {
        &.is--loading {
            background-color: $grey-300;
            color: $grey-700;
            pointer-events: none;
        }
    }
}

.mat-form-field {
  .mat-form-field-underline {
    background-color: $grey-300;
  }
  &.custom-mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 1em;
      .mat-form-field-flex {
        .mat-form-field-infix {
          border-top: 0 none;
          padding: 1.5em 0 1em;
          width: 100%;
          .mat-input-element {
            min-height: 21px;
          }
        }
        .mat-form-field-label-wrapper {
          padding-top: 0;
          top: 0;
          .mat-form-field-label {
            .mat-input-server:focus {
              text-transform: uppercase;
            }
          }
        }
        .mat-form-field-prefix {
          .disabled {
            color: $grey-400;
            fill: $grey-400;
          }
        }
      }
      .mat-form-field-subscript-wrapper {
        font-size: 75%;
        font-weight: 400;
      }
    }
    &.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
      text-transform: uppercase;
      transform: translateY(-1.28125em) scale(0.65) perspective(100px) translateZ(0.001px);
    }
    &.custom-mat-form-field-outline {
      .mat-form-field-wrapper {
        padding-bottom: 0;
        .mat-form-field-flex {
          .mat-form-field-infix {
            .mat-select-trigger {
              .mat-select-arrow-wrapper {
                align-items: center;
                display: flex;
              }
            }
          }
          .mat-form-field-outline {
            background-color: $white;
          }
        }
      }
      &.field-outline-payment-method {
        .mat-form-field-wrapper {
          .mat-form-field-flex {
            .mat-form-field-infix {
              padding: 2em 0 1.25em;
              .mat-form-field-label-wrapper {
                .mat-form-field-label {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
      &.field-amount {
        .mat-form-field-infix {
          .mat-input-element {
            padding: 0;
          }
        }
        .mat-form-field-prefix {
          bottom: 0;
          padding: 0 .5em;
          position: inherit;
          top: 0;
        }
        .mat-form-field-suffix {
          top: 0;
        }
      }
      &.field-tiered-unit {
        margin-right: .5em;
        width: 100%;
        & .mat-form-field-wrapper {
          & .mat-form-field-flex {
            align-items: center;
          }
          & .mat-form-field-prefix {
            padding: 0 0.5em 0 0;
            top: 4px;
          }
        }
      }
      &.size1of2 {
        margin-left: .5em;
        &:first-child {
          margin-left: 0;
        }
      }
    }
    &.field-amount, &.field-percentage {
      .mat-form-field-prefix {
        bottom: 34px;
        position: absolute;
        & .custom-form-field-prefix {
          &.prefix-currency-iso3 {
            font-size: .75em;
          }
        }
      }
      .mat-input-element {
        min-height: 21px;
        padding: 0 0 0 1.5em
      }
    }
    &.field-date-picker {
      & .mat-form-field-wrapper {
        & .mat-form-field-flex {
          align-items: flex-start;
          & .mat-form-field-infix {
            padding: 1.5em 0 1em;
            & .mat-input-element {
              min-height: 21px;
            }
          }
          & .mat-form-field-suffix {
            padding: 1.275em 0 0;
          }
        }
        & .mat-form-field-underline {
          background-image: none;
          background-color: #e0e0e0;
        }
      }
    }
    &.field-exchange-rate {
      & .mat-form-field-wrapper {
        & .mat-form-field-flex {
          & .mat-form-field-infix {
            display: flex;
            padding: 1.5em 1em 1em;
          }
        }
      }
    }
    &.field-hosted-field {
        & .hosted-field {
            height: 40px;
        }
        & .mat-form-field-wrapper {
            & .mat-form-field-flex {
                & .mat-form-field-outline {
                    color: $grey-400;
                }
                & .mat-form-field-infix {
                    padding: 0;
                }
            }
        }
    }
    &.field-invoice-currency {
      margin: auto;
      margin-left: 1em;
      margin-right: 1em;
      max-width: 120px;
      min-width: 80px;
      & .mat-form-field-wrapper {
        padding: 0;
        width: auto;
        & .mat-form-field-infix {
          padding: 0;
          & .custom-mat-select {
            min-height: 0;
            & .mat-select-value-text {
              color: $blue-A900;
              font-weight: 400;
            }
          }
        }
        & .mat-form-field-underline {
          display: none;
        }
      }
    }
    &.field-invoice-item {
      & .mat-form-field-wrapper {
        padding: 0;
        @include breakpoint('sm'){
          margin: .25em;
        }
        & .mat-form-field-flex {
          align-items: center;
          display: flex;
          margin: 0;
          padding: 0;
          @include breakpoint('sm'){
            min-height: 3em;
            padding: .5em;
          }
          & .mat-form-field-infix {
            padding: 0;
            & .mat-input-element {
              margin: 0;
              &:disabled {
                color: $grey-900;
              }
            }
          }
          & .mat-form-field-outline {
            display: none;
            @include breakpoint('sm'){
              display: flex;
              top: 0
            }
          }
          & .mat-form-field-prefix {
            align-items: center;
            bottom: 0;
            display: flex;
            top: 0;
          }
        }
      }
      &.field-amount {
        & .mat-form-field-wrapper {
          & .mat-form-field-flex {
            & .mat-form-field-infix {
              & .mat-input-element {
                text-align: right;
              }
            }
          }
        }
      }
      &.field-qty {
        & .mat-form-field-wrapper {
          & .mat-form-field-flex {
            & .mat-form-field-infix {
              & .mat-input-element {
                text-align: center;
              }
            }
          }
        }
      }
    }
    &.field-payment-methods {
      & .mat-form-field-wrapper {
        & .mat-form-field-flex {
          background-color: $white;
          border: 1px solid $grey-300;
          border-radius: 4px;
          padding: .5em 1em 0;
          transition: border .3s ease-in-out;
          & .mat-form-field-infix {
            align-items: center;
            display: flex;
          }
        }
        & .mat-form-field-subscript-wrapper {
          margin-top: 1em;
          padding: .5em 0;
        }
        & .mat-form-field-underline {
          display: none;
        }
      }
      &:hover {
        & .mat-form-field-wrapper {
          & .mat-form-field-flex {
            border: 1px solid $grey-900;
          }
        }
      }
    }
    &.field-phone {
        & .mat-form-field-label-wrapper {
            left: -22px;
        }
    }
    &.field-search {
        background-color: transparent;
        border: 0 none;
        border-radius: 0;
        color: $white;
        height: 40px;
        transition: background-color .3s ease-in-out;
        width: 100%;
        & .mat-form-field-wrapper {
            height: 100%;
            margin: 0;
            padding: 0;
            & .mat-form-field-underline {
                display: none;
            }
            & .mat-form-field-flex {
                height: 100%;
                margin: 0;
                padding: 0;
                & .mat-form-field-prefix {
                    display: flex;
                    margin: auto;
                    justify-content: center;
                    width: 50px;
                }
                & .mat-form-field-infix {
                    border-top: 0;
                    display: flex;
                    height: 100%;
                    margin: auto;
                    padding: 0;
                    width: 100%;
                    & input {
                        &.mat-input-element {
                            caret-color: $white;
                            font-size: $body-1-size;
                            margin: auto;
                            margin-left: 0;
                        }
                    }
                    & span {
                        &.mat-form-field-label-wrapper {
                            display: flex;
                            font-size: $body-1-size;
                            padding: 0;
                            top: 0;
                            & .mat-form-field-label {
                                color: $white;
                                margin: auto;
                                margin-right: 0;
                                position: relative;
                                top: 0;
                            }
                        }
                    }
                }
            }
        }
        & .button-clean-search {
            opacity: 0;
            pointer-events: none;
            transition: opacity .3s ease-in-out;
            &.is--visible {
                opacity: 1;
                pointer-events: auto;
            }
        }
        &.mat-focused {
            background-color: $blue-grey-900;
            color: $white;
            & .mat-form-field-wrapper {
                & .mat-form-field-flex {
                    & .mat-form-field-infix {
                        & span {
                            &.mat-form-field-label-wrapper {
                                & .mat-form-field-label {
                                    color: $blue-grey-400;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.is--active {
            background-color: $blue-grey-900;
            color: $white;
        }
    }
    &.field-search-head {
        color: $grey-900;
        @include breakpoint('md'){
          display: flex;
          min-width: 420px;
        }
        & .mat-form-field-wrapper {
            display: flex;
            flex: 1;
            height: 100%;
            margin: 0;
            padding: 0;
            & .mat-form-field-underline {
                display: none;
            }
            & .mat-form-field-flex {
                background-color: $white;
                border: 1px solid $grey-300;
                border-radius: 16px;
                height: 100%;
                margin: 0;
                min-height: 40px;
                padding: 0;
                transition: border .3s ease-in-out;
                & .mat-form-field-prefix {
                    display: flex;
                    margin: auto;
                    justify-content: center;
                    width: 50px;
                }
                & .mat-form-field-infix {
                    border-top: 0;
                    display: flex;
                    height: 100%;
                    margin: auto;
                    padding: 0;
                    width: 100%;
                    & input {
                        &.mat-input-element {
                            caret-color: $grey-900;
                            font-size: $body-1-size;
                            margin: auto;
                            margin-left: 0;
                        }
                    }
                    & span {
                        &.mat-form-field-label-wrapper {
                            display: flex;
                            font-size: $body-1-size;
                            padding: 0;
                            top: 0;
                            & .mat-form-field-label {
                                color: $grey-900;
                                margin: auto;
                                margin-right: 0;
                                position: relative;
                                top: 0;
                            }
                        }
                    }
                }
            }
        }
        & .button-clean-search {
            opacity: 0;
            pointer-events: none;
            transition: opacity .3s ease-in-out;
            &.is--visible {
                opacity: 1;
                pointer-events: auto;
            }
        }
        &.mat-focused {
            & .mat-form-field-wrapper {
                & .mat-form-field-flex {
                    border: 1px solid $blue-A400;
                    & .mat-form-field-infix {
                        & span {
                            &.mat-form-field-label-wrapper {
                                & .mat-form-field-label {
                                    color: $grey-300;
                                }
                            }
                        }
                    }
                    & .mat-form-field-prefix {
                        & .custom-mat-icon {
                            color: $blue-A400;
                        }
                    }
                }
            }
        }
        &.is--active {
            background-color: $blue-grey-900;
            color: $grey-900;
        }
    }
    &.field-select-order-status {
      min-width: 100%;
      @include breakpoint('md'){
        min-width: 230px;
      }
    }
    &.field-number-unit {
        & .mat-form-field-wrapper {
            & .mat-form-field-flex {
                & .mat-form-field-suffix {
                    color: $grey-500;
                    fill: $grey-500;
                    font-size: .875em;
                    font-weight: 300;
                }
            }
        }
    }
    &.field--has--hint {
        margin-bottom: 1em;
        &.margin-bottom-2x {
          margin-bottom: 2em;
        }
    }
    &.mat-form-field-disabled {
        & .mat-form-field-wrapper {
            & .mat-form-field-flex {
                & .mat-form-field-prefix {
                    color: $grey-400;
                    fill: $grey-400;
                }
            }
        }
    }
  }
}

.mat-hint {
  &.custom-mat-hint {
    display: none;
    &.hint-gateway {
      display: flex;
      padding: 2em 0;
    }
    &.hint-gateway-new {
      display: flex;
      padding: 0px 0px 1.5em 1em;
      font-size: 75%;
      font-weight: 400;
   }
  }
}

.mat-icon {
  &.custom-mat-icon {
    display: flex;
    & svg {
        margin: auto;
    }
    &.ico-alert {
      & svg {
        fill: $red-A400;
      }
    }
    &.ico-avatars {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        &.avatar-jeff {
            background-image: url('/assets/images/avatars-jeff.png')
        }
    }
    &.ico-back {
        background-color: $grey-200;
        border-radius: 50%;
        color: $grey-900;
        display: flex;
        height: 40px;
        width: 40px;
        & svg {
            height: 10px;
            margin: auto;
            width: 10px;
        }
    }
    &.ico-bullet-list {
        //font-size: 1em;
        height: auto;
        margin: auto;
        margin-bottom: 0;
        margin-right: 5px;
        width: auto;
    }
    &.ico-cancel-rule {
        margin: auto;
        margin-right: 0;
    }
    &.ico-company {
        background-color: $blue-300;
        border-radius: 50%;
        height: 40px;
        margin: auto;
        width: 40px;
        & svg {
            height: 20px;
            margin: auto;
            width: 20px;
        }
    }
    &.ico-flag {
        &.flag-AF {
            background-image: url(https://restcountries.eu/data/afg.svg);
        }
        &.flag-AL {
            background-image: url(https://restcountries.eu/data/alb.svg);
        }
        &.flag-DZ {
            background-image: url(https://restcountries.eu/data/dza.svg);
        }
        &.flag-AS {
            background-image: url(https://restcountries.eu/data/asm.svg);
        }
        &.flag-AD {
            background-image: url(https://restcountries.eu/data/and.svg);
        }
        &.flag-AO {
            background-image: url(https://restcountries.eu/data/ago.svg);
        }
        &.flag-AI {
            background-image: url(https://restcountries.eu/data/aia.svg);
        }
        &.flag-AQ {
            background-image: url(https://restcountries.eu/data/ata.svg);
        }
        &.flag-AG {
            background-image: url(https://restcountries.eu/data/atg.svg);
        }
        &.flag-AR {
            background-image: url(https://restcountries.eu/data/arg.svg);
        }
        &.flag-AM {
            background-image: url(https://restcountries.eu/data/arm.svg);
        }
        &.flag-AW {
            background-image: url(https://restcountries.eu/data/abw.svg);
        }
        &.flag-AU {
            background-image: url(https://restcountries.eu/data/aus.svg);
        }
        &.flag-AZ {
            background-image: url(https://restcountries.eu/data/aze.svg);
        }
        &.flag-BS {
            background-image: url(https://restcountries.eu/data/bhs.svg);
        }
        &.flag-BH {
            background-image: url(https://restcountries.eu/data/bhr.svg);
        }
        &.flag-BD {
            background-image: url(https://restcountries.eu/data/bgd.svg);
        }
        &.flag-BB {
            background-image: url(https://restcountries.eu/data/brb.svg);
        }
        &.flag-BY {
            background-image: url(https://restcountries.eu/data/blr.svg);
        }
        &.flag-BZ {
            background-image: url(https://restcountries.eu/data/blz.svg);
        }
        &.flag-BJ {
            background-image: url(https://restcountries.eu/data/ben.svg);
        }
        &.flag-BM {
            background-image: url(https://restcountries.eu/data/bmu.svg);
        }
        &.flag-BT {
            background-image: url(https://restcountries.eu/data/btn.svg);
        }
        &.flag-BO {
            background-image: url(https://restcountries.eu/data/bol.svg);
        }
        &.flag-BA {
            background-image: url(https://restcountries.eu/data/bih.svg);
        }
        &.flag-BW {
            background-image: url(https://restcountries.eu/data/bwa.svg);
        }
        &.flag-BV {
            background-image: url(https://restcountries.eu/data/bvt.svg);
        }
        &.flag-BV {
            background-image: url(https://restcountries.eu/data/bvt.svg);
        }
        &.flag-BR {
            background-image: url(https://restcountries.eu/data/bra.svg);
        }
        &.flag-IO {
            background-image: url(https://restcountries.eu/data/iot.svg);
        }
        &.flag-VG {
            background-image: url(https://restcountries.eu/data/vgb.svg);
        }
        &.flag-BN {
            background-image: url(https://restcountries.eu/data/brn.svg);
        }
        &.flag-BF {
            background-image: url(https://restcountries.eu/data/bfa.svg);
        }
        &.flag-BI {
            background-image: url(https://restcountries.eu/data/bdi.svg);
        }
        &.flag-KH {
            background-image: url(https://restcountries.eu/data/khm.svg);
        }
        &.flag-CM {
            background-image: url(https://restcountries.eu/data/cmr.svg);
        }
        &.flag-CA {
            background-image: url(https://restcountries.eu/data/can.svg);
        }
        &.flag-CV {
            background-image: url(https://restcountries.eu/data/cpv.svg);
        }
        &.flag-KY {
            background-image: url(https://restcountries.eu/data/cym.svg);
        }
        &.flag-CF {
            background-image: url(https://restcountries.eu/data/caf.svg);
        }
        &.flag-TD {
            background-image: url(https://restcountries.eu/data/tcd.svg);
        }
        &.flag-CL {
            background-image: url(https://restcountries.eu/data/chl.svg);
        }
        &.flag-CN {
            background-image: url(https://restcountries.eu/data/chn.svg);
        }
        &.flag-CX {
            background-image: url(https://restcountries.eu/data/cxr.svg);
        }
        &.flag-CC {
            background-image: url(https://restcountries.eu/data/cck.svg);
        }
        &.flag-CO {
            background-image: url(https://restcountries.eu/data/col.svg);
        }
        &.flag-KM {
            background-image: url(https://restcountries.eu/data/com.svg);
        }
        &.flag-CD {
            background-image: url(https://restcountries.eu/data/cog.svg);
        }
        &.flag-CK {
            background-image: url(https://restcountries.eu/data/cok.svg);
        }
        &.flag-CR {
            background-image: url(https://restcountries.eu/data/cri.svg);
        }
        &.flag-CI {
            background-image: url(https://restcountries.eu/data/civ.svg);
        }
        &.flag-CW {
            background-image: url(https://restcountries.eu/data/cuw.svg);
        }
        &.flag-CU {
            background-image: url(https://restcountries.eu/data/cub.svg);
        }
        &.flag-DJ {
            background-image: url(https://restcountries.eu/data/dji.svg);
        }
        &.flag-DM {
            background-image: url(https://restcountries.eu/data/dma.svg);
        }
        &.flag-DO {
            background-image: url(https://restcountries.eu/data/dom.svg);
        }
        &.flag-EC {
            background-image: url(https://restcountries.eu/data/ecu.svg);
        }
        &.flag-EG {
            background-image: url(https://restcountries.eu/data/egy.svg);
        }
        &.flag-SV {
            background-image: url(https://restcountries.eu/data/slv.svg);
        }
        &.flag-GQ {
            background-image: url(https://restcountries.eu/data/gnq.svg);
        }
        &.flag-ER {
            background-image: url(https://restcountries.eu/data/eri.svg);
        }
        &.flag-ET {
            background-image: url(https://restcountries.eu/data/eth.svg);
        }
        &.flag-FK {
            background-image: url(https://restcountries.eu/data/flk.svg);
        }
        &.flag-FO {
            background-image: url(https://restcountries.eu/data/fro.svg);
        }
        &.flag-FJ {
            background-image: url(https://restcountries.eu/data/fji.svg);
        }
        &.flag-GF {
            background-image: url(https://restcountries.eu/data/guf.svg);
        }
        &.flag-PF {
            background-image: url(https://restcountries.eu/data/pyf.svg);
        }
        &.flag-TF {
            background-image: url(https://restcountries.eu/data/atf.svg);
        }
        &.flag-GA {
            background-image: url(https://restcountries.eu/data/gab.svg);
        }
        &.flag-GM {
            background-image: url(https://restcountries.eu/data/gmb.svg);
        }
        &.flag-GE {
            background-image: url(https://restcountries.eu/data/geo.svg);
        }
        &.flag-GH {
            background-image: url(https://restcountries.eu/data/gha.svg);
        }
        &.flag-GI {
            background-image: url(https://restcountries.eu/data/gib.svg);
        }
        &.flag-GL {
            background-image: url(https://restcountries.eu/data/grl.svg);
        }
        &.flag-GD {
            background-image: url(https://restcountries.eu/data/grd.svg);
        }
        &.flag-GP {
            background-image: url(https://restcountries.eu/data/glp.svg);
        }
        &.flag-GU {
            background-image: url(https://restcountries.eu/data/gum.svg);
        }
        &.flag-GT {
            background-image: url(https://restcountries.eu/data/gtm.svg);
        }
        &.flag-GG {
            background-image: url(https://restcountries.eu/data/ggy.svg);
        }
        &.flag-GN {
            background-image: url(https://restcountries.eu/data/gin.svg);
        }
        &.flag-GW {
            background-image: url(https://restcountries.eu/data/gnb.svg);
        }
        &.flag-GY {
            background-image: url(https://restcountries.eu/data/guy.svg);
        }
        &.flag-HT {
            background-image: url(https://restcountries.eu/data/hti.svg);
        }
        &.flag-HM {
            background-image: url(https://restcountries.eu/data/hmd.svg);
        }
        &.flag-HN {
            background-image: url(https://restcountries.eu/data/hnd.svg);
        }
        &.flag-HK {
            background-image: url(https://restcountries.eu/data/hkg.svg);
        }
        &.flag-IS {
            background-image: url(https://restcountries.eu/data/isl.svg);
        }
        &.flag-IN {
            background-image: url(https://restcountries.eu/data/ind.svg);
        }
        &.flag-ID {
            background-image: url(https://restcountries.eu/data/idn.svg);
        }
        &.flag-IR {
            background-image: url(https://restcountries.eu/data/irn.svg);
        }
        &.flag-IQ {
            background-image: url(https://restcountries.eu/data/irq.svg);
        }
        &.flag-IM {
            background-image: url(https://restcountries.eu/data/imn.svg);
        }
        &.flag-IL {
            background-image: url(https://restcountries.eu/data/isr.svg);
        }
        &.flag-JM {
            background-image: url(https://restcountries.eu/data/jam.svg);
        }
        &.flag-JP {
            background-image: url(https://restcountries.eu/data/jpn.svg);
        }
        &.flag-JE {
            background-image: url(https://restcountries.eu/data/jey.svg);
        }
        &.flag-JO {
            background-image: url(https://restcountries.eu/data/jor.svg);
        }
        &.flag-KZ {
            background-image: url(https://restcountries.eu/data/kaz.svg);
        }
        &.flag-KE {
            background-image: url(https://restcountries.eu/data/ken.svg);
        }
        &.flag-KI {
            background-image: url(https://restcountries.eu/data/kir.svg);
        }
        &.flag-KP {
            background-image: url(https://restcountries.eu/data/prk.svg);
        }
        &.flag-KR {
            background-image: url(https://restcountries.eu/data/kor.svg);
        }
        &.flag-KW {
            background-image: url(https://restcountries.eu/data/kwt.svg);
        }
        &.flag-KG {
            background-image: url(https://restcountries.eu/data/kgz.svg);
        }
        &.flag-LA {
            background-image: url(https://restcountries.eu/data/lao.svg);
        }
        &.flag-LB {
            background-image: url(https://restcountries.eu/data/lbn.svg);
        }
        &.flag-LS {
            background-image: url(https://restcountries.eu/data/lso.svg);
        }
        &.flag-LR {
            background-image: url(https://restcountries.eu/data/lbr.svg);
        }
        &.flag-LY {
            background-image: url(https://restcountries.eu/data/lby.svg);
        }
        &.flag-LI {
            background-image: url(https://restcountries.eu/data/lie.svg);
        }
        &.flag-MO {
            background-image: url(https://restcountries.eu/data/mac.svg);
        }
        &.flag-MK {
            background-image: url(https://restcountries.eu/data/mkd.svg);
        }
        &.flag-MG {
            background-image: url(https://restcountries.eu/data/mdg.svg);
        }
        &.flag-MW {
            background-image: url(https://restcountries.eu/data/mwi.svg);
        }
        &.flag-MY {
            background-image: url(https://restcountries.eu/data/mys.svg);
        }
        &.flag-MV {
            background-image: url(https://restcountries.eu/data/mdv.svg);
        }
        &.flag-ML {
            background-image: url(https://restcountries.eu/data/mli.svg);
        }
        &.flag-MH {
            background-image: url(https://restcountries.eu/data/mhl.svg);
        }
        &.flag-MQ {
            background-image: url(https://restcountries.eu/data/mtq.svg);
        }
        &.flag-MR {
            background-image: url(https://restcountries.eu/data/mrt.svg);
        }
        &.flag-MU {
            background-image: url(https://restcountries.eu/data/mus.svg);
        }
        &.flag-YT {
            background-image: url(https://restcountries.eu/data/myt.svg);
        }
        &.flag-MX {
            background-image: url(https://restcountries.eu/data/mex.svg);
        }
        &.flag-FM {
            background-image: url(https://restcountries.eu/data/fsm.svg);
        }
        &.flag-MD {
            background-image: url(https://restcountries.eu/data/mda.svg);
        }
        &.flag-MC {
            background-image: url(https://restcountries.eu/data/mco.svg);
        }
        &.flag-MN {
            background-image: url(https://restcountries.eu/data/mng.svg);
        }
        &.flag-ME {
            background-image: url(https://restcountries.eu/data/mne.svg);
        }
        &.flag-MS {
            background-image: url(https://restcountries.eu/data/msr.svg);
        }
        &.flag-MA {
            background-image: url(https://restcountries.eu/data/mar.svg);
        }
        &.flag-MZ {
            background-image: url(https://restcountries.eu/data/moz.svg);
        }
        &.flag-MM {
            background-image: url(https://restcountries.eu/data/mmr.svg);
        }
        &.flag-NA {
            background-image: url(https://restcountries.eu/data/nam.svg);
        }
        &.flag-NR {
            background-image: url(https://restcountries.eu/data/nru.svg);
        }
        &.flag-NP {
            background-image: url(https://restcountries.eu/data/npl.svg);
        }
        &.flag-NC {
            background-image: url(https://restcountries.eu/data/ncl.svg);
        }
        &.flag-NZ {
            background-image: url(https://restcountries.eu/data/nzl.svg);
        }
        &.flag-NI {
            background-image: url(https://restcountries.eu/data/nic.svg);
        }
        &.flag-NE {
            background-image: url(https://restcountries.eu/data/ner.svg);
        }
        &.flag-NG {
            background-image: url(https://restcountries.eu/data/nga.svg);
        }
        &.flag-NU {
            background-image: url(https://restcountries.eu/data/niu.svg);
        }
        &.flag-NF {
            background-image: url(https://restcountries.eu/data/nfk.svg);
        }
        &.flag-MP {
            background-image: url(https://restcountries.eu/data/mnp.svg);
        }
        &.flag-NO {
            background-image: url(https://restcountries.eu/data/nor.svg);
        }
        &.flag-OM {
            background-image: url(https://restcountries.eu/data/omn.svg);
        }
        &.flag-PK {
            background-image: url(https://restcountries.eu/data/pak.svg);
        }
        &.flag-PW {
            background-image: url(https://restcountries.eu/data/plw.svg);
        }
        &.flag-PA {
            background-image: url(https://restcountries.eu/data/pan.svg);
        }
        &.flag-PG {
            background-image: url(https://restcountries.eu/data/png.svg);
        }
        &.flag-PY {
            background-image: url(https://restcountries.eu/data/pry.svg);
        }
        &.flag-PE {
            background-image: url(https://restcountries.eu/data/per.svg);
        }
        &.flag-PH {
            background-image: url(https://restcountries.eu/data/phl.svg);
        }
        &.flag-PN {
            background-image: url(https://restcountries.eu/data/pcn.svg);
        }
        &.flag-PR {
            background-image: url(https://restcountries.eu/data/pri.svg);
        }
        &.flag-QA {
            background-image: url(https://restcountries.eu/data/qat.svg);
        }
        &.flag-RE {
            background-image: url(https://restcountries.eu/data/reu.svg);
        }
        &.flag-RU {
            background-image: url(https://restcountries.eu/data/rus.svg);
        }
        &.flag-RW {
            background-image: url(https://restcountries.eu/data/rwa.svg);
        }
        &.flag-BL {
            background-image: url(https://restcountries.eu/data/blm.svg);
        }
        &.flag-SH {
            background-image: url(https://restcountries.eu/data/shn.svg);
        }
        &.flag-KN {
            background-image: url(https://restcountries.eu/data/kna.svg);
        }
        &.flag-LC {
            background-image: url(https://restcountries.eu/data/lca.svg);
        }
        &.flag-MF {
            background-image: url(https://restcountries.eu/data/lca.svg);
        }
        &.flag-PM {
            background-image: url(https://restcountries.eu/data/spm.svg);
        }
        &.flag-VC {
            background-image: url(https://restcountries.eu/data/vct.svg);
        }
        &.flag-WS {
            background-image: url(https://restcountries.eu/data/wsm.svg);
        }
        &.flag-SM {
            background-image: url(https://restcountries.eu/data/smr.svg);
        }
        &.flag-ST {
            background-image: url(https://restcountries.eu/data/stp.svg);
        }
        &.flag-SA {
            background-image: url(https://restcountries.eu/data/sau.svg);
        }
        &.flag-SN {
            background-image: url(https://restcountries.eu/data/sen.svg);
        }
        &.flag-RS {
            background-image: url(https://restcountries.eu/data/srb.svg);
        }
        &.flag-SC {
            background-image: url(https://restcountries.eu/data/syc.svg);
        }
        &.flag-SL {
            background-image: url(https://restcountries.eu/data/sle.svg);
        }
        &.flag-SG {
            background-image: url(https://restcountries.eu/data/sgp.svg);
        }
        &.flag-BQ {
            background-image: url(https://restcountries.eu/data/bes.svg);
        }
        &.flag-SX {
            background-image: url(https://restcountries.eu/data/sxm.svg);
        }
        &.flag-SB {
            background-image: url(https://restcountries.eu/data/slb.svg);
        }
        &.flag-SO {
            background-image: url(https://restcountries.eu/data/som.svg);
        }
        &.flag-ZA {
            background-image: url(https://restcountries.eu/data/zaf.svg);
        }
        &.flag-GS {
            background-image: url(https://restcountries.eu/data/sgs.svg);
        }
        &.flag-SS {
            background-image: url(https://restcountries.eu/data/ssd.svg);
        }
        &.flag-LK {
            background-image: url(https://restcountries.eu/data/lka.svg);
        }
        &.flag-SD {
            background-image: url(https://restcountries.eu/data/sdn.svg);
        }
        &.flag-SR {
            background-image: url(https://restcountries.eu/data/sur.svg);
        }
        &.flag-SJ {
            background-image: url(https://restcountries.eu/data/sjm.svg);
        }
        &.flag-SZ {
            background-image: url(https://restcountries.eu/data/swz.svg);
        }
        &.flag-CH {
            background-image: url(https://restcountries.eu/data/che.svg);
        }
        &.flag-SY {
            background-image: url(https://restcountries.eu/data/syr.svg);
        }
        &.flag-TW {
            background-image: url(https://restcountries.eu/data/twn.svg);
        }
        &.flag-TJ {
            background-image: url(https://restcountries.eu/data/tjk.svg);
        }
        &.flag-TZ {
            background-image: url(https://restcountries.eu/data/tza.svg);
        }
        &.flag-TH {
            background-image: url(https://restcountries.eu/data/tha.svg);
        }
        &.flag-TL {
            background-image: url(https://restcountries.eu/data/tls.svg);
        }
        &.flag-TG {
            background-image: url(https://restcountries.eu/data/tgo.svg);
        }
        &.flag-TK {
            background-image: url(https://restcountries.eu/data/tkl.svg);
        }
        &.flag-TO {
            background-image: url(https://restcountries.eu/data/ton.svg);
        }
        &.flag-TT {
            background-image: url(https://restcountries.eu/data/tto.svg);
        }
        &.flag-TN {
            background-image: url(https://restcountries.eu/data/tun.svg);
        }
        &.flag-TR {
            background-image: url(https://restcountries.eu/data/tur.svg);
        }
        &.flag-TM {
            background-image: url(https://restcountries.eu/data/tkm.svg);
        }
        &.flag-TC {
            background-image: url(https://restcountries.eu/data/tca.svg);
        }
        &.flag-TV {
            background-image: url(https://restcountries.eu/data/tuv.svg);
        }
        &.flag-UG {
            background-image: url(https://restcountries.eu/data/uga.svg);
        }
        &.flag-UA {
            background-image: url(https://restcountries.eu/data/ukr.svg);
        }
        &.flag-AE {
            background-image: url(https://restcountries.eu/data/are.svg);
        }
        &.flag-US {
            background-image: url(https://restcountries.eu/data/usa.svg);
        }
        &.flag-UM {
            background-image: url(https://restcountries.eu/data/umi.svg);
        }
        &.flag-UY {
            background-image: url(https://restcountries.eu/data/ury.svg);
        }
        &.flag-UZ {
            background-image: url(https://restcountries.eu/data/uzb.svg);
        }
        &.flag-VU {
            background-image: url(https://restcountries.eu/data/vut.svg);
        }
        &.flag-VA {
            background-image: url(https://restcountries.eu/data/vat.svg);
        }
        &.flag-VE {
            background-image: url(https://restcountries.eu/data/ven.svg);
        }
        &.flag-VN {
            background-image: url(https://restcountries.eu/data/vnm.svg);
        }
        &.flag-VI {
            background-image: url(https://restcountries.eu/data/vir.svg);
        }
        &.flag-WF {
            background-image: url(https://restcountries.eu/data/wlf.svg);
        }
        &.flag-EH {
            background-image: url(https://restcountries.eu/data/esh.svg);
        }
        &.flag-YE {
            background-image: url(https://restcountries.eu/data/yem.svg);
        }
        &.flag-ZM {
            background-image: url(https://restcountries.eu/data/zmb.svg);
        }
        &.flag-ZW {
            background-image: url(https://restcountries.eu/data/zwe.svg);
        }
        &.flag-AX {
            background-image: url(https://restcountries.eu/data/ala.svg);
        }
        &.flag-EU {
            background-image: url(https://upload.wikimedia.org/wikipedia/commons/b/b7/Flag_of_Europe.svg);
        }
    }
    &.ico-gateway {
        background-position: 50% 50%;
        background-size: contain;
        border-radius: 0;
        height: 24px;
        width: 24px;
        &.ico-gateway-adyen {
            background-image: url('../images/gateway-adyen-01.png');
        }
        &.ico-gateway-allpago {
            background-image: url('../images/gateway-allpago-01.png');
        }
        &.ico-gateway-amazon {
            background-image: url('../images/gateway-amazon-01.png');
        }
        &.ico-gateway-authorize {
            background-image: url('../images/gateway-authorize-01.png');
        }
        &.ico-gateway-braintree {
            background-image: url('../images/gateway-braintree-01.png');
        }
        &.ico-gateway-cybersource {
            background-image: url('../images/gateway-cybersource-01.png');
        }
        &.ico-gateway-stripe {
            background-image: url('../images/gateway-stripe-01.png');
        }
    }
    &.ico-gateway-logo-side {
      height: auto;
      width: auto;
      max-width: 160px;
    }
    &.ico-google{
      // border-right: 1px solid $blue-200;
      display: flex;
      height: 50px;
      left: 0;
      position: absolute;
      width: 50px;
      & svg {
          height: auto;
          margin: auto;
          width: 16px;
      }
    }
    &.ico-isologo-login {
      height: 40px;
      width: auto;
      @include breakpoint('sm') { height: 50px; }
    }
    &.ico-notifications {
        background-color: $blue-grey-50;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        & svg {
            height: 16px;
            width: 16px;
        }
    }
    &.ico-spanel-close {
        color: $white;
    }
    &.ico-spanel-edit {
        color: $white;
    }
    &.ico-sidenav {
      & .ico-path {
        stroke: $grey-900;
      }
    }
    &.ico-status {
      &.ico-status-archived {
        color: $grey-900;
      }
      &.ico-status-attention {
        color: $amber-A700;
      }
      &.ico-status-danger {
        color: $red-A400;
      }
      &.ico-status-success {
        color: $green-A400;
      }
    }
    &.ico-size {
        &.ico-4xs {
            height: 6px;
            width: 6px;
        }
        &.ico-3xs {
            height: 10px;
            width: 10px;
        }
        &.ico-2xs {
            height: 12px;
            width: 12px;
        }
        &.ico-1xs {
            height: 16px;
            width: 16px;
        }
        &.ico-xs {
            height: 20px;
            width: 20px;
        }
        &.ico-sm {
            height: 24px;
            width: 24px;
        }
        &.ico-std {
            height: 32px;
            width: 32px;
        }
        &.ico-md {
            height: 40px;
            width: 40px;
        }
        &.ico-lg {
            height: 60px;
            width: 60px;
        }
        &.ico-92 {
            height: 92px;
            width: 92px;
        }
    }
    &.ico-tooltip {
        display: none;
        @include breakpoint('md') {
            display: flex;
            margin: auto;
            margin-left: 10px;
        }
    }
    &.ico--is--disabled {
        color: $grey-300;
        pointer-events: none;
        & svg {
            fill: $grey-300;
        }
    }
    &.logo {
      &.logo-integration {
        height: 50%;
        max-height: 40px;
        max-width: 180px;
        width: 100%;
      }
    }
    &.logo-gateway {
        height: 50%;
        max-height: 35px;
        max-width: 125px;
        width: 100%;
    }

    &.disabled {
        opacity: .35;
    }
  }
}
.logo-usaepay {
  width: 140px;
}
.mat-icon-button {
    &.custom-mat-icon-button {
        display: flex;
        & .mat-button-wrapper {
            line-height: initial;
            margin: auto;
        }
        &.button-add {
            background-color: $blue-A400;
            height: 45px;
            width: 45px;
            & svg {
                fill: $white;
            }
        }
        &.button-add-rule {
            & .mat-button-wrapper{
                align-items: center;
                display: flex;
                justify-content: center;
                & svg {
                    color: $blue-A400;
                    fill: $blue-A400;
                }
            }
        }
        &.button-edit {
            height: 50px;
            width: 50px;
        }
        &.button-expander {
          svg {
            transform: rotate(0);
          }
          &.is-active {
            svg {
              transform: rotate(180deg);
            }
          }
        }
        &.button-search {
            height: 40px;
            width: 40px;
        }
    }
}

.mat-list-base {
  & .mat-list-item {
    & .mat-line {
      &.label-card {
        display: flex;
      }
    }
  }
}

.mat-list {
  &.custom-mat-list {
    & .custom-mat-list-item {
        height: auto;
        padding: 0;
        transition: background-color .3s ease-in-out;
        & .label-primary, & .label-link {
            font-size: .9375em;
        }
        & .label-secondary {
            font-size: .875em;
            font-weight: 300;
        }
        & .mat-line {
            &.nowrapping {
                white-space: normal;
            }
        }
        & .mat-list-item-content {
            padding: 0;
        }
        & .ico-prefix {
            margin: auto;
            margin-left: 0;
            margin-right: 0;
        }
        & .ico-suffix {
            margin: auto;
            margin-right: 0;
        }
        &.header {
            border-bottom: 1px solid $grey-200;
        }
        &.hover--is--disabled {
          &:hover {
              background-color: transparent;
          }
        }
        &:hover {
            background-color: $blue-grey-50;
        }
        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
    &.list-account-prehead {
        border-bottom: 1px solid $blue-50;
        background-color: $white;
        display: inline-flex;
        height: 64px;
        padding: 0;
        position: relative;
        width: 100%;
        & .custom-mat-list-item {
            height: 100%;
            &.nav-option-back {
                margin-left: 5px;
                z-index: 1;
            }
            &.nav-option-logo {
                margin: auto;
                position: absolute;
                width: 100%;
                z-index: 0;
                & .ico-isologo-top {
                    display: contents;
                }
            }
        }
    }
    &.list-account-subnav {
        background-color: $white;
        display: inline-flex;
        height: 64px;
        margin: auto;
        max-width: 768px;
        padding: 0 15px;
        position: relative;
        width: 100%;
        @include breakpoint('sm') { padding: 0 30px; }
        @include breakpoint('lg') { display: none; }
        & .custom-mat-list-item {
            height: 100%;
            &.nav-option {
                font-size: $body-1-size;
                margin: auto;
                margin-left: 5px;
                margin-right: 15px;
                @include breakpoint('sm') { margin-right: 30px; }
            }
            &.nav-option-profile {
                margin-left: auto;
            }
            &.nav-option-location {
                margin-right: 0;
            }
            &.nav-option-title {
                font-family: $font-condensed;
                font-weight: 600;
            }
        }
    }
    &.list-entities {
        padding: 0;
        & .custom-mat-list-item {
            border-bottom: 1px dotted $grey-300;
            height: 60px;
            &:first-child {
                border-top: 1px dotted $grey-300;
            }
            & .label-entity {
                font-family: $font-semi-condensed;
                //font-size: 1em;
                font-weight: 400;
            }
            & .mat-list-item-content {
                padding: 0 20px;
            }
        }
    }
    &.list-features {
        padding: 0;
        @include breakpoint ('sm') { margin-left: 50px; }
        & .mat-list-item {
            &.mat-2-line {
                height: auto;
            }
            & .mat-line {
                &.nowrapping {
                    white-space: normal;
                }
            }
            & .title {
                font-weight: 400;
                margin-bottom: 5px
            }
            & .subtitle {
                font-weight: 300;
            }
        }
    }
    &.list-filter-rule-options {
        border: 1px solid $grey-200;
        max-height: 230px;
        margin-bottom: 24px;
        overflow: auto;
        padding: 8px;
        width: 100%;
    }
    &.list-form-section {
        padding: 0;
        & .mat-list-item {
            display: flex;
            flex-direction: row;
            height: auto;
            min-height: 48px;
            & .mat-list-item-content {
                margin: auto;
                margin-left: 0;
                padding: 0;
                & .ico-row {
                    margin: auto;
                    margin-left: 0;
                    margin-right: 10px;
                    min-width: 24px;
                }
            }
        }
    }
    &.list-grid-std {
      background-color: $white;
      padding: 0;
      @include breakpoint('md'){
          border-radius: 0;
          box-shadow: none;
      }
      & .custom-mat-list-item {
          &.grid-row{
              background-color: $white;
              border-bottom: 1px dotted $grey-300;
              height: auto;
              padding: 0;
              transition: background-color .3s ease-in-out;
              .grid-row-panel {
                background-color: $grey-50;
                width: 100%;
                .row-wrapper {
                  padding: 0 2em 3em;
                }
              }
              .ico-next {
                  height: 50px;
                  width: 50px;
                  & svg {
                      height: 12px;
                      width: 12px;
                  }
              }
              .label-header {
                  background-color: none;
                  border: none;
                  font-size: .6875em;
                  text-transform: uppercase;
              }
              .label-mobile {
                align-items: center;
                display: flex;
                color: $grey-600;
                margin: auto;
                margin-right: 5px;
                min-height: 25px;
                width: 100%;
                @include breakpoint('md'){
                  display: none;
                }
                & span {
                  margin: auto;
                  margin-right: 0;
                }
              }
              .label-primary {
                  color: $grey-900;
                  &.label-link {
                    color: inherit;
                    cursor: pointer;
                  }
              }
              .mat-list-item-content {
                  align-items: center;
                  display: flex;
                  flex: 1;
                  flex-direction: row;
                  padding-bottom: 0;
                  padding-top: 0;
                  @include breakpoint('md'){
                      flex-direction: row;
                      padding: 0;
                  }
                  & .cell-wrapper {
                      display: flex;
                      flex-direction: column;
                      min-height: 60px;
                      padding: 1em;
                      @include breakpoint('md'){
                          align-items: center;
                          flex-direction: row;
                          justify-content: space-between;
                          padding: 0;
                      }
                      & .cell {
                        width: 100%;
                        margin: auto 0;
                        @include breakpoint('md'){
                            align-items: center;
                            display: flex;
                            margin: inherit;
                            padding: 0;
                        }
                        .badge {
                            background-color: $grey-50;
                            border-color: $grey-300;
                            color: $grey-500;
                            margin-left: 1em;
                        }
                        .cell-container {
                          align-items: center;
                          display: flex;
                        }
                        .cell-inner {
                          display: flex;
                          flex-direction: column;
                          width: 100%;
                        }
                        .cell-inner-with-status {
                          width: calc(100% - 20px);
                        }
                        .status {
                          margin: auto;
                          margin-left: 0;
                          margin-right: 0;
                        }
                      }
                      &.col-actions {
                          align-items: center;
                          margin: auto;
                          margin-right: 0;
                          max-width: 60px;
                          padding: 0;
                          justify-content: center;
                          width: 60px;
                          @include breakpoint('md') {
                            margin: auto;
                          }
                          @include breakpoint('lg') {
                            max-width: 120px;
                            width: 120px;
                          }
                      }
                      &.col-bulk {
                          display: none;
                          @include breakpoint('md'){
                              display: flex;
                              flex: 0 0;
                              flex-basis: 32px;
                              height: 100%;
                              max-width: 48px;
                              min-height: 60px;
                              position: relative;
                              transition: flex-basis .33s ease-in-out;
                              white-space: nowrap;
                          }
                          & .bulk-pulse-indicator {
                              background-color: $blue-grey-100;
                              display: flex;
                              min-height: 60px;
                              height: 100%;
                              overflow: hidden;
                              position: relative;
                              transition: width 0.1s ease-in;
                              width: 8px;
                              will-change: width;
                              & .bulk-pulse-inner {
                                  align-items: center;
                                  display: flex;
                                  flex-shrink: 0;
                                  justify-content: center;
                                  width: 40px;
                                  & .custom-mat-checkbox {
                                      opacity: 0;
                                      transition: opacity .5s ease-in-out;
                                      & .mat-checkbox-layout {
                                          & .mat-checkbox-inner-container {
                                              & .mat-checkbox-ripple {
                                                  display: none;
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                          &:hover{
                              @include breakpoint('md'){
                                  flex-basis: 48px;
                              }
                              & .bulk-pulse-indicator {
                                  width: 40px;
                                  & .bulk-pulse-inner {
                                      & .custom-mat-checkbox {
                                          opacity: 1;
                                      }
                                  }
                              }
                          }
                          &.is--visible {
                              @include breakpoint('md'){
                                  flex-basis: 48px;
                              }
                              & .bulk-pulse-indicator {
                                  width: 40px;
                                  & .bulk-pulse-inner {
                                      & .custom-mat-checkbox {
                                          opacity: 1;
                                      }
                                  }
                              }
                          }
                      }
                      &.col-expansion {
                        align-items: center;
                        display: flex;
                        height: 100%;
                        justify-content: center;
                        max-width: 60px;
                        min-height: 60px;
                        position: relative;
                        transition: flex-basis .33s ease-in-out;
                        white-space: nowrap;
                        width: 60px;
                      }
                      &.col-first{
                        @include breakpoint('md'){
                            min-width: 210px;
                        }
                        &.no--bulk--actions {
                          padding: 0 1em;
                        }
                      }
                      &.col-info {
                        display: none;
                        @include breakpoint('md'){
                            display: flex;
                            overflow: auto;
                            width: 100%;
                        }
                        & .cell {
                          &.col-address {
                              @include breakpoint('md'){
                                  min-width: 300px;
                                  max-width: 420px;
                              }
                          }
                          &.col-auto-collection {
                              @include breakpoint('md'){
                                  max-width: 120px;
                              }
                          }
                          &.col-date {
                            @include breakpoint('md'){
                              display: flex;
                              max-width: 130px;
                            }
                          }
                          &.col-payment-method {
                              @include breakpoint('md'){
                                  min-width: 160px;
                                  max-width: 210px;
                              }
                              & .custom-mat-icon {
                                  margin: auto .5em auto 0;
                              }
                          }
                          &.col-status {
                            @include breakpoint('md'){
                              max-width: 180px;
                            }
                          }
                          &.col-tax-region {
                            .label {
                              padding: 0 8px;
                            }
                          }
                        }
                      }
                  }
                  & .row-inner {
                    display: flex;
                  }
                  & .row-wrapper {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    & .item-block {
                      display: none;
                      @include breakpoint('md'){
                        display: flex;
                        flex-direction: row;
                        margin: 1em 0 0;
                      }
                      & .info-block-title {
                        color: $grey-500;
                        font-size: 12px;
                        font-weight: 300;
                        text-transform: uppercase;
                      }
                    }
                    & .panel-section {
                      display: flex;
                      flex-direction: column;
                      @include breakpoint('md'){
                        flex-direction: row;
                      }
                      & .panel-block {
                        flex: 1;
                        font-size: inherit;
                        padding: 1em;
                        @include breakpoint('md'){
                          flex: 3;
                        }
                        & .info-group {
                          display: flex;
                          flex-direction: column;
                        }
                        & .panel-block-title {
                          & .label-title {
                            font-size: 11px;
                            letter-spacing: .45px;
                          }
                        }
                      }
                    }
                  }
              }
              .mat-checkbox {
                  &.custom-mat-checkbox {
                      & .mat-checkbox-layout {
                          & .mat-checkbox-inner-container {
                              height: 18px;
                              width: 18px;
                          }
                      }
                  }
              }
              .show--only-mobile {
                  @include breakpoint('md'){
                      display: none;
                  }
              }
              &.grid-row-footer-actions {
                border-bottom: 0 none;
                display: flex;
                flex-direction: row;
                min-height: 64px;
              }
              &.grid-row-header {
                display: none;
                @include breakpoint('md'){
                  background-color: $grey-50;
                  border-bottom: 1px solid $grey-200;
                  display: flex;
                }
                .cell {
                    display: flex;
                }
                &:hover {
                    background-color: $grey-50;;
                }
              }
              &.hover--is--disabled {
                &:hover {
                  background-color: inherit;
                }
              }
              &.row--selected {
                background-color: $grey-50;
              }
              &:hover {
                  background-color: $grey-50;
              }
          }
      }
      &.list-api-keys {
        & .custom-mat-list-item {
          &.grid-row {
            & .mat-list-item-content {
              & .cell-wrapper {
                flex: 2;
                & .cell {
                  padding: 0 .5em;
                }
                &.col-first {
                  max-width: 150px;
                }
                &.col-info {
                  & .cell {
                    flex: 3;
                  }
                  & .col-key {
                    justify-content: space-between;
                    & .label-token {
                      overflow: hidden;
                      width: 220px;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                    }
                  }
                  & .col-switch-status {
                    justify-content: center;
                    max-width: 120px;
                  }
                  & .col-user {
                    max-width: 220px;
                  }
                }
              }
            }
          }
        }
      }
      &.list-avalara-transactions {
        .custom-mat-list-item {
          &.grid-row {
            .mat-list-item-content {
              flex-wrap: wrap;
              .cell-wrapper {
                &.col-info {
                  .cell {
                    flex: 8;
                    flex-grow: 1;
                    flex-shrink: 1;
                    &.col-transaction {
                      padding: 0 .5em;
                    }
                    &.col-amount, &.col-not-taxed, &.col-taxable, &.col-tax, &.col-subscription {
                      max-width: 150px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.list-billing-operations, &.list-payment-operations {
        .custom-mat-list-item {
          &.grid-row {
            .mat-list-item-content {
              flex-wrap: wrap;
              .cell-wrapper {
                flex: 2;
                .cell {
                  padding: 0 .5em;
                }
                &.col-first {
                  flex: 1;
                  @include breakpoint('sm'){
                    flex: 3;
                    flex-grow: 1;
                  }
                }
                &.col-info {
                  @include breakpoint('sm'){
                    flex: 3;
                    flex-grow: 2;
                  }
                  .cell {
                    flex: 5;
                  }
                }
              }
            }
          }
        }
        &.list-next-bill-run, &.list-next-payment-run {
          .custom-mat-list-item {
            &.grid-row {
              .mat-list-item-content {
                flex-wrap: wrap;
                .cell-wrapper {
                  &.col-first {
                    flex: 1;
                    @include breakpoint('sm'){
                      flex: 3;
                      flex-grow: 1;
                    }
                  }
                  &.col-info {
                    .cell {
                      flex: 3;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.list-bigcommerce-orders {
        .custom-mat-list-item {
          &.grid-row {
            .mat-list-item-content {
              flex-wrap: wrap;
              .cell-wrapper {
                &.col-first {
                  flex: 1;
                  @include breakpoint('sm'){
                    flex: 2;
                    flex-grow: 1;
                  }
                  .col-external-invoices-info {
                    display: flex;
                    .cell-inner {
                      margin: auto;
                      margin-left: 0;
                    }
                  }
                  .col-external-invoices-status {
                    .cell-inner-ico {
                      .custom-mat-icon {
                        margin: 1em;
                      }
                    }
                  }
                }
                &.col-info {
                  @include breakpoint('sm'){
                    flex: 2;
                    flex-grow: 3;
                  }
                  .cell {
                    flex: 4;
                    flex-grow: 1;
                    flex-shrink: 1;
                    &.col-customer, &.col-order {
                      flex-grow: 2;
                    }
                  }
                }
                &.col-panel {
                  min-height: 0;
                  flex-basis: 100%;
                //   max-height: 0;
                //   overflow: hidden;
                }
              }
            }
          }
        }
      }
      &.list-company-currency {
          display: flex;
          flex-direction: column;
          padding: 0;
          & .custom-mat-list-item {
              display: flex;
              flex-direction: column;
              height: auto;
              margin: auto;
              min-height: 60px;
              padding: 0;
              & .mat-list-item-content {
                  align-items: normal;
                  padding: 1em;
                  flex-direction: row;
                  & .row-wrapper {
                      display: flex;
                      flex: 1;
                      flex-direction: row;
                      & .currency-info {
                          align-items: center;
                          display: flex;
                          & .label-currency-symbol {
                              margin: auto .5em;
                          }
                      }
                      & .currency-rate {
                          display: none;
                          @include breakpoint('sm'){
                              align-items: center;
                              color: $grey-600;
                              display: flex;
                              font-size: .75em;
                              font-style: italic;
                              margin-left: 1em;
                              & .badge {
                                  margin-left: 1em;
                              }
                          }
                      }
                  }
              }
          }
      }
      &.list-company-gateways {
          display: flex;
          flex-direction: column;
          padding: 0;
          & .custom-mat-list-item {
              display: flex;
              flex-direction: column;
              height: auto;
              margin: auto;
              min-height: 60px;
              padding: 0;
              & .mat-list-item-content {
                  align-items: normal;
                  padding: 1em;
                  flex-direction: row;
                  & .row-wrapper {
                      display: flex;
                      flex: 1;
                      flex-direction: column;
                      & .gateway-info {
                          flex: 2 1 50%;
                          display: flex;
                          flex-direction: column;
                          margin: auto;
                      }
                      & .gateway-info-cards {
                          white-space: normal;
                          & label {
                            span {
                              &:before {
                                  background-color: $grey-400;
                                  border-radius: 2px;
                                  display: inline-block;
                                  content: '';
                                  margin: 0 6px;
                                  height: 4px;
                                  vertical-align: middle;
                                  width: 4px;
                              }
                              &.first {
                                  &:before {
                                      display: none;
                                  }
                              }
                            }
                          }
                      }
                      & .gateway-info-title {
                          color: $grey-600;
                          font-size: .75em;
                          margin-bottom: 4px;
                          text-transform: uppercase;
                      }
                      & .gateway-name {
                          align-items: center;
                          display: flex;
                          height: 40px;
                      }
                  }
                  & .item-block {
                      display: none;
                      @include breakpoint('md'){
                          display: flex;
                          flex-direction: row;
                          margin: 1em 0 0;
                      }
                  }
              }
          }
      }
      &.list-company-tax-regions {
        .custom-mat-list-item {
          &.grid-row {
            .mat-list-item-content {
              .cell-wrapper {
                &.col-first {
                  .cell {
                    &.col-tax-region {
                      padding: 0 8px;
                    }
                  }
                }
                &.col-info {
                  .cell {
                    flex: 2;
                  }
                }
              }
            }
          }
        }
      }
      &.list-company-integration {
        display: flex;
        flex-direction: column;
        padding: 0;
        & .custom-mat-list-item {
          display: inline-flex;
          height: auto;
          margin: auto;
          min-height: 60px;
          padding: 0;
          & .mat-list-item-content {
            align-items: normal;
            padding: 1em;
            & .row-wrapper {
              display: flex;
              flex: 1;
              flex-direction: column;
              & .cell {
                &.col-status {
                  display: flex;
                  justify-content: center;
                  min-width: 60px;
                }
              }
              & .integration-info {
                display: flex;
                flex-direction: column;
                margin: 0;
                margin-bottom: 1em;
                @include breakpoint('sm'){
                  margin-bottom: 0;
                }
                & .integration-info-title {
                  color: $grey-600;
                  font-size: 11px;
                  margin-bottom: 4px;
                  text-transform: uppercase;
                }
              }
              & .integration-name {
                align-items: center;
                display: flex;
                height: 40px;
              }
              & .item-block {
                display: flex;
                flex-direction: column;
                margin: 0;
                padding: 0;
                @include breakpoint('sm'){
                  border-top: 1px dotted $grey-300;
                  flex-direction: row;
                  justify-content: space-between;
                  padding: 1em 0;
                }
              }
              & .row-inner {
                align-items: center;
                justify-content: space-between;
                margin-bottom: 1em;
              }
            }
          }
        }
      }
      &.list-currencies {
          display: flex;
          flex-direction: column;
          padding: 0;
          width: 100%;
          & .custom-mat-list-item {
              display: inline-flex;
              flex: 3;
              height: auto;
              margin: auto;
              padding: 1em;
              & p {
                  &.item-gateway {
                      margin-top: 5px;
                  }
              }
              &.item-actions {
                  max-width: 40px;
              }
              &.item-currency {
                  & .mat-list-item-content {
                      align-items: baseline;
                      flex-direction: column;
                  }
              }
              &.item-currency-code {
                  display: none;
                  @include breakpoint('sm') { display: inline-flex; max-width: 120px; }
                  & .mat-list-item-content {
                      margin: auto;
                  }
              }
          }
      }
      &.list-customers {
        .custom-mat-list-item {
          &.grid-row {
            .mat-list-item-content {
              .cell-wrapper {
                &.col-actions {
                  flex: 2;
                  @include breakpoint('sm'){
                    flex: 4;
                  }
                }
                &.col-first {
                  flex: 2;
                  @include breakpoint('sm'){
                    flex: 4;
                    flex-grow: 1;
                  }
                }
                &.col-info {
                  @include breakpoint('sm'){
                    flex: 4;
                    flex-grow: 2;
                  }
                  .cell {
                    flex: 7;
                    flex-grow: 1;
                    flex-shrink: 1;
                    &.col-balance-due {
                      padding: 0 .5em;
                      .cell-inner {
                        margin: auto;
                        margin-left: 0;
                        margin-right: .5em;
                      }
                    }
                    &.col-last-invoice {
                      padding: 0 .5em;
                    }
                    &.col-payments, &.col-address-book, &.col-subscriptions  {
                      justify-content: center;
                    }
                    &.col-subscriptions {
                      padding: 0 .5em;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.list-customer-activities {
          & .custom-mat-list-item {
              &.grid-row {
                  & .mat-list-item-content {
                      padding: 0 1em;
                      & .col-info {
                          flex-direction: row;
                          justify-content: space-between;
                          & .cell {
                              flex: 2 100%;
                          }
                          & .col-created-on {
                              display: none;
                              @include breakpoint('md'){
                                  justify-content: flex-end;
                                  display: flex;
                              }
                          }
                          & .col-description {
                              align-items: normal;
                              display: flex;
                              flex-direction: column;
                          }
                      }
                  }
              }
          }
      }
      &.list-customer-addressbook {
          & .grid-row {
              & .mat-list-item-content {
                  align-items: flex-start;
                  padding: 16px;
              }
              & .address {
                  & p {
                      & span {
                          margin-right: 4px;
                      }
                  }
              }
              & .address-name {
                  line-height: 40px;
              }
              & .address-headline {
                  font-size: .75em;
                  font-weight: 300;
                  margin-bottom: 8px;
                  text-transform: uppercase;
              }
              & .address-info {
                  display: flex;
                  flex-direction: column;
                  @include breakpoint('md'){
                      align-items: initial;
                      flex-direction: row;
                      justify-content: space-between;
                  }
              }
              & .address-section {
                  font-size: .875em;
                  padding: 8px 0;
                  width: 100%;
                  @include breakpoint('md'){
                      margin-top: 0;
                      padding: 0;
                      width: 33%;
                  }
              }
          }
      }
      &.list-customer-charges {
        .custom-mat-list-item {
          &.grid-row {
            .mat-list-item-content {
              .cell-wrapper {
                &.col-actions {
                  flex: 2;
                  @include breakpoint('sm'){
                    flex: 3;
                  }
                }
                &.col-first {
                  flex: 2;
                  @include breakpoint('sm'){
                    flex: 3;
                    flex-grow: 1;
                  }
                  .cell {
                    padding: 0 1em;
                  }
                }
                &.col-info {
                  @include breakpoint('sm'){
                    flex: 3;
                    flex-grow: 3;
                  }
                  .cell {
                    flex: 5;
                    flex-grow: 1;
                    flex-shrink: 1;
                  }
                }
              }
            }
          }
        }
      }
      &.list-customer-invoices {
        & .custom-mat-list-item {
          &.grid-row {
            & .mat-list-item-content {
              padding: 0;
              & .col-actions {
                flex: 2 60px;
                @include breakpoint('sm'){
                  flex: 3 60px;
                }
              }
              & .col-first {
                flex: 2;
                @include breakpoint('sm'){
                  flex: 3;
                  max-width: 25%;
                }
                & .cell {
                    &.col-invoice {
                      @include breakpoint('md'){
                        padding: 0 1em;
                      }
                    }
                }
              }
              & .col-info {
                flex: 3;
                flex-direction: row;
                justify-content: space-between;
                & .cell {
                    flex: 4;
                    &.col-created-on {
                        display: none;
                        @include breakpoint('md'){
                            display: flex;
                        }
                    }
                    &.col-due-to {
                        display: none;
                        @include breakpoint('md'){
                            display: flex;
                        }
                    }
                    &.col-status {
                        display: none;
                        @include breakpoint('md'){
                            display: flex;
                            max-width: 25%;
                        }
                    }
                    &.col-total {
                        justify-content: flex-end;
                        text-align: right;
                    }
                }
              }
            }
            &.grid-row-header {
              & .mat-list-item-content {
                align-items: center;
                & .cell-wrapper {
                  min-height: 40px;
                }
              }
            }
          }
        }
      }
      &.list-customers-subscription {
        & .custom-mat-list-item {
          &.grid-row {
            & .mat-list-item-content {
              & .grid-row-panel {
                & .panel-section {
                  @include breakpoint('md'){
                    padding: 1.25em 0;
                  }
                  & .panel-block {
                    @include breakpoint('sm'){
                      display: flex;
                      flex-direction: row;
                      max-width: 30%;
                    }
                    & .col-side {
                      display: flex;
                      flex-direction: column;
                      flex: 2;
                      &.side-left {
                        @include breakpoint('sm'){
                          align-items: flex-end;
                          max-width: 48px;
                          padding-right: .5em;
                        }
                      }
                      &.side-right {
                        border-left: 1px solid $grey-200;
                        padding-left: .5em;
                      }
                      & .col-section {
                        margin-top: .25em;
                        & .col-row {
                          align-items: center;
                          display: flex;
                          height: 25px;
                          position: relative;
                          & .custom-mat-icon {
                            height: 25px;
                            left: -30px;
                            position: absolute;
                          }
                        }
                      }
                    }
                    & .skeleton-loader {
                      width: 100%;
                    }
                    &.panel-summary {
                      max-width: 100%;
                      padding: 1em 0 1em 1em;
                      width: 100%;
                      & .customer-subscription-detail-container {
                        padding: 0;
                        padding-bottom: 1em;
                        padding-left: .5em;
                        padding-right: 1em;
                        & .row-item-detail {
                          display: flex;
                          justify-content: space-between;
                          margin-top: .25em;
                          padding: 0;
                          & .line-item {
                            display: flex;
                            flex-direction: column;
                          }
                          &:first-child {
                            margin-top: 0;
                          }
                        }
                      }
                      & .customer-subscription-total-container {
                        background-color: $grey-100;
                        border: 1px solid $grey-200;
                        border-left: 0 none;
                        padding: 1em 1em 1em .5em;
                        & .row-customers-subscription-total {
                          align-items: center;
                          display: flex;
                          justify-content: space-between;
                          min-height: 25px;
                          padding: 0;
                          &.row-grand-total {
                            padding-top: 1em;
                          }
                        }
                      }
                      & .col-side {
                        &.side-right {
                          padding-left: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.list-customer-transactions {
        & .custom-mat-list-item {
          &.grid-row {
            & .mat-list-item-content {
              & .col-actions {
                flex: 3 60px;
                @include breakpoint('sm'){
                  flex: 3 60px;
                }
              }
              & .col-first {
                flex: 3;
                @include breakpoint('sm'){
                  max-width: 210px;
                  min-width: inherit;
                }
                & .cell {
                  &.col-date {
                    align-items: flex-start;
                    flex-direction: column;
                    @include breakpoint('md'){
                      padding: 0 1em;
                    }
                  }
                }
              }
              & .col-info {
                flex: 3;
                flex-direction: row;
                justify-content: space-between;
                & .cell {
                  flex: 7;
                  &.col-kind {
                    align-items: center;
                    max-width: 60px;
                  }
                  &.col-payment-method {
                    max-width: inherit;
                    min-width: inherit;
                  }
                  &.col-type {
                    align-items: flex-start;
                    flex-direction: column;
                    max-width: 90px;
                  }
                  &.col-total {
                      justify-content: flex-end;
                      padding: 0 1em;
                      text-align: right;
                  }
                }
              }
            }
            &.grid-row-header {
              & .mat-list-item-content {
                align-items: center;
                & .cell-wrapper {
                  min-height: 40px;
                }
              }
            }
          }
        }
      }
      &.list-emails {
          padding: 0;
          & .custom-mat-list-item {
            height: auto;
            & .label-title {
              align-items: center;
              display: flex;
              height: 40px;
              white-space: normal;
              @include breakpoint('sm'){ height: auto; }
            }
            & .label-subtitle {
              color: $grey-700;
              max-width: 90%;
              white-space: normal;
            }
            & .mat-list-item-content {
              align-items: normal;
              padding: 1em;
              @include breakpoint('sm'){
                  align-items: center;
              }
            }
          }
      }
      &.list-external-invoices, &.list-customers-subscription {
        .custom-mat-list-item {
          &.grid-row {
            .mat-list-item-content {
              flex-direction: column;
              .grid-row-inner {
                align-items: center;
                display: flex;
                flex: 1;
                flex-direction: row;
                padding-bottom: 0;
                padding-top: 0;
                width: 100%;
                @include breakpoint('md'){
                    flex-direction: row;
                    padding: 0;
                }
                .cell-wrapper {
                  padding: 0;
                  &.col-first {
                    flex: 2;
                    @include breakpoint('sm'){
                      flex: 4;
                      flex-grow: 1;
                    }
                    .col-subscription-info {
                      justify-content: space-between;
                      margin: auto;
                      margin-left: .5em;
                    }
                  }
                  &.col-info {
                    & .cell {
                      flex: 7;
                      flex-grow: 1;
                      flex-shrink: 1;
                      &.col-date {
                        max-width: 130px;
                      }
                      &.col-payment {
                        max-width: 180px;
                      }
                      &.col-total {
                        justify-content: flex-start;
                        max-width: 180px;
                      }
                    }
                  }
                }
              }
              .grid-row-panel {
                background-color: $grey-50;
                width: 100%;
                .row-wrapper {
                  padding: 0 2em 3em;
                }
              }
            }
          }
        }
      }
      &.list-external-invoices {
        .custom-mat-list-item {
          &.grid-row {
            .mat-list-item-content {
              .grid-row-inner {
                .cell-wrapper {
                  .cell {
                    padding: 0 .5em;
                  }
                  &.col-info {
                    .cell {
                      flex: 4;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.list-external-product-variants {
        max-height: 420px;
        overflow: scroll;
        position: relative;
        width: 100%;
        .custom-mat-list-item {
          &.grid-row {
            .mat-list-item-content {
              .cell-wrapper {
                min-height: 60px;
                .cell {
                  flex: 5;
                  padding: 0 .5em;
                  &.cell-actions, &.cell-image {
                    align-items: center;
                    justify-content: center;
                    max-width: 60px;
                  }
                  &.cell-image {
                    max-width: 80px;
                    svg {
                      height: auto;
                      width: 24px;
                    }
                  }
                  &.cell-price {
                    align-items: flex-end;
                    justify-content: center;
                    max-width: 90px;
                  }
                }
              }
            }
            &.grid-row-first {
              padding-top: 45px;
            }
            &.grid-row-header {
              border-top: 1px solid #eee;
              padding-top: 0;
              position: fixed;
              z-index: 1;
              .mat-list-item-content {
                .cell-wrapper {
                  min-height: 45px;
                }
              }
            }
          }
        }
      }
      &.list-events {
        .custom-mat-list-item {
          &.grid-row {
            .mat-list-item-content {
              .cell-wrapper {
                flex: 3;
                &.col-first {
                  flex: 2;
                  @include breakpoint('sm'){
                    flex: 3;
                    flex-grow: 1;
                  }
                  .col-date {
                    padding: 0 1em;
                  }
                }
                &.col-info {
                  .cell {
                    flex: 3;
                    flex-grow: 1;
                    flex-shrink: 1;
                    &.col-source {
                      max-width: 150px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.list-gateway {
          padding: 0;
          @include breakpoint('md') {
              display: grid;
              grid-gap: 0;
              grid-template-columns: repeat(auto-fill, 50%);
          }
          @include breakpoint('lg') {
              grid-template-columns: repeat(auto-fill, 25%);
          }
          & .custom-mat-list-item {
              height: auto;
              & .mat-list-item-content {
                  display: flex;
                  flex-flow: column nowrap;
                  height: 140px;
                  justify-content: center;
                  padding: 1em;
                  & .grid-row-container {
                    align-items: center;
                    background-color: transparent;
                    border: 0 none;
                    cursor: pointer;
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    width: 100%;
                  }
              }
          }
      }
      &.list-imported-orders {
        $row-custom-height: 0;
        & .custom-mat-list-item {
          &.grid-row {
            & .mat-list-item-content {
              align-items: inherit;
              & .cell-wrapper {
                align-items: flex-start;
                padding: 1.25em 0;
                & .cell {
                  & .cell-inner {
                    margin-top: .5em;
                    &:first-child {
                      margin-top: 0;
                    }
                  }
                }
                &.col-bulk {
                  padding: 0;
                }
                &.col-first {
                  min-width: 150px;
                  & .col-order-info {
                    align-items: flex-start;
                    display: flex;
                    flex-direction: column;
                    min-width: 0;
                    max-width: 100%;
                  }
                }
                &.col-info {
                  & .cell {
                    flex: 4;
                    flex-grow: 1;
                    flex-shrink: 1;
                    &.col-address {
                      align-items: flex-start;
                      display: flex;
                      flex-direction: column;
                      min-width: 0;
                      & .address-info {
                        align-items: baseline;
                        display: flex;
                        margin-top: .5em;
                        & .label-title {
                          width: 45px;
                          margin-right: .5em;
                        }
                        &:first-child {
                          margin-top: 0;
                        }
                      }
                    }
                    &.col-assign-to {
                      align-items: flex-start;
                      display: flex;
                      flex-direction: column;
                    }
                    &.col-customer-info {
                      align-items: normal;
                      display: flex;
                      flex-direction: column;
                    }
                    &.col-status {
                      align-items: center;
                      height: 100%;
                      justify-content: center;
                      max-width: 80px;
                    }
                  }
                }
              }
              & .grid-row-inner {
                display: flex;
                flex: 4;
                flex-direction: row;
              }
            }
            &.grid-row-header {
              min-height: inherit;
              & .mat-list-item-content {
                min-height: inherit;
                & .cell-wrapper {
                  align-items: center;
                }
              }
            }
          }
        }
        & .skeleton-loader-grid-body {
          & .custom-mat-list-item {
            &.grid-row {
              & .mat-list-item-content {
                align-items: inherit;
                min-height: $row-custom-height;
                & .cell-wrapper {
                  &.col-bulk {
                    min-height: $row-custom-height;
                    & .bulk-pulse-indicator {
                      min-height: $row-custom-height;
                    }
                  }
                }
              }
            }
          }
        }
        & .skeleton-loader-grid-header {
          & .custom-mat-list-item {
            &.grid-row {
              & .mat-list-item-content {
                align-items: inherit;
                min-height: 40px;
                & .cell-wrapper {
                  &.col-bulk {
                    min-height: 40px;
                    & .bulk-pulse-indicator {
                      min-height: 40px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.list-invoices {
        .custom-mat-list-item {
          &.grid-row {
            .mat-list-item-content {
              .cell-wrapper {
                &.col-actions {
                  flex: 2;
                  @include breakpoint('sm'){
                    flex: 4;
                  }
                }
                &.col-first {
                  flex: 2;
                  @include breakpoint('sm'){
                    flex: 4;
                    flex-grow: 1;
                  }
                  .cell {
                    padding: 0 .5em;
                  }
                  .col-invoice-info {
                    .cell-inner {
                      margin: auto;
                      margin-left: 0;
                    }
                    .cell-inner-ico {
                      margin: auto;
                      margin-left: 0;
                      padding: 0px 1em 0px 0.5em;
                    }
                  }
                }
                &.col-info {
                  @include breakpoint('sm'){
                    flex: 4;
                    flex-grow: 3;
                  }
                  .cell {
                    flex: 6;
                    flex-grow: 1;
                    flex-shrink: 1;
                    &.col-ship-to, &.col-transactions {
                      justify-content: center;
                    }
                    &.col-customer {
                      flex-grow: 2;
                    }
                    &.col-ship-to {
                      flex-grow: 1 15%;
                    }
                    &.col-total {
                      flex-grow: 2;
                      justify-content: flex-end;
                      .cell-inner {
                        justify-content: flex-end;
                        label {
                          text-align: right;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.list-invoices-transactions {
        .custom-mat-list-item {
          &.grid-row {
            .cell-wrapper {
              &.col-bulk {
                .bulk-pulse-indicator {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
      &.list-notifications-channels {
        background-color: transparent;
        display: flex;
        flex: 1 100%;
        flex-direction: column;
        width: 100%;
        .custom-mat-list-item {
          &.grid-row {
            background-color: transparent;
            .cell-wrapper {
              padding: 0 1em;
              @include breakpoint('sm'){
                flex: 2;
              }
              &.col-actions {
                margin: auto;
                margin-right: 0;
                min-width: 120px;
                max-width: 120px;
                text-align: center;
              }
            }
          }
        }
      }
      &.list-notifications-events {
        .custom-mat-list-item {
          &.grid-row{
            padding: 0;
            @include breakpoint('sm'){
              padding: 1em;
            }
            .mat-list-item-content {
              align-items: baseline;
              flex-direction: column;
              min-height: 3em;
              @include breakpoint('sm'){
                flex-direction: row;
              }
              .col-condition-notify {
                flex-grow: 1;
                flex-shrink: 1;
              }
              .col-condition-when {
                align-items: center;
                display: flex;
                margin-left: 0;
                margin-right: 0;
              }
              & .label-conditional {
                color: #646468;
                font-family: "SF Mono","Monaco","Andale Mono","Lucida Console","Bitstream Vera Sans Mono","Courier New",Courier,monospace;
                font-size: .875em;
                font-weight: 300;
                min-width: 70px;
                padding: 1em;
                text-align: right;
              }
              & .label-conditional-event {
                margin: auto;
                margin-left: 1em;
                margin-right: 1em;
              }
              & .label-conditional-notify {
                color: #646468;
                font-family: "SF Mono","Monaco","Andale Mono","Lucida Console","Bitstream Vera Sans Mono","Courier New",Courier,monospace;
                font-size: .875em;
                font-weight: 300;
                min-width: 70px;
                padding: 1em;
                text-align: right;
              }
            }
          }
        }
      }
      &.list-notification-inbox {
        background-color: transparent;
        border: 0 none;
        padding: 0;
        & .custom-mat-list-item {
          background-color: transparent;
          border: 0 none;
          height: auto;
          margin-bottom: .5em;
          & .mat-list-item-content {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            padding: 1em;
            @include breakpoint('md'){
              flex-direction: row;
              padding: 0;
            }
            & .col {
              flex: 1 100%;
              @include breakpoint('md'){
                flex: 2;
                padding: 1em;
              }
              &.col-actions {
                margin-top: 1em;
                order: 2;
                @include breakpoint('md'){
                  margin: 0;
                  max-width: 190px;
                  text-align: right;
                }
              }
              &.col-notification {
                order: 1;
                @include breakpoint('md'){
                  order: 0;
                }
              }
            }
          }
          &.info {
            background-color: $amber-50;
            border: 0 none;
            border-left: 4px solid $amber-100;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
          }
        }
      }
      &.list-payment-operations {
        & .custom-mat-list-item {
          &.grid-row {
            & .mat-list-item-content {
              & .cell-wrapper {
                flex: 2;
                & .cell {
                  padding: 0 .5em;
                }
                &.col-info {
                  & .cell {
                    flex: 8;
                  }
                }
              }
            }
          }
        }
      }
      &.list-paymentMethods {
          padding: 0;
          & .custom-mat-list-item {
              height: auto;
              & .card-billing-address {
                  display: flex;
                  flex-direction: column;
                  margin: 0;
              }
              & .card-default {
                  display: flex;
                  flex-direction: row;
                  margin-top: 16px;
              }
              & .card-exp-date {
                  @include breakpoint('md'){
                      margin: 0;
                  }
              }
              & .card-info-wrapper {
                  display: flex;
                  flex-direction: column;
                  @include breakpoint('md'){
                      flex-direction: row;
                  }
                  & .card-info {
                      flex: 3 100%;
                      & .card-info-row {
                          display: flex;
                          flex-direction: column;
                          margin: 0;
                          margin-bottom: .5em;
                      }
                  }
              }
              & .card-last4 {
                  align-items: center;
                  display: inline-flex;
                  height: 40px;
              }
              & .card-payment-gateway {
                  display: flex;
                  flex-direction: column;
                  margin: 0;
              }
              & .ico-card {
                  margin: auto;
                  margin-left: 0;
                  margin-right: 0;
              }
              & .ico-default {
                  fill: $green-A400;
                  margin: auto;
                  margin-left: 0;
                  margin-right: 5px;
              }
              & .ico-mask {
                  height: 6px;
                  margin: auto;
                  margin-left: 10px;
                  margin-right: 10px;
                  width: 100px;
              }
              & .label-exp-date {
                  margin: auto;
                  margin-right: 8px;
              }
              & .label-last4 {
                  margin: auto;
                  margin-left: 8px;
              }
              & .label-placeholder {
                  align-items: center;
                  display: flex;
                  flex-direction: row;
                  min-height: 40px;
              }
              & .mat-list-item-content {
                  align-items: flex-start;
                  padding: 1em;
                  @include breakpoint('md'){
                      align-items: normal;
                  }
              }
          }
      }
      &.list-products {
        .custom-mat-list-item {
          &.grid-row {
            .mat-list-item-content {
              .cell-wrapper {
                &.col-actions {
                  flex: 2;
                  @include breakpoint('sm'){
                    flex: 4;
                  }
                }
                &.col-first {
                  flex: 2;
                  @include breakpoint('sm'){
                    flex: 4;
                    flex-grow: 1;
                  }
                  .col-product-info {
                    display: flex;
                    .cell-inner {
                      margin: auto;
                      margin-left: 0;
                    }
                  }
                }
                &.col-plan {
                  display: flex;
                  flex: 4;
                  flex-grow: 0.5;
                  .cell-inner {
                    margin: auto;
                    margin-left: 0;
                  }
                }
                &.col-info {
                  @include breakpoint('sm'){
                    flex: 4;
                    flex-grow: 2;
                  }
                  .cell {
                    flex: 3;
                    flex-grow: 1;
                    flex-shrink: 1;
                    &.col-effective-dates {
                      flex-grow: 3;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.list-rateplans{
        .custom-mat-list-item {
          &.grid-row {
            .mat-list-item-content {
              flex-direction: column;
              .grid-row-inner {
                align-items: center;
                display: flex;
                flex: 1;
                flex-direction: row;
                padding-bottom: 0;
                padding-top: 0;
                width: 100%;
                @include breakpoint('md'){
                    flex-direction: row;
                    padding: 0;
                }
                .cell-wrapper {
                  &.col-actions {
                    flex: 2;
                    @include breakpoint('sm'){
                      flex: 4;
                    }
                  }
                  &.col-first {
                    flex: 2;
                    @include breakpoint('sm'){
                      flex: 4;
                      flex-grow: 1;
                    }
                    .col-rateplan-info {
                      display: flex;
                      .cell-inner {
                        margin: auto;
                        margin-left: 0;
                      }
                    }
                  }
                  &.col-info {
                    @include breakpoint('sm'){
                      flex: 4;
                      flex-grow: 2;
                    }
                    .cell {
                      flex: 5;
                      flex-grow: 1;
                      flex-shrink: 1;
                    }
                  }
                }
              }
              .grid-row-panel {
                .row-wrapper {
                  padding: 1em 3em;
                }
              }
            }
          }
        }
      }
      &.list-rateplan-charges, &.list-invoices-detail {
        .custom-mat-list-item {
          &.grid-row {
            .mat-list-item-content {
              .cell-wrapper {
                flex: 3;
                &.col-first {
                  flex-direction: row;
                  justify-content: space-between;
                  width: 100%;
                  @include breakpoint('md'){
                    align-items: flex-start;
                    flex-direction: column;
                    justify-content: center;
                  }
                  & .cell {
                    &.col-item {
                      align-items: flex-start;
                      display: flex;
                      flex-direction: column;
                      padding: 1em 0;
                      & .label-charge-frequency {
                        font-size: .875em;
                        font-weight: 300;
                        margin-top: .25em;
                      }
                      & .label-charge-tier {
                        font-size: .875em;
                        font-weight: 300;
                        margin-top: .25em;
                      }
                      & .label-charge-model {
                        font-style: italic;
                        font-size: .875em;
                        font-weight: 300;
                        margin-top: .25em;
                      }
                      & .label-item-amount {
                        font-size: .8125em;
                        font-style: italic;
                        font-weight: 300;
                        @include breakpoint('md'){
                          display: none;
                        }
                      }
                    }
                    &.col-total {
                      @include breakpoint('md'){
                        display: none;
                      }
                      & .label-item-total {
                        font-size: 1em;
                        margin: .5em 0 0;
                      }
                    }
                  }
                }
                &.col-info {
                  & .cell {
                    align-items: center;
                    flex: 3;
                    &.col-amount {
                      justify-content: flex-start;
                      max-width: 33%;
                      padding-right: .5em;
                    }
                    &.col-qty {
                      justify-content: center;
                      max-width: 55px;
                    }
                    &.col-total {
                      justify-content: flex-end;
                      padding-right: .5em;
                      max-width: 33%;
                    }
                  }
                }
              }
            }
            &.grid-row-header {
              background-color: transparent;
              font-size: .75em;
              text-transform: uppercase;
              & .mat-list-item-content {
                & .cell-wrapper {
                  &.col-info {
                    & .cell {
                      &.col-amount {
                        font-weight: 400;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.list-rateplan-charges, &.list-rateplan-charges-linked {
        background-color: $white;
        border: 1px solid $grey-300;
        padding: 0 1em;
        .custom-mat-list-item {
          &.grid-row {
            background-color: transparent;
            .mat-list-item-content {
              display: flex;
              flex-direction: row;
              .cell-wrapper {
                flex: 2;
                &.col-info {
                  .cell {
                    flex: 6;
                    flex-grow: 1;
                    .ico-alert {
                      margin: auto .5em;
                      order: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.list-rateplan-charges-linked {
        .custom-mat-list-item {
          &.grid-row {
            .mat-list-item-content {
              .cell-wrapper {
                padding: 1em 0;
                &.col-actions {
                  min-width: 120px;
                  width: auto;
                }
                &.col-info {
                  .cell {
                    flex: 3;
                    padding: 0 .5em;
                  }
                }
              }
            }
            &.grid-row-header {
              background-color: $grey-50;
            }
          }
        }
      }
      &.list-rateplan-charges {
        &.borderless {
          border: 0 none;
        }
        &.nopadding {
          padding: 0;
        }
      }

      &.list-rateplan-charge-tiers, &.list-tiers {
        background-color: transparent;
        & .custom-mat-list-item{
          &.grid-row{
            background-color: transparent;
            border: 0 none;
          }
        }
      }
      &.list-accounting-providers, &.list-shipping-classes, &.list-taxes-classes, &.list-shipping-zones-classes {
        & .custom-mat-list-item {
          &.grid-row {
            padding: 2em 0;
            & .mat-list-item-content {
              & .cell-wrapper {
                flex-direction: row;
                padding: 0 1em;
                width: 100%;
                & .cell {
                  flex: 3;
                  &.col-icon {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    max-width: 65px;
                    width: 65px;
                    @include breakpoint('md'){
                      max-width: 120px;
                      width: 120px;
                    }
                  }
                  &.col-shipping-class, &.col-tax-class {
                    align-items: baseline;
                    display: flex;
                    flex-direction: column;
                    padding: 0 1em;
                    & .label-secondary {
                      display: none;
                      @include breakpoint('md'){
                        display: inline;
                      }
                    }
                  }
                  &.col-status {
                    align-items: center;
                    display: flex;
                    justify-content: flex-end;
                    max-width: 60px;
                    width: 60px;
                    @include breakpoint('md'){
                      max-width: 80px;
                      width: 80px;
                    }
                  }
                }
              }
              & .ico-shipping-class {
                height: 48px;
                width: 48px;
                @include breakpoint('md'){
                  height: 62px;
                  width: 62px;
                }
              }
            }
            &:hover {
              background-color: transparent;
            }
            &:last-child {
              border-bottom: 0 none;
            }
          }
        }
      }
      &.list-sales-channels {
        & .custom-mat-list-item {
          &.grid-row {
            & .mat-list-item-content {
              display: flex;
              flex-direction: row;
              min-height: 120px;
              & .cell-wrapper {
                flex: 2;
                justify-content: flex-start;
                & .col-logo {
                  align-items: center;
                  display: flex;
                  justify-content: center;
                  min-width: 210px;
                  & figure {
                    &.sales-channels {
                      margin: auto;
                      & img {
                        height: auto;
                        max-width: 90px;
                        min-height: auto;
                        width: auto;
                      }
                    }
                  }
                }
                & .col-description {
                  align-items: flex-start;
                  display: flex;
                  flex-direction: column;
                  padding: 0 1em;
                }
                &.col-actions {
                  justify-content: center;
                  max-width: 240px;
                  width: 240px;
                }
              }
            }
          }
        }
        &.list-recommended {
          background-color: transparent;
          & .custom-mat-list-item {
            &.grid-row {
              background-color: transparent;
              border: 0 none;
              border-bottom: 0 none;
              & .mat-list-item-content {
                & .cell-wrapper {
                  & .col-logo {
                    & figure {
                      &.sales-channels {
                        & img {
                          height: auto;
                          max-width: 180px;
                          min-height: auto;
                          width: auto;
                        }
                      }
                    }
                  }
                }
              }
              &:hover{
                background-color: transparent;
              }
            }
          }
        }
      }
      &.list-sales-channels-activity {
        & .custom-mat-list-item {
          &.grid-row {
            & .mat-list-item-content {
              & .cell-wrapper {
                padding: 0;
                width: 100%;
                & .cell {
                  padding: 0;
                  @include breakpoint('sm'){
                    padding: 0 1em;
                  }
                }
                &.col-info {
                  & .cell {
                    flex: 4;
                  }
                }
              }
            }
          }
        }
      }
      &.list-skeleton {
        padding: 0;
        & .custom-mat-list-item {
          height: auto;
          & .label-title {
              align-items: center;
              display: flex;
              height: 40px;
              white-space: normal;
              @include breakpoint('sm'){ height: auto; }
              & .ico-isDisabled {
                  fill: $grey-500;
                  margin-left: 5px;
              }
              & .ico-isEnabled {
                  fill: $green-A400;
                  margin-left: 5px;
              }
          }
          & .label-subtitle {
              max-width: 90%;
              white-space: normal;
          }
          & .mat-list-item-content {
              align-items: inherit;
              padding: 1em;
              @include breakpoint('sm'){
                  align-items: center;
              }
          }
        }
      }
      &.list-smart-routing {
        & .custom-mat-list-item {
          &.grid-row {
            & .mat-list-item-content {
              flex-direction: column;
              min-height: 3em;
              @include breakpoint('sm'){
                flex-direction: row;
              }
              & .col-condition-currency {
                align-items: center;
                display: flex;
                flex: 1 100%;
                margin: auto;
                margin-left: 0;
                margin-top: 0;
                @include breakpoint('sm'){
                  flex: 2 100%;
                  max-width: 90px;
                }
              }
              & .col-condition-gateway {
                display: flex;
                flex: 1 100%;
                width: 100%;
                @include breakpoint('sm'){
                  flex: 2 100%;
                }
                & .col-wrapper {
                  align-items: center;
                  display: flex;
                  margin: auto;
                  margin-left: 0;
                  flex: 1 100%;
                  @include breakpoint('sm'){
                    margin-left: .5em;
                  }
                  & .actions {
                    margin: auto;
                    margin-right: 0;
                    min-width: 120px;
                    max-width: 120px;
                    text-align: center;
                  }
                  & .list-smart-routing-gateway {
                    & .custom-mat-list-item {
                      &.grid-row {
                        & .mat-list-item-content {
                          flex-direction: row;
                        }
                      }
                      &:last-child {
                        border-bottom: 0 none;
                      }
                    }
                  }
                }
              }
              & .label-conditional {
                color: #646468;
                font-family: "SF Mono","Monaco","Andale Mono","Lucida Console","Bitstream Vera Sans Mono","Courier New",Courier,monospace;
                font-size: .875em;
                font-weight: 300;
                min-width: 70px;
                padding: 1em;
                text-align: right;
              }
              & .label-gateway-default {
                margin: auto;
                margin-left: 1em;
                color: $blue-A900;
              }
              & .label-gateway-name {
                color: $grey-900;
                font-style: normal;
                font-weight: 400;
                margin: auto;
                margin-left: 0;
              }
              & .label-no-data {
                color: $grey-500;
                font-style: italic;
                font-weight: 300;
              }
            }
          }
        }
      }
      &.list-subscriptions {
        .custom-mat-list-item {
          &.grid-row {
            .mat-list-item-content {
              .cell-wrapper {
                &.col-actions {
                  flex: 2;
                  @include breakpoint('sm'){
                    flex: 4;
                  }
                }
                &.col-first {
                  flex: 2;
                  @include breakpoint('sm'){
                    flex: 4;
                    flex-grow: 1;
                  }
                  .col-subscription-info {
                    display: flex;
                    .cell-inner {
                      margin: auto;
                      margin-left: 0;
                    }
                  }
                }
                &.col-info {
                  @include breakpoint('sm'){
                    flex: 4;
                    flex-grow: 3;
                  }
                  .cell {
                    flex: 7;
                    flex-grow: 1;
                    flex-shrink: 1;
                    & .ico-alert {
                      margin: auto .5em;
                      order: 0;
                    }
                    &.col-customer-info {
                      flex-grow: 2;
                    }
                    &.col-mrr {
                      max-width: 150px;
                    }
                    &.col-subscription-info {
                      & .cell-inner {
                        order: 1;
                      }
                    }
                    &.col-source {
                      max-width: 170px;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.list-summary-detail {
        background-color: transparent;
        padding: 0;
        & .custom-mat-list-item {
          &.grid-row {
            background-color: transparent;
            padding: 0;
            & .mat-list-item-content {
              & .cell-wrapper {
                padding: 0;
                & .cell {
                  &.cell-discount {
                    & .custom-mat-button {
                      font-size: .8125em;
                      height: auto;
                    }
                  }
                  &.cell-start-date {
                    flex: 3;
                    & .data {
                      display: flex;
                      & .field-date-picker {
                        max-width: 100px;
                      }
                      & .field-starting-date {
                        margin: auto;
                        margin-right: .5em;
                      }
                    }
                  }
                  &.cell-total {
                    min-height: 60px;
                  }
                }
                &.col-info {
                  display: flex;
                  margin: auto;
                  min-height: 2.125em;
                  padding: 0;
                  & .cell {
                    align-items: center;
                    display: flex;
                    flex: 1;
                    flex-direction: row;
                    justify-content: space-between;
                  }
                }
              }
            }
            &:hover {
              background-color: transparent;
            }
            &.grid-row-footer {
              padding: 0;
            }
            &.grid-row-next-invoice {
              display: flex;
              min-height: 60px;
            }
            &.grid-row-start-date {
              border-bottom: 1px solid $grey-200;
              margin-bottom: .5em;
            }
            &.grid-row-total {
              border-top: 1px solid $grey-200;
              margin-top: .5em;
            }
          }
        }
      }
      &.list-taxes-classes {
        & .custom-mat-list-item {
          &.grid-row {
            & .mat-list-item-content {
              & .cell-wrapper {
                & .cell {
                  &.col-icon {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    max-width: 65px;
                    width: 65px;
                    @include breakpoint('md'){
                      max-width: 180px;
                      width: 180px;
                    }
                    & .ico-tax-class {
                      height: 48px;
                      width: 48px;
                      @include breakpoint('md'){
                        height: 80px;
                        width: 80px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.list-tiers {
        & .mat-list-item {
          &:hover {
            background-color: transparent;
          }
          &.custom-mat-list-item {
            height: auto;
          }
        }
        &.tiers-shipping {
          & .custom-mat-list-item {
            & .mat-list-item-content {
              flex: 5;
              & .cell-nav {
                align-items: center;
                display: flex;
                max-width: 80px;
                justify-content: center;
              }
              & .field-type {
                min-width: 75px;
                width: 75px;
              }
            }
          }
        }
      }
      &.list-transactions {
        .custom-mat-list-item {
          &.grid-row {
            .mat-list-item-content {
              .cell-wrapper {
                &.col-actions {
                  flex: 2;
                  @include breakpoint('sm'){
                    flex: 4;
                  }
                }
                &.col-first {
                  flex: 2;
                  @include breakpoint('sm'){
                    flex: 4;
                    flex-grow: 1;
                  }
                  .col-transaction-info {
                    padding: 0 .5em;
                    .cell-inner {
                      margin: auto;
                      margin-left: 0;
                    }
                  }
                }
                &.col-info {
                  @include breakpoint('sm'){
                    flex: 4;
                    flex-grow: 3;
                  }
                  .cell {
                    flex: 5;
                    flex-grow: 1;
                    flex-shrink: 1;
                    &.col-customer {
                      flex-grow: 3;
                    }
                    &.col-kind {
                      justify-content: center;
                    }
                    &.col-payment-method {
                      flex-grow: 2;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.list-upcoming-charges {
        flex-grow: 1;
        & .custom-mat-list-item {
          &.grid-row {
            & .mat-list-item-content {
              & .cell {
                &.cell-shipping {
                  & .label-primary {
                    margin-right: .5em;
                  }
                }
              }
              & .grid-row-panel {
                background-color: transparent;
              }
            }
          }
        }
      }
      &.list-users {
          padding: 0;
          & .item-user {
              & .mat-list-item-content {
                  padding: 1em;
                  & .user {
                      display: inline-flex;
                      line-height: 26px;
                      & .badge {
                          margin: auto;
                          margin-left: 8px;
                      }
                      & .identifier {
                          display: none;
                          @include breakpoint('sm') { display: inline-flex; }
                          &:after {
                              background-color: $blue-A400;
                              border-radius: 50%;
                              content: '';
                              height: 5px;
                              margin: auto;
                              margin-left: 5px;
                              margin-right: 5px;
                              width: 5px;
                          }
                      }
                      & .user-name {
                          margin: auto;
                          margin-left: 0;
                          margin-right: 0;
                      }
                  }
                  & .user-email {
                      font-size: $caption-size;
                      @include breakpoint('sm'){ font-size: $body-1-size; }
                  }
              }
          }
      }
    }
    &.list-navigation {
        padding: 0;
        & .ico-preffix {
            margin: auto;
            margin-left: 0;
            margin-right: 8px;
        }
        & .nav-option {
            background-color: $white;
            border-top: 1px solid $grey-200;
            cursor: pointer;
            font-size: .875em;
            transition: background-color .3s ease-in-out;
            &:first-child {
                border-top: 0;
            }
            &:hover {
                background-color: $blue-50;
            }
        }
    }
    &.list-navigation-head {
        padding: 0;
        & .custom-mat-link {
            height: 40px;
            & .mat-list-item-content {
                width: 100%;
                @include breakpoint('lg'){ padding: 0; }
            }
            &.nav-option {
                transition: background-color .3s ease-in-out;
                & label {
                    margin: auto;
                    margin-left: 16px;
                    pointer-events: none;
                    transition: color .3s ease-in-out, opacity .3s ease-in-out;
                    @include breakpoint('lg'){
                        color: $white;
                        font-size: .875em;
                        font-weight: 400;
                        margin: auto;
                        margin-left: 0;
                        width: 100%;
                    }
                }
                & .ico-next {
                    margin: auto;
                }
                & .ico-route-is-active {
                    opacity: 0;
                    transform: translateX(-10%);
                    transition: transform .3s ease-in-out;
                    visibility: hidden;
                }
                & .svg-wrapper {
                    display: flex;
                    height: 40px;
                    transition: width .3s ease-in-out;
                    width: 40px;
                    @include breakpoint('lg'){
                        height: 40px;
                        margin: 0;
                        min-width: 50px;
                        width: 50px;
                    }
                    & .custom-mat-icon {
                        margin: auto;
                        transition: fill .3s ease-in-out;
                        @include breakpoint('lg'){
                            fill: $white;
                        }
                        &.ico-next {
                            width: 40px;
                            @include breakpoint('lg'){
                                fill: $blue-400;
                            }
                        }
                    }
                }
                &.option-search {
                  display: flex;
                  & .mat-form-field-wrapper {
                    display: flex;
                  }
                }
                &.router-link-active {
                    & .custom-mat-icon {
                        @include breakpoint('lg'){
                            fill: $blue-400;
                        }
                    }
                    & label {
                        color: $blue-400;
                    }
                    & .ico-route-is-active {
                        opacity: 1;
                        transform: translateX(0%);
                        visibility: visible;
                    }
                }
            }
        }
    }
    &.list-navigation-account {
        padding: 0;
        & .custom-mat-link {
            height: 64px;
            padding: 0;
            @include breakpoint('lg'){ height: 50px; }
            & .mat-list-item-content {
              padding: 0;
              width: 100%;
              & .mat-list-text {
                  flex-direction: row;
              }
            }
            &.nav-option {
                cursor: pointer;
                & label {
                    margin: auto;
                    margin-left: 16px;
                    pointer-events: none;
                    transition: opacity .3s ease-in-out;
                    @include breakpoint('lg'){
                        color: $white;
                        font-size: .875em;
                        font-weight: 400;
                        margin: auto;
                        margin-left: 0;
                        width: 100%;
                    }
                }
                & .ico-next {
                    margin: auto;
                }
                & .svg-wrapper {
                    align-items: center;
                    display: flex;
                    height: 40px;
                    justify-content: center;
                    transition: scale .3s ease-in-out;
                    width: 40px;
                    @include breakpoint('lg'){
                        height: 40px;
                        margin: 0;
                        min-width: 50px;
                        width: 50px;
                    }
                    & .custom-mat-icon {
                        margin: auto;
                        @include breakpoint('lg'){
                            fill: $white;
                        }
                    }
                    &.ico-next {
                        width: 40px;
                    }
                }
                &.nav-option-account {
                    display: flex;
                    flex-direction: row;
                    & .avatar-wrapper {
                      background-color: $blue-A700;
                      border: 1px solid $blue-700;
                      border-radius: 50%;
                      display: flex;
                      height: 34px;
                      margin: 0 10px;
                      width: 34px;
                    }
                    & .avatar {
                      margin: auto;
                      margin-left: 0;
                      margin-right: 16px;
                      @include breakpoint('lg'){
                        color: $white;
                        font-size: .875em;
                        font-weight: 500;
                        height: auto;
                        margin: auto;
                        text-transform: uppercase;
                        width: auto;
                      }
                    }
                    & .user-profile {
                        display: flex;
                        flex-direction: column;
                        margin: auto;
                        margin-left: 0;
                        & .user-name {
                            font-size: 1.125em;
                            margin: 0;
                        }
                        & .account-name {
                            font-size: .8125em;
                            margin: 0;
                        }
                    }
                }
                &.nav-option-logout {
                    color: $red-A400;
                    fill: $red-A400;
                }
            }
        }
    }
    &.list-navigation-options {
        padding: 0;
        & .custom-mat-list-item {
            height: 50px;
            @include breakpoint('lg'){
                height: 36px;
            }
            &:hover {
                background-color: transparent;
            }
        }
        & .nav-option {
            color: $grey-900;
            display: block;
            font-family: $font-semi-condensed;
            //font-size: 1em;
            font-weight: 400;
            min-width: 180px;
            opacity: 1;
            overflow: hidden;
            padding: 0 0 0 36px;
            transition: opacity .3s ease-in-out, color .3s ease-in-out;
            width: 100%;
            @include breakpoint('lg'){
                color: $white;
                font-size: .875em;
                font-weight: 300;
                height: 36px;
                padding: 0 0 0 50px;
            }
            &:after {
                @include prefix(-webkit-transform, rotate(45deg));
                background-color: $blue-50;
                border: 1px solid #e0e0e0;
                box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.25);
                content: '';
                height: 8px;
                opacity: 0;
                pointer-events: none;
                position: absolute;
                right: -5px;
                top: 18px;
                transform: rotate(45deg);
                transition: all .3s ease-in-out;
                width: 8px;
                z-index: 0;
            }
            &:hover {
                opacity: 1;
            }
            &.disabled {
                color: $grey-500;
            }
            &.mat-list-item-disabled {
              background-color: transparent;
              opacity: .45;
            }
            &.router-link-active {
                color: $blue-A900;
                opacity: 1;
                pointer-events: none;
                @include breakpoint('lg'){ color: $blue-A100; }
            }
        }
    }
    &.list-navigation-panel {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 0;
        & .custom-mat-list-item {
            cursor: pointer;
            display: flex;
            font-size: 1em;
            height: 48px;
            margin: 0;
            padding: 0 1.5em;
            & .mat-list-item-content {
              display: flex;
              flex: 1;
              justify-content: space-between;
            }
        }
    }
    &.list-page-side {
        padding: 0;
    }
    &.list-payment-gateway {
      padding: 0;
      & .custom-mat-list-item {
        border-bottom: 1px solid $grey-200;
        @include breakpoint('md'){
          border-left: 1px solid $grey-200;
          border-right: 1px solid $grey-200;
        }
        & .mat-list-item-content {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          padding: 0;
          & .list-item-body {
            background-color: $grey-50;
            width: 100%;
            & .item-body-inner {
              padding: 1em 0;
              & .item-row {
                align-items: center;
                display: flex;
                justify-content: flex-start;
                min-height: 4.03125em;
                padding: 0 3.25em;
              }
            }
          }
          & .list-item-header {
            align-items: center;
            display: flex;
            min-height: 64px;
            width: 100%;
            & .custom-mat-radio-button {
              display: flex;
              min-height: 64px;
              padding: 0 1em;
              width: 100%;
              & .mat-radio-label {
                & .mat-radio-label-content {
                  display: flex;
                  flex-direction: column;
                  padding-left: 1em;
                  width: 100%;
                  @include breakpoint('md'){
                    flex-direction: row;
                    justify-content: space-between;
                  }
                }
              }
            }
          }
        }
        &:first-child {
          border-top: 1px solid $grey-200;
        }
      }
    }
    &.list-plans, &.list-subscriptions {
        & .custom-mat-list-item {
            &.grid-row {
                & .mat-list-item-content {
                    & .cell-wrapper {
                        & .cell {
                            flex: 2;
                        }
                        & .col-price {
                            align-items: center;
                            @include breakpoint('md'){
                                display: flex;
                                justify-content: flex-end;
                            }
                            & .label-currency-symbol {
                                margin: auto;
                                margin-left: 4px;
                                margin-right: 0;
                            }
                        }
                        & .col-status {
                            margin: 8px 0;
                            max-width: 120px;
                            padding: 0 16px;
                        }
                    }
                }
            }
        }
    }
    &.list-plans {
        & .custom-mat-list-item {
            &.grid-row {
                & .mat-list-item-content {
                    & .cell-wrapper {
                        & .col-name {
                            padding-left: 0;
                            @include breakpoint('md'){ flex: 6; }
                        }
                    }
                }
            }
        }
    }
    &.list-search-results{
        padding: 0;
        & a {
            transition: background-color .3s ease-in-out;
            &:hover {
                background-color: $blue-grey-50;
            }
            &.custom-mat-list-item {
                padding: 0 16px;
            }
        }
    }
    &.list-setup-prehead {
        background-color: $grey-50;
        box-shadow: 0 2px 6px -3px $grey-400;
        height: 64px;
        padding: 0 10px;
        @include breakpoint('sm') { padding: 8px 10px 8px 20px; };
        & .headline {
            font-size: $headline-size;
            font-weight: 300;
        }
        & .caption {
            font-size: $caption-size;
        }
        & .mat-list-item-content{
            padding: 0;
        }
        & .nav-option-logo {
            margin: auto;
            margin-left: 0;
            margin-right: 20px;
            & .ico-isologo-top {
                height: 35px;
                width: 85px;
            }
        }
        & .nav-option-logout {
            margin: auto;
            margin-right: 0;
        }
        & .nav-option-userinfo {
            display: none;
            @include breakpoint('sm'){ display: inline-flex; }
            &:before {
                background-color: $grey-300;
                content: '';
                display: inline-block;
                height: 28px;
                margin: auto;
                margin-bottom: 8px;
                margin-right: 20px;
                width: 1px;
            }
        }
    }
    &.list-simple-nav {
        display: flex;
        flex-direction: column;
        padding: 0;
        @include breakpoint('sm') { flex-direction: row; }
        & .custom-mat-list-item {
            height: 48px;
            padding: 0;
            &.aside {
                margin: auto;
                &.aside-left {
                    margin-bottom: 20px;
                    @include breakpoint('sm') { margin: auto; margin-left: 0; }
                }
                &.aside-right {
                    @include breakpoint('sm') { margin-right: 0; }
                }
            }
        }
    }
    &.list-sidenav-prehead {
        padding: 0;
        & .item-head-toggle {
            display: flex;
            & .mat-list-item-content {
                width: 100%;
            }
        }
        & .ico-toggle-sandwich {
            margin: auto;
            color: $white;
            position: absolute;
        }
    }
    &.list-smart-routing-gateway {
      padding: 0;
      width: 100%;
      & .custom-mat-list-item {
        & .col-actions {
          display: flex;
          justify-content: center;
          margin: auto;
          margin-right: 0;
          margin-left: 0;
          min-width: 60px;
        }
        &.grid-row-actions {
          & .actions {
            min-width: 120px;
          }
        }
      }
    }
    &.list-spanel-account {
        padding: 0;
        & .mat-divider {
            border-top-color: $grey-800;
            margin-bottom: 20px;
            margin-left: 80px;
            margin-right: 45px;
            @include breakpoint('sm'){ margin-left: 105px; }
        }
        & .mat-list-item {
            color: $white;
            height: auto;
            & .mat-list-item-content {
                padding: 0;
                @include breakpoint('sm') { padding: 0 45px 0 25px; }
                & .mat-list-text {
                    padding: 0;
                }
                & .custom-mat-line {
                    margin-bottom: 20px;
                }
                & .mat-list-icon {
                    background-color: $black;
                    color: $blue-A900;
                    height: 40px;
                    margin: auto;
                    margin-left: 20px;
                    margin-right: 20px;
                    margin-top: 0;
                    padding: 0;
                    width: 40px;
                    & svg {
                        height: 15px;
                        width: 15px;
                    }
                }
                & .subheading-1 {
                    font-size: $subheading-2-size;
                    margin-bottom: 5px;
                }
                & .note {
                    color: $grey-600;
                    font-size: $note-size;
                }
            }
            &.item-logout {
                padding-bottom: 50px;
                & .button-logout {
                    background-color: transparent;
                    border: none;
                    color: $pink-A400;
                    cursor: pointer;
                    font-family: $font-semi-condensed;
                    font-size: $body-1-size;
                    font-weight: 500;
                    margin: 0;
                    padding: 0;
                    text-transform: uppercase;
                }
                & .mat-list-icon {
                    color: $pink-A400;
                    margin: auto;
                    margin-left: 20px;
                    margin-right: 20px;
                    margin-top: auto;
                }
            }
        }
    }
    &.list-subscriptions {
        & .custom-mat-list-item {
            &.grid-row {
                & .mat-list-item-content {
                    & .cell-wrapper {
                        & .col-subscription, & .col-customer-info {
                            @include breakpoint('md'){ flex: 3; }
                        }
                    }
                }
            }
        }
    }
    &.list-summary-numbers {
      box-shadow: 0 0 12px -8px rgb(0,0,0,.45);
      display: flex;
      padding: 0;
      flex-direction: column;
      width: 100%;
      @include breakpoint('sm'){
          flex-direction: row;
      }
      & .custom-mat-list-item {
          background-color: $white;
          border-bottom: 1px solid $blue-grey-100;
          flex: 5;
          height: auto;
          padding: 20px 25px;
          @include breakpoint('sm') {
              border-right: 1px solid $blue-grey-100;
          }
          @include breakpoint('md') {
              border-top: 1px solid $blue-grey-100;
          }
          &:first-child {
              border-top: 1px solid $blue-grey-100;
              @include breakpoint('md') {
                  border-left: 1px solid $blue-grey-100;
              }
          }
          & .summary-amount {
              font-family: $font-condensed;
              font-size: $caption-size;
              font-weight: 300;
          }
          & .summary-desc {
              color: $grey-600;
              display: flex;
              font-family: $font-semi-condensed;
              font-size: $note-size;
              font-weight: 500;
              letter-spacing: .5px;
              text-transform: uppercase;
              @include breakpoint('lg') { font-size: $caption-size; }
              & .custom-mat-icon {
                  margin: auto;
                  margin-left: 5px;
              }
          }
          & .summary-value {
              font-family: $font-condensed;
              font-size: $title-size;
              font-weight: 400;
              @include breakpoint('md') { font-size: $headline-size; }
          }
      }
    }
    &.mat-list-plans {
        & .headline {
            font-size: $headline-size;
            font-weight: $headline-weight;
        }
        & .mat-list-item {
            height: auto!important;
        }
        & .subheading-2 {
            font-size: $subheading-2-size;
            font-weight: $subheading-2-weight;
        }
    }
    &.nopadding {
        padding: 0;
    }
  }
}

.mat-menu-panel {
  &.custom-mat-menu-panel {
    min-height: auto;
    min-width: 190px;
    & .custom-mat-menu-item {
      align-items: center;
      border-top: 1px dotted $grey-300;
      display: flex;
      flex-direction: row;
      padding: 0;
      & .custom-mat-icon {
          margin: auto;
          margin-left: 0;
          margin-right: 0;
          min-width: 40px;
      }
      &:first-child {
          border-top: 0 none;
      }
      &.has--no--icon {
          padding: 0 16px;
      }
      &.item-danger {
          color: $red-A400;
          & .custom-mat-icon {
              color: $red-A400;
          }
      }
      &.item-disabled {
        color: $grey-500;
        pointer-events: none;
      }
      &.item-header {
          align-items: center;
          background-color: $grey-200;
          display: flex;
          height: 64px;
          justify-content: space-between;
          padding: 0 1em;
          pointer-events: none;
      }
      &.item-manage {
          color: $blue-A400;
          padding: 0 0 0 16px;
          justify-content: space-between;
          & .custom-mat-icon {
              color: $blue-A400;
          }
          &:hover {
              background-color: $blue-50;
          }
      }
      &.item-section {
        align-items: flex-start;
        cursor: default;
        display: flex;
        flex-direction: column;
        .item-nav {
          display: flex;
          justify-content: space-between;
          width: 100%;
          .item-nav-option {
            cursor: pointer;
            &.active {
              color: $blue-A400;
            }
          }
        }
        .item-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      }
    }
    & .mat-menu-content {
        padding: 0;
    }
    &.menu-customer-address, &.menu-customer-external, &.menu-customer-payments, &.menu-invoice-transactions, &.menu-customer-subscriptions {
      max-height: 350px;
      overflow: scroll;
      .custom-mat-menu-item {
        background-image: url(../svg/ico-next-01.svg);
        background-position: 100% 50%;
        background-repeat: no-repeat;
        background-size: 30px 8px;
        height: 60px;
      }
    }
    &.menu-customer-payments {
      .custom-mat-menu-item {
        margin: 0;
        padding: 0;
        .label-card {
          &.card-badge {
            border: 0 none;
          }
        }
      }
    }
    &.menu-customer-address, &.menu-customer-external, &.menu-invoice-dates, &.menu-invoice-transactions, &.menu-invoice-shipTo, &.menu-customer-subscriptions {
      .custom-mat-menu-item {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        label {
          cursor: pointer;
          line-height: 1em;
          &.label-primary {
            margin-bottom: .5em;
          }
        }
      }
    }
    &.menu-grid-preferences {
        & .custom-mat-menu-item {
            align-items: center;
            background-color: $white;
            border-top: 1px dotted $grey-300;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 0 0 8px;
            & .custom-mat-menu-item-placeholder {
                background-color: $grey-500;
                border: dotted 3px #999;
                min-height: 48px;
                transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
            }
        }
    }
    &.menu-sales-channel, &.menu-external-products {
      & .custom-mat-menu-item {
        height: 61px;
        padding: .5em 1em;
        & figure {
          margin: 0;
          &.sales-channels {
            img {
              min-height: 25px;
              max-height: 25px;
            }
          }
        }
        &.active {
          color: $blue-A400;
        }
        &.item-section {
          height: auto;
        }
      }
    }
    &.menu-external-products {
      min-width: 340px;
      .label-product-name {
        font-size: 16px;
        line-height: 1.75em;
      }
      .label-product-salechannel {
        line-height: 1.75em;
      }
      .product-variants {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        margin-bottom: 1em;
        margin-top: 1em;
        .label-product-variant {
          line-height: 1.5em;
        }
      }
    }
  }
}

.mat-paginator {
    &.custom-mat-paginator {
        background-color: transparent;
        & .mat-paginator-container {
            justify-content: space-between;
            @include breakpoint('md'){
                padding: 0;
            }
            & .mat-paginator-page-size {
                & .mat-paginator-page-size-label {
                    margin: 0;
                    margin-right: 4px;
                }
                & .mat-paginator-page-size-select {
                    max-width: 30px;
                    width: auto;
                }
            }
            & .mat-paginator-range-actions {
                & .mat-paginator-range-label {
                    margin: auto 16px;
                }
                & .mat-icon-button {
                    & .mat-button-wrapper {
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        & .mat-paginator-icon {
                            width: 22px;
                        }
                    }
                }
            }
        }
    }
}

.mat-progress-bar {
    &.custom-mat-progress-bar {
        height: 3px;
    }
}

.mat-progress-spinner {
    &.custom-mat-progress-spinner {
        &.spinner-loading-01 {
            height: 40px!important;
            margin: auto;
            width: 40px!important;
            & svg {
                height: 100%!important;
                width: 100%!important;
            }
        }
    }
}

.mat-radio-group {
  &.custom-mat-radio-group {
    & .custom-mat-radio-button {
      background-color: $white;
      border-bottom: 1px solid $grey-200;
      margin: auto;
      margin-left: 0;
      padding: 1em;
      width: 100%;
      &:first-child {
          border-top: 1px solid $grey-200;
      }
      & .mat-radio-label {
        & .mat-radio-label-content {
          white-space: normal;
        }
      }
    }
    & .mat-radio-container {
      margin: auto;
      margin-left: 0;
      margin-right: 1em;
      margin-top: 3px;
    }
    & .placeholder {
        color: $grey-600;
        font-size: 12px;
        margin-bottom: 12px;
    }
    &.column {
        display: flex;
        flex-direction: column;
        & .mat-radio-label {
            white-space: normal;
        }
    }
    &.group-avalara-setup, &.group-choose-address, &.group-choose-payment, &.group-choose-shipping-service {
      & .custom-mat-radio-button {
        border-left: 1px solid $grey-200;
        border-right: 1px solid $grey-200;
        padding: 0 1em;
        & .mat-radio-label {
          min-height: 4em;
          & .mat-radio-container {
              margin: auto;
              margin-left: 0;
              margin-right: .5em;
          }
        }
        & .label-primary{
          font-size: .9375em;
        }
        & .label-secondary{
          font-size: .875em;
          font-weight: 300;
        }
      }
    }
    &.group-avalara-setup {
      & .panel-radio {
        background-color: $grey-100;
      }
    }
    &.group-choose-payment {
      & .custom-mat-radio-button {
        &.mat-radio-checked {
          border-bottom: 0 none;
          @include breakpoint('sm'){
            background-color: $grey-50;
          }
        }
      }
      & .panel {
        border-bottom: 1px solid $grey-200;
        border-left: 1px solid $grey-200;
        border-right: 1px solid $grey-200;
        @include breakpoint('sm'){
          background-color: $grey-50;
        }
        & .panel-wrapper {
          padding: 0 1em 1em;
          @include breakpoint('sm'){
            padding: 0 1em 1em;
          }
          & .form-row {
            margin-top: 0!important;
          }
        }
        &.panel-thirdPartyPayments {
          & .panel-wrapper {
            padding: 0 1em 2em;
          }
        }
      }
    }
    &.group-choose-system-events {
      width: 100%;
      & .custom-mat-radio-button {
        border-left: 1px solid $grey-200;
        border-right: 1px solid $grey-200;
      }
    }
    &.group-currencies-exchange {
        & .custom-mat-radio-button {
            border-bottom: 1px dotted $grey-300;
            margin: auto;
            margin-bottom: 20px;
            margin-left: 0;
            padding-bottom: 20px;
            @include breakpoint('sm'){
                margin: 0;
                padding: 1.5em 0;
            }
            & .mat-radio-label {
              & .mat-radio-label-content {
                margin: 0;
                padding-left: 1em;
                padding-right: 2em;
              }
            }
        }
        & .mat-radio-container {
            margin: auto;
            margin-left: 0;
            margin-right: 0;
            margin-top: 0;
        }
    }
    &.group-customer-payment-methods {
      & .custom-mat-radio-button {
        background-color: transparent;
        border: 0 none;
        padding: 0;
        & .mat-radio-label {
          padding: .5em 0;
          & .mat-radio-container {
            margin: auto 0;
          }
          & .mat-radio-label-content {
            width: 100%;
          }
        }
      }
    }
    &.group-integrations {
        display: grid;
        grid-gap: 5px;
        grid-template-columns: repeat(auto-fill, 90%);
        justify-content: center;
        @include breakpoint('sm') {
            grid-gap: 10px;
            grid-template-columns: repeat(auto-fill, 48%);
        }
        & .custom-mat-radio-button {
            background-color: $white;
            border: 1px solid #d6d6d6;
            border-radius: 8px;
            margin: 0;
            min-height: 150px;
            padding: 1em;
            transition: border-color .3s ease-in-out;
            & .mat-radio-container {
                opacity: 0;
                position: absolute;
            }
            & .mat-radio-label{
                height: 100%;
                & .mat-radio-label-content {
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    padding: 0;
                    width: 100%;
                }
            }
            &.mat-radio-checked {
                border: 2px solid #0070c9;
            }
        }
        & .logo-integration {
            height: auto;
            max-width: 170px;
            &.logo-disabled {
              -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
              background-color: $grey-100;
              filter: $grey-300; /* Google Chrome, Safari 6+ & Opera 15+ */
              filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
              opacity: .25;
            }
            &.logo-goolgle-retail {
              max-height: 80px;
            }
            &.logo-salesforce {
              max-height: 80px;
            }
        }
    }
    &.group-options-settings {
      display: flex;
      flex-direction: column;
      & .custom-mat-radio-button {
        border: 0 none;
        padding: 1em 0;
        & .mat-radio-label {
          & .mat-radio-container {
            margin: auto 0;
          }
        }
        &:first-child {
          border: 0 none;
        }
      }
    }
    &.group-roles {
        & .custom-mat-radio-button {
            border-bottom: 1px solid $grey-200;
            margin: auto;
            margin-left: 0;
            padding: 1em;
            width: 100%;
            &:first-child {
                @include breakpoint('md'){
                    border-top: 1px solid $grey-200;
                }
            }
        }
        & .label-title {
            @include breakpoint('md'){
                margin-bottom: 5px;
            }
        }
        & .mat-radio-container {
            margin: auto;
            margin-left: 0;
            margin-right: 1em;
            margin-top: 3px;
        }
    }
    &.radio-group-filter-values {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        width: 100%;
        & .custom-mat-radio-button {
            border: 0 none;
            border-top: 1px solid $grey-200;
            & .mat-radio-container {
                margin: auto 0;
            }
            &:last-child {
                border-bottom: 1px solid $grey-200;
            }
        }
    }
  }
}

.mat-slide-toggle {
  &.custom-mat-slide-toggle {
    & .mat-slide-toggle-label {
      & .mat-slide-toggle-content {
        line-height: 1em;
        white-space: normal;
      }
    }
  }
}

.mat-select {
  &.custom-mat-select {
    display: inline-block;
    min-height: 20px;
    .mat-select-trigger {
      .mat-select-arrow-wrapper {
        display: inline-flex;
        justify-content: center;
        min-width: inherit;
      }
      .mat-select-value {
        max-width: inherit;
      }
    }
    &.select-address-books, &.select-payment-methods, &.select-credit-card {
      padding: 0;
      .custom-mat-select-trigger {
        display: flex;
        flex-direction: column;
        .address-contact {
          margin-bottom: .5em;
        }
        .address-section {
          height: 70px;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
        }
        .address-info {
          color: $grey-600;
          font-size: .875em;
          width: 100%;
        }
        .label-card-holder {
          color: $grey-600;
          font-size: .875em;
        }
        .label-card-gateway {
          color: $grey-600;
          font-size: .875em;
        }
        .label-card-number {
          align-items: center;
          display: flex;
          //margin: auto;
          &:before {
            color: #8c8c91;
            content:"····";
            font-size: 2em;
            margin: 0 .25em 0 .5em;
          }
        }
        .customer-payment-card-ico{
          border-top: 1px solid $grey-200;
            height: auto;
            line-height: initial;
            padding: 1em;
              & .mat-option-text {
                  display: flex;
                  flex-direction: row;
              }
              &:first-child {
                  border-top: 0 none;
              }
            .customer-payment-card-ico {
                align-items: center;
                display: flex;
            }
            .label-card-exp {
              margin: auto;
              margin-right: 0;
            }
        }
        &.heightFixed {
          min-height: 100px;
        }
      }
    }
    &.select-currency {
      align-items: center;
      display: flex;
      min-height: 21px;
      & .mat-select-trigger {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: auto;
      }
    }
    &.select-flex {
        align-items: center;
        display: flex;
        min-height: 1em;
    }
    &.select-payment-methods {
      align-items: center;
      display: flex;
      width: 100%;
      .mat-select-trigger {
        align-items: center;
        display: flex;
        width: 100%;
        .mat-select-arrow-wrapper {
          display: contents;
        }
        .mat-select-value {
          padding: 0 1em 0 0;
          .mat-select-value-text {
            .custom-mat-select-trigger {
              align-items: center;
              flex-direction: row;
              &.customer-payment-method {
                .card-exp {
                  margin-left: auto;
                  margin-right: 0;
                }
                .card-number-wrapper {
                  display: flex;
                  flex-direction: column;
                }
                .card-type {
                  margin: auto;
                  margin-left: 0;
                  margin-right: .5em;
                  width: auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

.mat-select-panel {
    &.custom-mat-select-panel {
        &.list-address-books {
            & .option-address {
                border-top: 1px solid $grey-200;
                height: auto;
                line-height: initial;
                padding: 1em;
                & .mat-option-text {
                    display: flex;
                    flex-direction: column;
                    & .label-address-info {
                        font-size: .875em;
                        & span {
                            margin-right: 4px;
                        }
                    }
                    & .label-address-nickname {
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.5;
                    }
                }
                &:first-child {
                    border-top: 0 none;
                }
                &.option-address-new {
                    & .mat-option-text{
                        color: $blue-A400;
                    }
                }
                &.mat-selected {
                    background-color: $blue-50;
                }
            }
        }
        &.list-currency {
            & .option-currency {
                & .mat-option-text {
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
        &.list-tax-regions {
            & .mat-option {
                & .mat-option-text {
                    display: flex;
                    flex-direction: row;
                    & .ico-flag {
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: 50% 50%;
                        height: 15px;
                        margin: auto;
                        margin-left: 0;
                        margin-right: 8px;
                        min-width: 20px;
                        width: 20px;
                    }
                }
            }
        }
        &.list-credit-cards {
          & .option-card {
            border-top: 1px solid $grey-200;
            height: auto;
            line-height: initial;
            padding: 1em;
              & .mat-option-text {
                display: flex;
                flex-direction: row;
              }
              &:first-child {
                border-top: 0 none;
              }
            & .customer-payment-card-ico {
                align-items: center;
                display: flex;
            }
            .label-card-exp {
              margin: auto;
              margin-right: 0;
            }
      }
    }
  }
}

.mat-selection-list {
    &.custom-mat-selection-list {
        padding: 0;
        & .custom-mat-list-option {
            height: auto;
            & .mat-list-item-content {
                padding: 1em 0;
                & .mat-list-item-ripple {
                    display: none;
                }
                &:hover {
                    background-color: $white;
                }
            }
            & .mat-list-text {
                & .label-description {
                    font-size: .75em;
                    font-weight: 300;
                }
                & .label-title {
                    font-size: 1em;
                    font-weight: 400;
                }
            }
            &:focus {
                background-color: transparent;
            }
            &:hover {
                background-color: transparent;
            }
        }
    }
}

.mat-tab-group {
    &.custom-mat-tab-group{
        & .mat-tab-header {
            & .mat-tab-label-container {
                flex-grow: 0;
            }
        }
        & .mat-tab-body {
            &.mat-tab-body-active {
                overflow-y: hidden;
            }
            & .mat-tab-body-content {
                overflow-y: hidden;
            }
        }
        & .mat-tab-header-pagination {
            box-shadow: none;
        }
        & .mat-tab-label {
            height: 70px;
            padding: 0;
            &.mat-tab-label-active {
                color: $blue-A400;
                opacity: 1;
            }
        }
        & .mat-tab-label-content {
            flex-wrap: wrap;
            padding: 0 20px;
            & p {
                display: flex;
                flex: 1 0 100%;
                justify-content: center;
            }
        }
        &.tab-group-plans {
            & .mat-tab-label-container {
                flex: 1;
            }
            & .card-review {
                margin: 0;
                @include breakpoint ('sm') { margin: 30px 10px 0 80px; }
            }
            & .tab-group-section {
                padding: 50px 0;
                &.first {
                    padding-top: 0;
                }
            }
        }
        &.tab-group-refund-options {
          .mat-tab-body-wrapper {
            display: none;
          }
        }
    }
}

.mat-tooltip {
  background-color: $grey-800!important;
  color: $white!important;
  font-size: $caption-size!important;
  padding: 15px!important;
  white-space: pre-line !important;

  &.custom-mat-tooltip {
    font-size: $body-1-size!important;
    overflow: visible!important;
    position: relative!important;
    &:before {
      background-color: $grey-800;
      content: ' ';
      display: inline-block;
      clip-path: polygon(50% 0, 0 50%, 50% 100%);
      height: 15px;
      left: -7px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 15px;
    }
  }
  &.tooltip-large {
    max-width: 500px;
    max-height: 80vh;
    overflow: auto !important  ;
   }
}

.panel {
    &.panel-avalara-json {
      background-color: $grey-50;
      border: 0 none;
      box-shadow: none;
      .mat-expansion-panel-content {
        width: 100%;
        .panel-wrapper {
          display: flex;
          flex-direction: column;
          padding: 2em;
          @include breakpoint('md'){
            flex-direction: row;
          }
          .panel-col {
            display: flex;
            flex-direction: column;
            flex: 3;
            flex-grow: 1;
            flex-shrink: 1;
            .col-inner {
              align-items: flex-start;
              margin-bottom: 1.5em;
              flex-direction: column;
              min-height: 2.5em;
              @include breakpoint('md') {
                flex-direction: row;
              }
              .col-row {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              .json-code-block {
                  max-height: 450px;
                  overflow: scroll;
                  margin: 5px;
                }
              }
            }
          }
        }
      }
    }
    & .panel-header {
        margin-bottom: 30px;
        & .label-title {
            margin-bottom: 5px;
        }
    }
    & .pre-head {
        display: flex;
        flex-direction: row;
        & .ico-isologo-login {
            margin: auto;
            margin-bottom: 30px;
            margin-top: 30px;
            @include breakpoint('md'){ margin-left: 0; }
        }
    }
    &.panel-adv {
        display: flex;
        height: 100vh;
        position: relative;
        width: 100%;
        &.adv-01 {
            background-color: $grey-900;
            color: $white;
        }
        & .panel-adv__footer{
            bottom: 40px;
            position: absolute;
            text-align: center;
            width: 100%;
            & .enterprise-panel__footer__lead{
                opacity: .75;
            }
            & .panel-adv__logos {
                height: 30px;
                margin: 30px 0 0;
                opacity: .75;
                width: auto;
            }
        }
    }
    &.panel-hero {
        margin: auto;
        max-width: 320px;
        width: 100%;
        @include breakpoint('sm'){ max-width: 420px; }
        @include breakpoint('lg') { margin-left: 25%; }
    }
    &.panel-order-detail {
      background-color: $grey-50;
      border: 0 none;
      box-shadow: none;
      .mat-expansion-panel-content {
        width: 100%;
        .panel-call-to-action {
          padding: 2em;
        }
        .panel-wrapper {
          display: flex;
          flex-direction: column;
          padding: 2em;
          @include breakpoint('md'){
            flex-direction: row;
          }
          .panel-col {
            display: flex;
            flex-direction: column;
            flex: 3;
            flex-grow: 1;
            flex-shrink: 1;
            .col-inner {
              align-items: center;
              display: flex;
              flex-direction: column;
              min-height: 2.5em;
              @include breakpoint('md') {
                flex-direction: row;
              }
              .col-pre {
                display: flex;
                justify-content: flex-end;
                min-width: 90px;
                padding: 0;
                padding-right: 1em;
              }
              .col-row {
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                .order-detail, .order-summary {
                  .line-item {
                    display: flex;
                    justify-content: space-between;
                    &.row-summary-total {
                      font-size: 1.25em;
                      padding-top: 1em;
                    }
                  }
                }
                .order-detail {
                  border-left: 1px solid $grey-200;
                  min-height: 150px;
                  padding: 1em;
                  padding-top: 0;
                }
                .order-summary {
                  background-color: $blue-grey-50;
                  border-left: 1px solid $blue-grey-50;
                  padding: 1em;
                  .line-item {
                    align-items: center;
                    min-height: 28px;
                    label {
                      flex: 2;
                      &.label-total {
                        text-align: right;
                      }
                    }
                  }
                }
              }
              &.col-headline {
                align-items: flex-start;
                margin-bottom: 1.5em;
              }
            }
            &.col-order {
              flex-grow: 2;
              .col-inner {
                &.col-headline {
                  .col-pre {
                    align-items: flex-end;
                    flex-direction: column;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.panel-radio {
      background-color: $white;
      & .panel-wrapper {
        padding: 1em;
        & .panel-block {
          padding-bottom: 1em;
          & .panel-row {
            margin: .5em .25em;
          }
        }
        & .row-checkbox {
          padding: 0 .5em;
        }
      }
      &.inner-panel {
        background-color: $grey-100;
        border-bottom: 1px solid $grey-200;
        border-left: 1px solid $grey-200;
        border-right: 1px solid $grey-200;
      }
      &.panel-address {
        background-color: $grey-100;
        border-bottom: 1px solid $grey-200;
        border-left: 1px solid $grey-200;
        border-right: 1px solid $grey-200;
      }
      &.panel-exchange-rate {
        & .message-form {
          margin: 1em 0 0;
        }
        & .panel-wrapper {
          padding: 1em 0 0;
        }
      }
    }
    &.panel-tax-config {
        max-height: 0;
        overflow: hidden;
        transition: all .65s ease-in-out;
        & .panel-wrapper {
            padding-top: 20px;
            @include breakpoint('sm'){ padding-bottom: 0; padding-left: 28px; padding-right: 28px; }
            & .custom-mat-form-field {
                width: 100%;
                @include breakpoint('sm'){ display: inline-block;  padding: 0 10px 0 0;  width: 33%;
                    &:last-child { padding: 0; }
                }
            }
        }
        &.isVisible {
            max-height: 2048px;
        }
        &.panel-tax-US {
            & .panel-wrapper {
                & .custom-mat-form-field {
                    @include breakpoint('sm'){ width: 50%; }
                }
            }
        }
    }
}

.panel-gear-settings {
  &.panel-grid-preference {
    &.grid-product-rateplans {
      & .panel {
        &.popup-panel {
          &.panel-preferences {
            top: 180px;
          }
        }
      }
    }
  }
}

.panel-gear-settings {
  &.panel-grid-preference {
    &.grid-customers-subscription {
      & .panel {
        &.popup-panel {
          &.panel-preferences {
            top: 194px;
          }
        }
      }
    }
  }
}

.side {
    display: flex;
    flex-direction: column;
    width: 100%;
    &.side-flex-1 {
        flex: 1;
    }
    &.side-flex-2 {
        flex: 2;
    }
    &.side-flex-3 {
        flex: 3;
    }
}

#ccName, #ccNumber, #expDate, #cvv, #zipCode {
    &.braintree-hosted-fields-focused {
        color: $grey-700;
    }
    &.braintree-hosted-fields-invalid {
        color: $red-A400;
    }
    &.braintree-hosted-fields-valid {
        color: $light-green-A700;
    }
}

.fileover {
  animation: shake 1s;
  animation-iteration-count: infinite;
}

.files-list {
  margin-top: 1.5rem;

  .single-file {
    display: flex;
    padding: 0.5rem;
    justify-content: space-between;
    align-items: center;
    border: dashed 1px #979797;
    margin-bottom: 1rem;

    .delete {
      display: flex;
      margin-left: 0.5rem;
      cursor: pointer;
      align-self: flex-end;
    }


    display: flex;
    flex-grow: 1;

    .name {
      font-size: 14px;
      font-weight: 500;
      color: #353f4a;
      margin: 0;
    }

    .size {
      font-size: 12px;
      font-weight: 500;
      color: #a4a4a4;
      margin: 0;
      margin-bottom: 0.25rem;
    }

    .info {
      width: 100%
    }
  }
}

.skeleton {
  & .mat-form-field-appearance-legacy {
    &.mat-form-field-disabled {
      & .mat-form-field-underline {
        background-color: #e0e0e0;
        background-image: none;
        background-size: 0;
        background-repeat: unset;
      }
    }
  }
}

.old-discarded-price {
  text-decoration-line: line-through;
  margin-left: 5px;
  margin-right: 5px;
}

#post-info {
    display: flex;
    .chip-cnt {
      padding-left: 10px;
    }
}
.ico-export-csv {
  path {
    stroke: #2962ff !important;
  }
}
.flex {
  display: flex;
}
app-my-component {
  width: 100%;
}
.overlay-restriction {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
  backdrop-filter: blur(10px);

  padding-top: 40vh;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;
  padding-left: 20px;
  padding-right: 20px;
}

.chip-item-danger {
  color: $red-A400 !important;
  border: 1px solid $red-A400 !important;
  & .custom-mat-icon {
      color: $red-A400 !important;
  }
}

.primary-color-for-text {
  color: $blue-A400
}

.statusest {
  .status-label {
    padding: 10px;
    line-height: 35px;
    border-radius: 8px;
  }
  .status-inner {
    border-radius: 4px;
    margin-right: 10px;
  }

  .status-active {
    background-color: var(--palette-green-60);
    color:  var(--palette-green-100);
  }
  .status-disabled {
      background-color: var(--palette-orange-60);
      color:  var(--palette-orange-100);
    }
}

.icon-danger {
  color: red;
}
