@mixin alpha-attribute($attribute, $color, $background) {
	$percent: alpha($color) * 100%;
	$opaque: opacify($color, 1);
	$solid-color: mix($opaque, $background, $percent);
	#{$attribute}: $solid-color;
	#{$attribute}: $color;
}

@mixin breakpoint($screensize) {
  @if ($screensize == xs) {
    @media (max-width: 767px) {
      @content;
    }
  }
  @else if ($screensize == sm) {
    @media (min-width: 768px) {
      @content;
    }
  }
  @else if ($screensize == md) {
    @media (min-width: 980px) {
      @content;
    }
  }
  @else if ($screensize == lg) {
    @media (min-width: 1200px) {
      @content;
    }
  }
  @else if ($screensize == xl) {
    @media (min-width: 1440px) {
      @content;
    }
  }
  @else if ($screensize == xxl) {
    @media (min-width: 1920px) {
      @content;
    }
  }
  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}

@mixin font($breakpoint, $font-size, $line-height, $letter-spacing) {
  @include breakpoint($breakpoint) {
    font-size: $font-size;
    letter-spacing: $letter-spacing;
    line-height: $line-height;
  }
}

@mixin prefix($property, $value) {
  #{'-moz-' + $property}: $value;
  #{'-0-' + $property}: $value;
  #{'-ms-' + $property}: $value;
  #{'-webkit-' + $property}: $value;
  #{$property}: $value;
}
